import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function AppThemeProvider({ children }) {
  const appConfig = useSelector((state) => state.appConfig);

  return (
    <ThemeProvider theme={appConfig.themeRougeOr || {}}>
      {children}
    </ThemeProvider>
  );
}

AppThemeProvider.propTypes = propTypes;

export default AppThemeProvider;
