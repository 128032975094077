import { FormControl, Popover } from 'react-bootstrap';
import styled from 'styled-components';

import { NAVY } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 4vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  height: 50%;
  width: 70vw;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  font-size: 6vmin;
  font-weight: bold;
  padding: 2vmin;
  margin-top: 0;
  margin-bottom: 4vh;
  color: #fff;
`;

export const InputControls = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SeatInput = styled(FormControl)`
  white-space: pre;
  text-align: left;
  font-size: 5vmin;
  color: ${NAVY};
  width: 30vw;
`;

export const ErrorPopover = styled(Popover)`
  font-size: 4vmin;
  text-align: center;
  background-color: pink;

  .arrow::after {
    border-bottom-color: pink;
  }

  .popover-body {
    color: darkred;
  }
`;
