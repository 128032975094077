import React from 'react';

import DIRECTIONS from '#lib/constants/Directions';

import MidiVisualizer from '../MidiVisualiser';

function IntegratedWarmup() {
  return (
    <MidiVisualizer
      colorEvents={{ warmup: [] }}
      direction={DIRECTIONS.VERTICAL}
      instrumentPartitions={{
        name: 'warmup',
        partition: [
          { startTime: 2000 },
          { startTime: 3000 },
          { startTime: 3500 },
          { startTime: 3750 },
          { startTime: 4500 },
        ],
      }}
      playing
      specialEvents={{ warmup: [] }}
      teamInfos={{
        warmup: {
          color: '#ff0021',
          image: 'benchmarks/images/teams/teamDrummer',
        },
      }}
    />
  );
}

export default IntegratedWarmup;
