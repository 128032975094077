import './customProgressbar.css';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import Logger from '../../lib/Logger';
import {
  CircularCountdownContainer,
} from './styles';

const propTypes = {
  elapsed: PropTypes.bool,
  milliseconds: PropTypes.number,
  onElapsed: PropTypes.func,
  onSecondCallback: PropTypes.func,
  showTimeLeft: PropTypes.bool,
  timeElapsed: PropTypes.number,
};

const defaultProps = {
  elapsed: false,
  milliseconds: 0,
  onElapsed: () => { },
  onSecondCallback: () => { },
  showTimeLeft: false,
  timeElapsed: 0,
};

class CircularCountdown extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.timeLeft === 0 && nextProps.milliseconds && !nextProps.elapsed) {
      return {
        alert: false,
        timeLeft: parseInt((nextProps.milliseconds - nextProps.timeElapsed) / 1000, 10),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);
    this.state = {
      timeLeft: parseInt((props.milliseconds - props.timeElapsed) / 1000, 10),
    };

    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    const { milliseconds, onElapsed } = this.props;

    this.startTimer(milliseconds, onElapsed);
  }

  componentDidUpdate(prevProps, prevState) {
    const { elapsed, milliseconds, onElapsed } = this.props;

    if (prevProps.elapsed !== elapsed && prevState.timeLeft === 0) {
      this.startTimer(milliseconds, onElapsed);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.alertTimeout);
    clearTimeout(this.endTimeout);
    clearInterval(this.timeInterval);
  }

  startTimer(duration, onElapsed) {
    clearTimeout(this.alertTimeout);
    clearTimeout(this.endTimeout);
    clearInterval(this.timeInterval);

    this.endTimeout = setTimeout(() => {
      this.logger.trace('onElapsed()');
      onElapsed();
    }, duration);

    if (this.timeInterval) clearInterval(this.timeInterval);
    this.timeInterval = setInterval(() => {
      const { milliseconds, onSecondCallback, timeElapsed } = this.props;
      const newTimeElapsed = timeElapsed + 1000 > milliseconds ? milliseconds : timeElapsed + 1000;
      if (newTimeElapsed >= milliseconds) {
        clearInterval(this.timeInterval);
        this.setState({
          timeLeft: 0,
        });
      } else {
        onSecondCallback(newTimeElapsed);
        this.setState({
          timeLeft: parseInt((milliseconds - newTimeElapsed) / 1000, 10),
        });
      }
    }, 1000);
  }

  render() {
    const { timeLeft } = this.state;
    const {
      milliseconds,
      showTimeLeft,
    } = this.props;
    return (
      <CircularCountdownContainer>
        <CircularProgressbar
          maxValue={parseInt(milliseconds / 1000, 10)}
          minValue={0}
          text={showTimeLeft ? `${parseInt(timeLeft, 10)}` : ''}
          value={parseInt(timeLeft, 10)}
        />
      </CircularCountdownContainer>
    );
  }
}

CircularCountdown.propTypes = propTypes;
CircularCountdown.defaultProps = defaultProps;

export default CircularCountdown;
