import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CustomButton from '#components/CustomButton/CustomButton';
import {
  MainContainer,
  NextButtonContainer,
  PageText,
  PageTitle,
  TextContainer,
} from '#components/TeamSelectionFrame/styles';

const propTypes = {
  children: PropTypes.node,
  confirmedTeam: PropTypes.string,
  handleTeamConfirm: PropTypes.func.isRequired,
  selectedTeam: PropTypes.string,
  theme: PropTypes.shape().isRequired,
};

const defaultProps = {
  children: null,
  confirmedTeam: '',
  selectedTeam: '',
};

function IntegratedTeamSelection(props) {
  const {
    children,
    confirmedTeam,
    handleTeamConfirm,
    selectedTeam,
    theme,
  } = props;
  return (
    <MainContainer usePercents>
      <TextContainer>
        {confirmedTeam
          ? (
            <PageTitle>
              <FormattedMessage id="team_selection_found" values={{ name: confirmedTeam }} />
            </PageTitle>
          )
          : (
            <>
              <PageTitle><FormattedMessage id="team_selection_title" /></PageTitle>
              <PageText><FormattedMessage id="team_selection_text" /></PageText>
            </>
          )}
      </TextContainer>
      {children}
      { !confirmedTeam && (
        <NextButtonContainer>
          <CustomButton
            disabled={selectedTeam === '' || confirmedTeam}
            onClick={handleTeamConfirm}
            id="confirmButton"
            themeCode={theme && theme.themeCode}
          >
            <FormattedMessage id="team_selection_confirm" />
          </CustomButton>
        </NextButtonContainer>
      )}

    </MainContainer>
  );
}

IntegratedTeamSelection.propTypes = propTypes;
IntegratedTeamSelection.defaultProps = defaultProps;

export default IntegratedTeamSelection;
