import MessageScreen from '../MessageScreen/MessageScreen';

function PageNotFound() {
  return (
    <MessageScreen
      linkLabelKey="page_not_found_go_back"
      linkPath="/orchestration"
      messageLabelKey="page_not_found"
    />
  );
}

export default PageNotFound;
