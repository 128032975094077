import { CloudImage } from '@novom/cloudinary-image';
import styled, { keyframes } from 'styled-components';

import { slideInUp, zoomIn } from '../../../styles/animations';
import { UNI_GOLD } from '../../../styles/colors';

const crowdShadow = 'benchmarks/images/rockBand/crowdShadow';
const crowd = 'benchmarks/images/rockBand/crowd';

function getScoreColor({ countUpFinished, isAGoodScore, theme }) {
  if (!countUpFinished) {
    return theme.textColor;
  }
  if (isAGoodScore) {
    return UNI_GOLD;
  }
  if (!isAGoodScore) {
    return UNI_GOLD;
  }
  return 'white';
}

const crowdAnimation = keyframes`

  from {
    bottom: 0;
  }

  50% {
    bottom: -5vh;
  }

  to {
    bottom: 0;
  }
`;

export const Crowd = styled(CloudImage).attrs((props) => ({
  publicId: `${crowd}${props.theme.cloudExtension}`,
}))`
  position: absolute;
  bottom: 0;
  width: ${({ isIntegrated }) => (isIntegrated ? '80%' : '100%')};
  left: ${({ isIntegrated }) => (isIntegrated ? '10%' : '0')};
  max-height: 45em;
  animation:
    ${(props) => (props.animationStarted ? crowdAnimation : slideInUp)}
    0.5s linear
    ${(props) => (props.animationStarted && 'infinite')};
`;

export const CrowdShadow = styled(CloudImage).attrs((props) => ({
  publicId: `${crowdShadow}${props.theme.cloudExtension}`,
}))`
  position: absolute;
  bottom: 0;
  width: ${({ isIntegrated }) => (isIntegrated ? '80%' : '100%')};
  left: ${({ isIntegrated }) => (isIntegrated ? '10%' : '0')};
  max-height: 45em;
  animation:
    ${(props) => (props.animationStarted ? crowdAnimation : slideInUp)}
    0.5s linear
    ${(props) => (props.animationStarted && 'infinite')};
  animation-delay: ${(props) => (props.animationStarted && '0.3s')};
`;

export const Score = styled.p`
  position: absolute;
  top: 30vh;
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 9vh;
  color: ${(props) => getScoreColor(props)};
  text-align: center;
  animation: ${zoomIn} 0.5s linear;
`;

export const ResultTitle = styled.p`
  position: absolute;
  bottom: 74vh;
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 5vh;
  color: ${(props) => props.theme.textColor};
  text-align: center;
  animation: ${zoomIn} 0.5s linear;
`;

export const ResultText = styled.p`
  position: absolute;
  top: 26vh;
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 4vh;
  color: ${(props) => props.theme.textColor};
  text-align: center;
  animation: ${zoomIn} 0.5s linear;
`;
