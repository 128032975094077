import { midiDataToMidiInfos } from '../../../../lib/midi/midiDataToMidiInfos';

function sortEventsByInstrument(events, instruments) {
  const instrumentsMap = instruments.reduce((acc, { name }) => ({
    ...acc,
    [name]: [],
  }), {});
  const instrumentNames = Object.keys(instrumentsMap);

  return events.reduce((
    instrumentEvents,
    event,
  ) => {
    event.targets.forEach((target) => {
      const targetInstrument = instrumentNames[target];
      if (targetInstrument) {
        instrumentEvents[targetInstrument].push(event);
      }
    });
    return instrumentEvents;
  }, instrumentsMap);
}

export default function getMidiInfo(midiPlayer, instruments) {
  const { events, tempo, division } = midiPlayer;
  const infos = midiDataToMidiInfos(
    events.flat(),
    tempo,
    division,
    instruments,
  );

  return {
    ...infos,
    colorEvents: sortEventsByInstrument(infos.colorEvents, instruments),
    specialEvents: sortEventsByInstrument(infos.specialEvents, instruments),
  };
}
