import styled from 'styled-components';

import { doubleBrightness, rubberBand, shake } from './animations';
import {
  AQUA,
  AQUA_LIGHT,
  FUSHIA,
  GREEN_PERSIAN,
  GREY_LIGHT,
  WHITE,
} from './colors';
import { H2_FONT_SIZE, NORMAL_FONT_SIZE } from './constants';

export const ButtonContainer = styled.div`
  height: ${(props) => props.height || 'unset'};
  width: ${(props) => props.width || 'fit-content'};
  padding: 0.3em 0.75em;
  font-size: ${(props) => props.fontSize || NORMAL_FONT_SIZE};
  color: ${(props) => props.theme.textColor};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (!props.disabled ? 1 : 0.5)};
  background-color: ${(props) => (props.secondary ? props.theme.secondaryColor : props.theme.primaryColor)};
  border-radius: 9999px;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
  }

  > * {
    user-select: none;
  }

  &:active {
    animation: ${doubleBrightness} 500ms;
  }

  @media (hover: none) {

    &:hover {
      animation: ${doubleBrightness} 500ms;
    }
  }
`;

export const GradientText = styled.p`
  background: linear-gradient(${GREEN_PERSIAN} 0 25%, ${AQUA} 25% 50%, ${AQUA_LIGHT} 50% 75%, ${WHITE} 50% 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(0%, ${GREEN_PERSIAN}), color-stop(50%, ${AQUA}), color-stop(51%, ${AQUA_LIGHT}), color-stop(100%, ${WHITE}));
  -webkit-text-fill-color: transparent;

  /* stylelint-disable property-no-vendor-prefix */
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
`;

export const GradientTitle = styled(GradientText)`
  font-size: 6.25vw;
  line-height: 0.85;
  width: 75%;
  margin: auto;
  margin-top: 8vw;
  text-align: center;
`;

export const NonGradientTitle = styled.p`
  font-size: 6.25vw;
  line-height: 0.85;
  width: 75%;
  margin: auto;
  margin-top: 8vw;
  text-align: center;
`;

export const FullScreenContainer = styled.div`
  ${(props) => (props.center ? 'display: flex' : '')}
  ${(props) => (props.center ? 'justify-content: center' : '')}
  ${(props) => (props.center ? 'align-items: center' : '')}
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.usePercents ? '100%' : '100vw')};
  height: ${(props) => (props.usePercents ? '100%' : '100vh')};
  overflow: hidden;
  z-index: 2;
  user-select: none;
`;

export const Input = styled.input`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : NORMAL_FONT_SIZE)};
  padding: 3vmin 7vmin;
  background-color: ${(props) => (!props.disabled ? 'white' : GREY_LIGHT)};
  border-radius: 10px;
  animation: ${(props) => (props.error ? shake : null)} 1000ms;

  &:focus {
    outline: none;
  }
`;

export const PhaserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoundedButton = styled.div`
  min-width: 35vmin;
  font-size: ${NORMAL_FONT_SIZE};
  text-align: center;
  color: ${AQUA};
  padding: 5vmin;
  border: 0.5vmin solid ${AQUA};
  border-radius: 20vmin;
  opacity: ${(props) => (!props.disabled ? 1 : 0.5)};
  cursor: pointer;

  &:active {
    animation: ${rubberBand} 500ms;
  }

  @media (hover: none) {

    &:hover {
      animation: ${rubberBand} 500ms;
    }
  }

  /* Disable select of text inside button */

  > * {
    user-select: none;
  }
`;

export const Subtitle = styled.p`
  font-size: ${H2_FONT_SIZE};
  color: white;
  width: 100%;
  text-align: center;
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${NORMAL_FONT_SIZE};
  color: ${(props) => props.theme.textColor};
  white-space: pre-wrap;
`;

export const TextArea = styled.textarea`
  width: 100%;
  font-size: ${NORMAL_FONT_SIZE};
  padding: 3vmin;
  background-color: white;
  border: 0.5vmin solid ${(props) => (!props.error ? AQUA : FUSHIA)};
  animation: ${(props) => (props.error ? shake : null)} 1000ms;
`;

export const TextCenter = styled.p`
  font-size: ${NORMAL_FONT_SIZE};
  color: ${AQUA};
  width: 100%;
  text-align: center;
`;

export const Title = styled.p`
  position: relative;
  font-size: 7vmin;
  font-weight: bold;
  color: #fff;
  width: 70%;
  text-align: center;
`;
