import styled, { keyframes } from 'styled-components';

const backgroundColor = '#000';

const opacityKeyFrame = keyframes`
  0% { opacity: 0; }
  20% { opacity: 100; }
  100% { opacity: 100; }
`;

export const Pixel = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${backgroundColor};
  ${(props) => props.animations || ''}
`;

export const FramesContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${backgroundColor};
`;

export const EffectFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: ${(props) => (props.color ? props.color : backgroundColor)};
  animation-name: ${opacityKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  animation-delay: ${(props) => props.delay}ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;
