import { goBack, push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import BlockedOrientation from '../../components/BlockedOrientation/BlockedOrientation';
import CustomBack from '../../components/CustomBack/CustomBack';
import NormalSelect from '../../components/NormalSelect/NormalSelect';
import languagesForSelect from '../../lib/languagesForSelect';
import Logger from '../../lib/Logger';
import { FullScreenContainer } from '../../styles/styledComponents';
import {
  BackgroundImage,
  EmblemContainer,
  Header,
  LanguageForm,
  NameText,
  ProfileContainer,
  ProfileSection,
  ProfileTitle,
  SectionInfo,
  SectionTitle,
  TeamEmblem,
  TermsLink,
} from './styles';

const ANIMATION_DURATION_MS = 500;
const BUTTON_DURATION_MS = 250;
export const EXIT_DURATION_MS = ANIMATION_DURATION_MS + BUTTON_DURATION_MS;

const propTypes = {
  analytics: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  username: PropTypes.string.isRequired,
};

const defaultProps = {
  analytics: undefined,
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);
    this.state = { opening: true };
    this.goBack = this.goBack.bind(this);
    this.goTerms = this.goTerms.bind(this);

    const { analytics } = props;
    if (analytics) {
      analytics.logEvent('visited_profile');
    }
  }

  componentDidMount() {
    clearTimeout(this.goToTheHubTimeout);
    clearTimeout(this.goToTheTermsTimeout);
  }

  goBack() {
    this.logger.trace('goBack()');
    const { opening } = this.state;
    const { dispatch } = this.props;
    if (opening) {
      this.goToTheHubTimeout = setTimeout(() => { // give time for the exit animation
        dispatch(goBack());
      }, EXIT_DURATION_MS);
      this.setState({ opening: false });
    }
  }

  goTerms() {
    this.logger.trace('goTerms()');
    const { opening } = this.state;
    const { analytics, dispatch } = this.props;
    if (opening) {
      this.goToTheTermsTimeout = setTimeout(() => { // give time for the exit animation
        if (analytics) {
          analytics.logEvent('visited_terms');
        }
        dispatch(push('/termsAndConditions'));
      }, EXIT_DURATION_MS);
      this.setState({ opening: false });
    }
  }

  renderUsernameSection() {
    const { username } = this.props;

    return (
      <ProfileSection>
        <SectionInfo>
          <EmblemContainer>
            <TeamEmblem publicId="benchmarks/images/profile/rock" />
          </EmblemContainer>
          <NameText>{username}</NameText>
        </SectionInfo>
      </ProfileSection>
    );
  }

  renderLanguageSection() {
    const { config, setLanguage, theme } = this.props;

    return (
      <ProfileSection>
        <SectionTitle><FormattedMessage id="profile_language" /></SectionTitle>
        <SectionInfo>
          <LanguageForm>
            <NormalSelect
              id="languageSelect"
              themeCode={theme && theme.themeCode}
              list={languagesForSelect}
              onChange={(event) => setLanguage(event.target.value)}
              value={config.language}
              style={{ width: '100%' }}
            />
          </LanguageForm>
        </SectionInfo>
      </ProfileSection>
    );
  }

  render() {
    this.logger.trace('render()');
    const { opening } = this.state;

    return (
      <>
        <FullScreenContainer usePercents>
          <ProfileContainer
            animationDelayMs={opening ? 250 : BUTTON_DURATION_MS}
            animationDurationMs={ANIMATION_DURATION_MS}
            opening={opening}
          >
            <BackgroundImage />
            <Header>
              <CustomBack onClick={this.goBack} id="backButton" />
            </Header>
            <ProfileTitle><FormattedMessage id="profile_title" /></ProfileTitle>
            {this.renderUsernameSection()}
            {this.renderLanguageSection()}
            <TermsLink onClick={this.goTerms}><FormattedMessage id="profile_termsAndConditions" /></TermsLink>
          </ProfileContainer>
        </FullScreenContainer>
        <BlockedOrientation orientation="landscape" />
      </>
    );
  }
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

Profile.contextTypes = {
  intl: PropTypes.shape(),
};

export default Profile;
