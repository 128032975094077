const settings = {
  anyware: {
    intermedia: {
      actionUrl: 'https://aggregation.skyshow.app',
      subscriptionUrl: 'https://sync.skyshow.app',
      url: '<NOT_USED_IN_PRODUCTION>',
    },
  },
  cloudinary: {
    cloudName: 'novom-networks',
    folder: 'skyshow',
  },
  dataBackend: {
    graphql: {
      url: 'https://data.skyshow.app/graphql',
    },
  },
  env: 'production',
  firebase: {
    apiKey: 'AIzaSyCdR5cdiDtCobviFiJZdv2EwUc0RZzUUlA',
    appId: '1:604245653316:web:405f3aba03cfb834c2c911',
    authDomain: 'skyshow-rockfactory.firebaseapp.com',
    databaseURL: 'https://skyshow-rockfactory.firebaseio.com',
    measurementId: 'G-T4RFEM9WK2',
    messagingSenderId: '604245653316',
    projectId: 'skyshow-rockfactory',
    storageBucket: 'skyshow-rockfactory.appspot.com',
  },
  logLevel: 'ERROR',
  useUrlEvent: true,
};

export default settings;
