import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_USER,
  SET_ID,
  SET_SCORE,
  SET_TEAM,
  SET_USERNAME,
} from './UserActions';

const logger = Logger.getLogger('UserReducer');

function UserReducer(state = initialStore.user, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_USER');
      return { ...initialStore.user };

    case RESET_USER:
      logger.trace('RESET_USER');
      return { ...initialStore.user };

    case SET_ID:
      logger.trace('SET_ID', action);
      return {
        ...state,
        id: action.id,
      };

    case SET_USERNAME:
      logger.trace('SET_USERNAME', action);
      return {
        ...state,
        username: action.username,
      };

    case SET_SCORE:
      logger.trace('SET_SCORE', action);
      return {
        ...state,
        score: {
          ...state.score,
          [action.game]: action.score,
        },
      };

    case SET_TEAM:
      logger.trace('SET_TEAM', action);
      return {
        ...state,
        team: {
          ...state.team,
          [action.game]: action.team,
        },
      };

    default:
      return state;
  }
}

export default UserReducer;
