import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { withGoogleAnalytics } from '../../../lib/GoogleAnalytics';
import { setPermissions } from '../../../reducers/ConfigReducer/ConfigActions';
import Permissions from './Permissions';

const mapStateToProps = (state) => ({
  permissions: state.config.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setPermissions: (permissions) => dispatch(setPermissions(permissions)),
});

const PermissionsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withGoogleAnalytics,
  withTheme,
)(Permissions);

export default PermissionsContainer;
