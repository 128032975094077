import FormControl from 'react-bootstrap/FormControl';
import Popover from 'react-bootstrap/Popover';
import styled from 'styled-components';

import { NAVY } from '../../../styles/colors';

export const TextAndInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UsernameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4vh;
`;

export const UsernameForm = styled.div`
  display: flex;
  position: relative;
  flex-grow: 2;
  fill: 1;
  width: 90vmin;
  min-height: 24vh;
  bottom: 4vh;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const UsernameQuestion = styled.div`
  width: auto;
  white-space: pre;
  text-align: left;
  font-size: 6vmin;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 4vh;
  color: #fff;
`;

export const UsernameAnswer = styled(FormControl)`
  white-space: pre;
  text-align: left;
  font-size: 5vmin;
  margin-top: 0;
  color: ${NAVY};
`;

export const ErrorPopover = styled(Popover)`
  font-size: 4vmin;
  text-align: center;
  background-color: pink;

  .arrow::after {
    border-bottom-color: pink;
  }

  .popover-body {
    color: darkred;
  }
`;

export const NextButtonContainer = styled.div`
  position: absolute;
  bottom: 8vh;
  justify-content: center;
`;
