import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  BackgroundImage,
  LinkText,
  Message,
  MessageScreenContainer,
} from './styles';

const propTypes = {
  linkLabelKey: PropTypes.string,
  linkPath: PropTypes.string,
  messageLabelKey: PropTypes.string.isRequired,
};

const defaultProps = {
  linkLabelKey: null,
  linkPath: null,
};

function MessageScreen(props) {
  const { linkLabelKey, linkPath, messageLabelKey } = props;

  return (
    <MessageScreenContainer>
      <BackgroundImage />
      <Message><FormattedMessage id={messageLabelKey} /></Message>
      {linkPath && linkLabelKey && (
        <LinkText><Link to={linkPath}><FormattedMessage id={linkLabelKey} /></Link></LinkText>
      )}
    </MessageScreenContainer>
  );
}

MessageScreen.propTypes = propTypes;
MessageScreen.defaultProps = defaultProps;

export default MessageScreen;
