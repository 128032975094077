import PropTypes from 'prop-types';
import { useState } from 'react';

import { NORMAL_FONT_SIZE } from '../../styles/constants';
import { Select, SelectContainer, TriangleDown } from './styles';

const propTypes = {
  id: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    labelKey: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({
    fontSize: PropTypes.string,
    height: PropTypes.string,
  }),
  value: PropTypes.string.isRequired,
};

const defaultProps = {
  id: null,
  style: {
    fontSize: NORMAL_FONT_SIZE,
    height: `calc(${NORMAL_FONT_SIZE} * 2)`,
  },
};

const contextTypes = {
  intl: PropTypes.shape(),
};

function NormalSelect(props, context) {
  const {
    id,
    list,
    onChange,
    style,
    value,
  } = props;
  const { intl } = context;
  const [focus, setFocus] = useState(false);

  return (
    <SelectContainer style={style}>
      <Select
        fontSize={style.fontSize || NORMAL_FONT_SIZE}
        id={id}
        onChange={onChange}
        value={value}
        onFocus={() => { setFocus(true); }}
        onBlur={() => { setFocus(false); }}
      >
        {list.map((element) => (
          <option value={element.optionValue} key={`${element.optionValue}Option`}>
            {intl ? intl.formatMessage({ id: element.labelKey }) : element.optionValue}
          </option>
        ))}
      </Select>
      <TriangleDown focus={focus} />
    </SelectContainer>
  );
}

NormalSelect.propTypes = propTypes;
NormalSelect.defaultProps = defaultProps;
NormalSelect.contextTypes = contextTypes;

export default NormalSelect;
