import styled from 'styled-components';

import { FullScreenContainer } from '../../styles/styledComponents';

export const MainContainer = styled(FullScreenContainer)`
  display: flex;
  flex-direction: column;
  background-color: #18181a;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 2vh;
  padding-bottom: 1vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const PageTitle = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 28px;
  flex: 1;
`;

export const PageText = styled.div`
  height: 70%;
  width: 100%;
  line-height: 70%;
  text-align: center;
  color: #fff;
  font-family: ${(props) => props.theme.fontFamilyRegular};
  font-size: 22px;
`;

export const NextButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 4%;
`;

export const BlockingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
`;
