import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import DIRECTIONS from '#lib/constants/Directions';

import noteStyles, { defaultStyle } from './notesStyles';
import { TrackContainer } from './styles';
import Track from './Track/Track';

const { HORIZONTAL, VERTICAL } = DIRECTIONS;

const defaultProps = {
  direction: 'vertical',
  playing: false,
};

const propTypes = {
  colorEvents: PropTypes.shape().isRequired,
  direction: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
  instrumentPartitions: PropTypes.shape().isRequired,
  playing: PropTypes.bool,
  specialEvents: PropTypes.shape().isRequired,
  teamInfos: PropTypes.shape().isRequired,
};

const initialTrackDimension = {
  height: Math.round(window.innerHeight),
  width: Math.round(window.innerWidth * 0.65),
};

function MidiVisualizer(props) {
  const {
    colorEvents,
    direction,
    instrumentPartitions,
    playing,
    specialEvents,
    teamInfos,
  } = props;

  const { name, partition } = instrumentPartitions;
  const [dimensions, setDimensions] = useState(initialTrackDimension);
  const [drawFunctions, setDrawFunctions] = useState([]);

  const handleDrawFunctionAdd = useCallback((func) => {
    setDrawFunctions((previousFuncs) => [...previousFuncs, func]);
  }, []);

  useEffect(() => {
    window.onresize = () => {
      setDimensions({
        height: Math.round(window.innerHeight),
        width: Math.round(window.innerWidth * 0.65),
      });
    };
  }, []);

  useEffect(() => {
    let drawRequest = null;
    function executeDrawFunctions(time) {
      drawFunctions.forEach((func) => func(time));
      drawRequest = requestAnimationFrame(executeDrawFunctions);
    }
    executeDrawFunctions();
    return () => {
      if (drawRequest) {
        cancelAnimationFrame(drawRequest);
      }
    };
  }, [drawFunctions]);

  return (
    <TrackContainer>
      <Track
        key={name}
        note={noteStyles[name] || defaultStyle}
        direction={direction}
        instrument={teamInfos[name].image}
        colorEvents={colorEvents[name]}
        noteEvents={partition}
        specialEvents={specialEvents[name]}
        playbackStatus={playing ? 'playing' : 'stopped'}
        height={dimensions.height}
        width={dimensions.width}
        onDrawFunctionAdd={handleDrawFunctionAdd}
      />
    </TrackContainer>
  );
}

MidiVisualizer.propTypes = propTypes;
MidiVisualizer.defaultProps = defaultProps;

export default MidiVisualizer;
