import { connect } from 'react-redux';

import { setInputType, setLastExtension } from '../../reducers/GameReducer/GameActions';
import { setTeam } from '../../reducers/UserReducer/UserActions';
import RockBand from './RockBand';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  resetInputType: () => dispatch(setInputType(undefined)),
  resetTeam: () => dispatch(setTeam('', 'music')),
  setInputType: (type) => dispatch(setInputType(type)),
  setLastMusicGame: (lastMusicGame) => dispatch(setLastExtension(lastMusicGame, 'music')),
});

const RockBandContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RockBand);

export default RockBandContainer;
