import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { withTheme } from 'styled-components';

import Logger from '../../../../../lib/Logger';
import handsUp from '../handsUp';
import { Instrument, TrackCanvas, TrackContainer } from './styles';

const BAR_SPACING_RATIO = 0.15;
class Track extends Component {
  constructor(props) {
    super(props);
    this.isSpecial = false;
    this.logger = Logger.getLogger(this.constructor.name);
    this.canvasRef = createRef();
    this.noteCanvasRef = createRef();
    this.draw = this.draw.bind(this);
    this.drawBackground = this.drawBackground.bind(this);
    this.drawPlayBar = this.drawPlayBar.bind(this);
    this.drawNote = this.drawNote.bind(this);
    this.drawGradientTriangle = this.drawGradientTriangle.bind(this);
    this.generateColorScale = this.generateColorScale.bind(this);
    this.resizeCanvas = this.resizeCanvas.bind(this);
    this.prepareNote = this.prepareNote.bind(this);
    this.preparedNote = null;
    this.preparedNotePlaying = null;
    this.axisLength = props.direction === 'vertical' ? props.height : props.width;
    this.axisInverseLength = props.direction === 'vertical' ? props.width : props.height;
    this.playbackTime = 0;
  }

  componentDidMount() {
    const { onDrawFunctionAdd } = this.props;

    this.preparedNote = this.prepareNote();
    this.preparedNotePlaying = this.prepareNote(true);
    this.generateColorScale();
    this.setCanvasContexts();
    onDrawFunctionAdd(this.draw);
  }

  componentDidUpdate(prevProps) {
    const {
      colorEvents,
      direction,
      height,
      width,
    } = this.props;
    if (colorEvents !== prevProps.colorEvents) {
      this.generateColorScale();
    }

    if (height !== prevProps.height || width !== prevProps.width) {
      this.axisLength = direction === 'vertical' ? height : width;
      this.axisInverseLength = direction === 'vertical' ? width : height;
      this.resizeCanvas();
    }
  }

  setCanvasContexts() {
    const canvas = this.canvasRef.current;
    const noteCanvas = this.noteCanvasRef.current;
    if (canvas) this.ctx = canvas.getContext('2d');
    if (noteCanvas) this.noteCtx = noteCanvas.getContext('2d');
  }

  resizeCanvas() {
    const {
      height,
      width,
    } = this.props;
    const canvas = this.canvasRef.current;
    const noteCanvas = this.noteCanvasRef.current;

    if (canvas) {
      canvas.height = height;
      canvas.width = width;
      canvas.style.height = height;
      canvas.style.width = width;
    }

    if (noteCanvas) {
      noteCanvas.height = height;
      noteCanvas.width = width;
      noteCanvas.style.height = height;
      noteCanvas.style.width = width;
    }

    this.setCanvasContexts();
  }

  prepareNote(playing = false) {
    const {
      note,
    } = this.props;

    const noteColor = playing ? note.active.color : note.color;
    const innerNoteColor = playing ? note.active.innerColor : note.innerColor;

    const canvas = document.createElement('canvas');
    canvas.width = 80;
    canvas.height = 80;
    const ctx = canvas.getContext('2d');

    ctx.beginPath();
    ctx.fillStyle = noteColor;
    ctx.arc(40, 40, 40, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    // Inner note circle
    ctx.beginPath();
    ctx.fillStyle = innerNoteColor;
    ctx.arc(40, 40, 30, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    if (playing) {
      ctx.beginPath();
      ctx.strokeStyle = '#ffffff';
      ctx.lineWidth = 10;
      ctx.arc(40, 40, 25, 1.05 * Math.PI, 1.45 * Math.PI);
      ctx.stroke();
      ctx.closePath();
    }

    return canvas;
  }

  draw(time) {
    const {
      direction,
      height,
      noteEvents,
      playbackStatus,
      specialEvents,
      width,
    } = this.props;
    const canvas = this.canvasRef.current;
    if (!this.previousTime) {
      this.previousTime = time;
    }

    const timeDelta = time - this.previousTime;
    this.previousTime = time;
    switch (playbackStatus) {
      case 'stopped':
        this.playbackTime = 0;
        break;
      case 'playing':
        if (Number.isFinite(timeDelta)) {
          this.playbackTime += timeDelta;
        }
        break;
      default:
        break;
    }

    const lastSpecialEvent = specialEvents.reduce((last, specialEvent) => {
      if (specialEvent.startTime <= this.playbackTime && specialEvent.startTime > last.startTime) {
        return specialEvent;
      }
      return last;
    }, { name: 'off', startTime: 0 });

    if (canvas) {
      this.ctx.clearRect(0, 0, width, height);
      if (lastSpecialEvent) {
        switch (lastSpecialEvent.name) {
          case 'hands_up':
            this.isSpecial = true;
            handsUp(canvas, this.playbackTime - lastSpecialEvent.startTime);
            break;
          case 'off':
            this.isSpecial = false;
            break;
          default:
            break;
        }
      }
      if (!this.isSpecial) {
        this.drawBackground(this.ctx);
        this.drawPlayBar(false, this.ctx);

        if (direction === 'vertical') {
          this.noteCtx.clearRect(width / 2 - 40, 0, 80, height);
        } else {
          this.noteCtx.clearRect(0, height / 2 - 40, width, 80);
        }
        noteEvents.forEach(({ startTime }) => {
          const timeUntilPlay = startTime - this.playbackTime;
          if (timeUntilPlay < this.axisLength - (this.axisLength * BAR_SPACING_RATIO)
            && timeUntilPlay > 0 - (this.axisLength * BAR_SPACING_RATIO)
          ) {
            this.drawNote(startTime, this.playbackTime, this.noteCtx, this.ctx);
          }
        });
      }
    }
  }

  drawNumberOfNotes(n, ctx) {
    ctx.fillStyle = '#ffffff';
    ctx.font = '30px Arial';
    ctx.fillText(n, 50, 50);
  }

  drawBackground(ctx) {
    const { direction, height, width } = this.props;

    if (this.colorScale) {
      const trueColor = this.colorScale(this.playbackTime);

      const generalGradient = ctx.createLinearGradient(
        (direction === 'vertical' ? 0 : width),
        (direction === 'vertical' ? height : 0),
        0,
        0,
      );
      generalGradient.addColorStop(0, trueColor.alpha(0.4));
      generalGradient.addColorStop(0.7, trueColor.alpha(0.2));
      generalGradient.addColorStop(1, trueColor.alpha(0));
      ctx.fillStyle = generalGradient;
      ctx.fillRect(0, 0, width, height);

      if (direction === 'vertical') {
        this.generateGradient(
          {
            height,
            width: 60,
            x: 0,
            y: 0,
          },
          [0, 0, 60, 0],
          trueColor,
          ctx,
          'left',
        );
        this.generateGradient(
          {
            height,
            width: 60,
            x: width - 60,
            y: 0,
          },
          [60, 0, 0, 0],
          trueColor,
          ctx,
          'right',
        );
      } else {
        this.generateGradient(
          {
            height: 60,
            width,
            x: 0,
            y: 0,
          },
          [0, 0, 0, 60],
          trueColor,
          ctx,
          'top',
        );
        this.generateGradient(
          {
            height: 60,
            width,
            x: 0,
            y: height - 60,
          },
          [0, 60, 0, 0],
          trueColor,
          ctx,
          'bottom',
        );
      }
    } else {
      ctx.fillStyle = '#ffffff00';
      ctx.fillRect(0, 0, width, height);
    }
  }

  /**
   * @param {{ x: number, y: number, width: number, height: number }} box
   * @param {Array<number>} [x0, y0, x1, y1]
   * @param {string} color
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} linePos
   * @memberof Track
   */
  generateGradient(box, [x0, y0, x1, y1], color, ctx, linePos) {
    const {
      x,
      y,
      width,
      height,
    } = box;

    const generalGradient = ctx.createLinearGradient(
      x0 + x,
      y0 + y,
      x1 + x,
      y1 + y,
    );
    generalGradient.addColorStop(0, color.alpha(0.8));
    generalGradient.addColorStop(0.7, color.alpha(0.2));
    generalGradient.addColorStop(1, color.alpha(0));
    ctx.save();
    ctx.fillStyle = generalGradient;
    ctx.fillRect(x, y, width, height);
    if (linePos === 'left') {
      ctx.fillStyle = color;
      ctx.fillRect(x, y, 3, height);
    } else if (linePos === 'right') {
      ctx.fillStyle = color;
      ctx.fillRect(x + width - 3, y, 3, height);
    } else if (linePos === 'top') {
      ctx.fillStyle = color;
      ctx.fillRect(x, y, width, 3);
    } else if (linePos === 'bottom') {
      ctx.fillStyle = color;
      ctx.fillRect(x, y + height - 3, width, 3);
    }
    ctx.restore();
  }

  drawPlayBar(active, ctx) {
    const {
      bar,
      direction,
      height,
      width,
    } = this.props;
    ctx.strokeStyle = active ? bar.activeColor : bar.color;
    ctx.lineWidth = 2;
    ctx.beginPath();
    if (direction === 'vertical') {
      ctx.moveTo(width, height - (height * BAR_SPACING_RATIO));
      ctx.lineTo(0, height - (height * BAR_SPACING_RATIO));
    } else {
      ctx.moveTo(width - (width * BAR_SPACING_RATIO), height);
      ctx.lineTo(width - (width * BAR_SPACING_RATIO), 0);
    }
    ctx.stroke();
    ctx.closePath();
  }

  drawNote(noteTime, songTime, ctx, backgroundCtx) {
    const {
      direction,
      height,
      noteSpeed,
      width,
    } = this.props;

    let isPlaying = false;

    const playBarPos = this.axisLength - (this.axisLength * BAR_SPACING_RATIO);
    let noteX;
    let noteY;
    if (direction === 'vertical') {
      noteX = +width / 2;
      noteY = +playBarPos - ((+noteTime - +songTime) * +noteSpeed);
    } else {
      noteX = +playBarPos - ((+noteTime - +songTime) * +noteSpeed);
      noteY = +height / 2.0;
    }

    if (Math.abs(noteTime - songTime) <= 100) {
      this.drawPlayBar(true, backgroundCtx);
      if (direction === 'vertical') {
        this.drawGradientTriangle(
          noteX,
          noteY,
          0,
          playBarPos,
          this.axisInverseLength,
          playBarPos,
          backgroundCtx,
        );
      } else {
        this.drawGradientTriangle(
          noteX,
          noteY,
          playBarPos,
          0,
          playBarPos,
          this.axisInverseLength,
          backgroundCtx,
        );
      }

      isPlaying = true;
    }

    ctx.drawImage(
      isPlaying ? this.preparedNotePlaying : this.preparedNote,
      noteX - 40,
      noteY - 40,
    );
  }

  drawGradientTriangle(x1, y1, x2, y2, x3, y3, ctx) {
    const { note } = this.props;

    ctx.beginPath();
    const grd = ctx.createRadialGradient(x1, y1, 10, x1, y1, note.active.gradientSize);
    grd.addColorStop(0, note.active.color);
    grd.addColorStop(1, note.active.gradientEndColor);
    ctx.fillStyle = grd;
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x3, y3);
    ctx.lineTo(x1, y1);
    ctx.fill();
    ctx.closePath();
  }

  generateColorScale() {
    const { colorEvents } = this.props;
    if (colorEvents.length > 0) {
      this.colorScale = chroma
        .scale(colorEvents.map((ce) => ce.color))
        .mode('lab')
        .domain(colorEvents.map((ce) => ce.startTime));
    } else {
      this.colorScale = null;
    }
  }

  render() {
    this.logger.trace('render()');
    const {
      direction,
      height,
      instrument,
      width,
      theme,
    } = this.props;

    return (
      <TrackContainer
        dimensions={{ height, width }}
      >
        <TrackCanvas
          direction={direction}
          width={width}
          height={height}
          ref={this.canvasRef}
        />
        <TrackCanvas
          direction={direction}
          width={width}
          height={height}
          ref={this.noteCanvasRef}
        />
        <Instrument
          direction={direction}
          publicId={`${instrument}${theme.cloudExtension}`}
        />

      </TrackContainer>
    );
  }
}

Track.propTypes = {
  bar: PropTypes.shape(),
  colorEvents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  height: PropTypes.number,
  instrument: PropTypes.string.isRequired,
  note: PropTypes.shape(),
  noteEvents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  noteSpeed: PropTypes.number,
  onDrawFunctionAdd: PropTypes.func.isRequired,
  playbackStatus: PropTypes.string,
  specialEvents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  theme: PropTypes.shape().isRequired,
  width: PropTypes.number,
};

Track.defaultProps = {
  bar: {
    activeColor: '#2de8b0',
    color: '#deddf0',
  },
  direction: 'horizontal',
  height: window.innerHeight,
  note: {
    active: {
      color: '#2de8b0',
      gradientEndColor: '#2de8b033',
      gradientSize: 300,
      innerColor: '#2de8b0ff',
    },
    color: '#deddf0',
    innerColor: '#eeedf0',
  },
  noteSpeed: 1,
  playbackStatus: 'stopped',
  width: window.innerWidth,
};

export default withTheme(Track);
