import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

import { slideInRight, slideOutRight } from '../../styles/animations';

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 3vmin;
  z-index: 4;
`;

export const BigTitle = styled.div`
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
  margin-top: 1vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 8vh;
  padding-bottom: 10px;
  visibility: ${(props) => (props.opening ? 'hidden' : 'visible')};
  animation:
    ${(props) => (props.opening ? slideInRight : slideOutRight)}
    ${(props) => props.animationDurationMs}ms
    forwards;
  animation-delay: ${(props) => props.animationDelayMs}ms;
  overflow-y: auto;
`;

export const Content = styled(Accordion)`
  height: 90%;
`;

export const EntryTitle = styled.div`
  width: 75%;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin: 1vw;
`;

export const Text = styled(Accordion.Body)`
  font-size: 0.75em;
  padding-left: 3vw;
  padding-right: 3vw;
`;

export const Title = styled(Accordion.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1em;
  text-align: left;
  color: #fff;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: transparent;
  border: none;
`;

export const Item = styled(Accordion.Item)`
  background-color: transparent;
`;
