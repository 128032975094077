import { connect } from 'react-redux';

import Orchestration from './Orchestration';

const mapStateToProps = (state) => ({
  user: state.user,
});

const OrchestrationContainer = connect(
  mapStateToProps,
)(Orchestration);

export default OrchestrationContainer;
