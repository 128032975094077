import { CloudImage } from '@novom/cloudinary-image';
import Switch from 'react-switch';
import styled from 'styled-components';

import { WHITE } from '../../../styles/colors';
import { NORMAL_FONT_SIZE } from '../../../styles/constants';
import { FullScreenContainer } from '../../../styles/styledComponents';

const waveBackground1 = 'benchmarks/images/app/wave';
const waveBackground2 = 'benchmarks/images/app/wave2';
const waveBackgroundTrivia = 'benchmarks/images/app/trivia2';
const waveBackgroundLight = 'benchmarks/images/app/lightshow2';
const waveBackground = {
  integrated: {
    rockband: 'assets/app/desktop/Display_B_BTH_intro.jpg',
  },
  normal: {
    bingo: waveBackground1,
    lightShow: waveBackground1,
    poll: waveBackground1,
    rockband: waveBackground1,
    trivia: waveBackground1,
  },
  rougeOr: {
    bingo: waveBackground2,
    lightShow: waveBackgroundLight,
    poll: waveBackgroundTrivia,
    rockband: waveBackground2,
    trivia: waveBackgroundTrivia,
  },
};

export const IntegratedWaitingScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const CarouselContainer = styled.div`
  width: 40%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WaitingContainer = styled(FullScreenContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const WaitingImage = styled(CloudImage)`
  max-width: 94%;
  max-height: 94%;
`;

export const WaitingImageContainer = styled.div`
  flex: 1.25;
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
`;

export const OrchestrationNotificationSwitch = styled(Switch)`
  margin-top: 5vmin;
`;

export const OrchestrationNotificationText = styled.p`
  font-size: ${NORMAL_FONT_SIZE};
  color: ${WHITE};
`;

export const WaitingTitleContainer = styled.div`
  display: flex;
  position: relative;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 6vh;
  color: ${(props) => props.theme.textColor};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const WaitingTimeText = styled.p`
  color: ${(props) => props.theme.secondaryColor};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const TextShadow = styled.span`
  text-shadow: 2px 2px black;
`;

export const BackgroundImage = styled(CloudImage).attrs((props) => ({
  publicId: waveBackground[props.isIntegrated ? 'integrated' : props.theme.themeCode || 'rougeOr'][props.selectedGame || 'rockband'],
}))`
  position: absolute;
  width: 100%;
  height: 50%;
  object-fit: cover;
  object-position: bottom;
`;
