import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_LIGHT_SHOW = gql`
  query getLightShow($id: String!) {
    getLightShow(id: $id) {
      teamMode,
      matrix {
        id
      }
      geofences {
        id
        collection
      }
    }
  }
`;
