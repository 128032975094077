import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import settings from '../settings';

const CACHE_GARBAGE_COLLECTION_INTERVAL = 60000;

const cache = new InMemoryCache();

setInterval(() => {
  cache.gc();
}, CACHE_GARBAGE_COLLECTION_INTERVAL);

const apolloClient = new ApolloClient({
  cache,
  link: createHttpLink({ uri: settings.dataBackend.graphql.url }),
});

export default apolloClient;
