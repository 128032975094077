import { Route, Switch } from 'react-router';

import PageNotFound from '../components/PageNotFound/PageNotFound';
// import AppInfo from '../modules/AppInfo/AppInfoContainer';
// import BeaconYourFriends from '../modules/BeaconYourFriends/BeaconYourFriendsContainer';
// import CampaignClient from '../modules/CampaignClient/CampaignClientContainer';
import Onboarding from '../modules/Onboarding/OnboardingContainer';
import Orchestration from '../modules/Orchestration/OrchestrationContainer';
import PrizeWon from '../modules/PrizeWon/PrizeWon';
import Profile from '../modules/Profile/ProfileContainer';
import TermsAndConditions from '../modules/TermsAndConditions/TermsAndConditionsContainer';

const Routes = (
  <Switch>
    <Route exact path="/onboarding"><Onboarding /></Route>
    <Route exact path="/orchestration"><Orchestration /></Route>
    <Route exact path="/profile"><Profile /></Route>
    <Route exact path="/prize"><PrizeWon /></Route>
    <Route exact path="/termsAndConditions"><TermsAndConditions /></Route>
    <Route exact path="/"><Onboarding /></Route>
    <Route><PageNotFound /></Route>
  </Switch>
);

export default Routes;
