export function convertMidiTickToMs(delta, tempo, ppq) {
  return ((delta / ppq) / tempo) * 60000;
}

function fixBrokenCharacter(character) {
  return character.replace(/Ã©/g, 'é')
    .replace(/Ã¨/g, 'è')
    .replace(/Ãª/g, 'ê')
    .replace(/Ã¬è/g, 'iè')
    .replace(/Ã´/g, 'ô')
    .replace(/Ã /g, 'à') /* eslint-disable-line no-irregular-whitespace */
    .replace(/Ã/g, 'À')
    .replace(/Ã¹/g, 'ù');
}

export function midiDataToMidiInfos(
  midiData,
  tempo,
  division,
  instruments,
) {
  const infos = midiData.reduce(({
    colorEvents,
    instrumentPartitions,
    specialEvents,
    textEvents,
  }, event) => {
    const {
      tick,
      track,
      name,
      noteName,
    } = event;
    const startTime = convertMidiTickToMs(tick, tempo, division);
    if (name === 'Note on') {
      return {
        colorEvents,
        instrumentPartitions: instrumentPartitions.map((instrumentData) => {
          if (instrumentData.noteName === noteName) {
            return {
              ...instrumentData,
              partition: [...instrumentData.partition, { noteName, startTime }],
            };
          }
          return instrumentData;
        }),
        specialEvents,
        textEvents,
      };
    }
    if (name === 'Text Event') {
      switch (track) {
        case 3: {
          let text;
          try {
            text = JSON.parse(event.string);
          } catch (_) {
            text = [event.string];
          }

          text = text.map((c) => {
            if (typeof c === 'string') {
              return fixBrokenCharacter(c);
            }

            return {
              c: c.c,
              t: fixBrokenCharacter(c.t),
            };
          });

          return {
            colorEvents,
            instrumentPartitions,
            specialEvents,
            textEvents: [
              ...textEvents,
              {
                startTime,
                text,
              },
            ],
          };
        }
        case 4: {
          try {
            const colorObject = JSON.parse(event.string);
            return {
              colorEvents: [
                ...colorEvents,
                {
                  color: colorObject.color,
                  startTime,
                  targets: colorObject.targets,
                },
              ],
              instrumentPartitions,
              specialEvents,
              textEvents,
            };
          } catch (_) {
            break;
          }
        }
        case 5: {
          try {
            const specialEventObject = JSON.parse(event.string);
            return {
              colorEvents,
              instrumentPartitions,
              specialEvents: [
                ...specialEvents,
                {
                  name: specialEventObject.name,
                  startTime,
                  targets: specialEventObject.targets,
                },
              ],
              textEvents,
            };
          } catch (_) {
            break;
          }
        }
        default:
          break;
      }
    }
    return {
      colorEvents,
      instrumentPartitions,
      specialEvents,
      textEvents,
    };
  }, {
    colorEvents: [],
    instrumentPartitions: instruments.map((instrument) => ({ ...instrument, partition: [] })),
    specialEvents: [],
    textEvents: [],
  });
  return infos;
}
