import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import Introduction from './Introduction';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const IntroductionContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTheme,
)(Introduction);

export default IntroductionContainer;
