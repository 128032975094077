import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_EVENT = gql`
  query getEvent($id: String!) {
    getEvent(id: $id) {
      id
      eventInfo {
        eventName
        eventLogo
        eventDesc {
          en
          fr
        }
        isIntegrated
        socialNetworkLinks {
          facebook
          twitter
        }
        welcomeMessage
      }
      activitiesOrder
      geofences
      onboarding {
        permissionsEnabled
        seatEnabled
        storyEnabled
      }
      title
      isMono
      cnamePrefix
    }
  }
`;
