import styled from 'styled-components';

export const TrackContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EmptyDiv = styled.div``;
