import styled, { keyframes } from 'styled-components';

export const PreAnswerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PreAnswerText = styled.p`
  color: white;
  font-size: 2em;
`;

const popIn = keyframes`
  0% { transform: rotate(0); }
  15% { transform: scale(1); }
  45% { transform: rotate(0); }
  50% { transform: scale(2) rotate(-30deg); }
  60% { transform: scale(2) rotate(30deg); }
  65% { transform: scale(2); }
  70% { transform: rotate(-20deg); }
  80% { transform: rotate(20deg); }
  90% { transform: rotate(-10deg); }
  100% { transform: scale(1) rotate(0); }
`;

export const PreAnswerNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 3em;
  align-items: center;
`;

export const PreAnswerNumber = styled.span`
  color: white;
  font-size: 2em;
  animation: ${popIn} 1.5s ease-in-out;
  margin-right: 10px;
  text-align: center;
`;
