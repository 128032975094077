import { useQuery } from '@apollo/client';
import { useIntermediaClient, useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { GET_MUSIC_GAME } from '#lib/apollo/MusicGame/queries';

import Loading from '../../components/Loading/Loading';
import Transition from '../../components/Transition/Transition';
import { GET_USER_TEAM } from '../../lib/apollo/queries';
import Logger from '../../lib/Logger';
import EventContext from '../../providers/EventProvider/EventContext';
import { setTeam as setReduxTeam } from '../../reducers/UserReducer/UserActions';
import Introduction from '../Introduction/IntroductionContainer';
import Leaderboard from '../Leaderboard/LeaderboardContainer';
import IntegratedTeamSelection from './Integrated/teamSelection';
import MultiplayerGame from './MultiplayerGame';
import Result from './Result/Result';
import MUSIC_GAME_STEPS from './steps';

const logger = Logger.getLogger('RockBand');

const propTypes = {
  musicGameId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function RockBand(props) {
  const {
    musicGameId,
    user,
  } = props;
  const dispatch = useDispatch();
  const { isIntegrated } = useContext(EventContext);
  const intermediaClient = useIntermediaClient();
  const [team, setTeam] = useState(null);
  const inputType = 'touch';
  const { data: rtData } = useSubscription('musicGame');
  const {
    data,
    loading,
    error,
  } = useQuery(GET_MUSIC_GAME, { variables: { id: musicGameId } });
  const getUserTeamQuery = useQuery(
    GET_USER_TEAM,
    {
      variables: { activityId: musicGameId, userId: user.id },
    },
  );

  useEffect(() => {
    if (getUserTeamQuery?.getActivityUser?.data) {
      setTeam(getUserTeamQuery.getActivityUser.data.team.name);
    }
  }, [getUserTeamQuery]);

  const handleTeamSelect = useCallback((selectedTeam) => {
    setTeam(selectedTeam);
    dispatch(setReduxTeam(selectedTeam, 'music'));
  }, [setTeam, dispatch]);

  if (loading || error || !rtData) return <Loading />;
  const { teams, song } = data.getMusicGame;
  const { step: currentStep } = rtData;

  logger.trace('render()', data, rtData, getUserTeamQuery, team, inputType);

  return (
    <Transition currentStep={currentStep} startShown>
      {() => (
        <>
          {currentStep === MUSIC_GAME_STEPS.INTRODUCTION && (
            <Introduction selectedGame="rockband" />
          )}
          {(
            currentStep === MUSIC_GAME_STEPS.WAITING
            || currentStep === MUSIC_GAME_STEPS.WARMUP
            || currentStep === MUSIC_GAME_STEPS.GAME
          ) && (
          <Transition currentStep={team} startShown>
            {() => (
              <>
                {team && (
                  <MultiplayerGame
                    activityId={musicGameId}
                    song={song}
                    teamInfos={teams}
                    isIntegrated={isIntegrated}
                    inputType={inputType}
                    intermediaClient={intermediaClient}
                    currentStep={currentStep}
                    team={team}
                  />
                )}
                {!team && (
                  <IntegratedTeamSelection
                    teams={teams}
                    setTeam={handleTeamSelect}
                    late
                    activityId={musicGameId}
                  />
                )}
              </>
            )}
          </Transition>
          )}
          {currentStep === MUSIC_GAME_STEPS.RESULT && (
            <Result />
          )}
          {currentStep === MUSIC_GAME_STEPS.TEAM_SELECTION && (
            <Transition currentStep={team} startShown>
              {() => (
                <>
                  <IntegratedTeamSelection
                    activityId={musicGameId}
                    teams={teams}
                    setTeam={setTeam}
                  />
                </>
              )}
            </Transition>
          )}
          {currentStep === MUSIC_GAME_STEPS.LEADERBOARD && (
            <Leaderboard activityId={musicGameId} activityType="music" />
          )}
        </>
      )}
    </Transition>
  );
}

RockBand.propTypes = propTypes;

export default React.memo(RockBand);
