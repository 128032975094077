export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export function addError(errorName) {
  return { errorName, type: ADD_ERROR };
}

export function removeError(errorName) {
  return { errorName, type: REMOVE_ERROR };
}
