import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { scheduleToCSS } from './scheduleConversion';
import { Pixel } from './styles';

const propTypes = {
  pixel: PropTypes.shape().isRequired,
  serverOffset: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
};

function EffectDisplay({ pixel, startTime, serverOffset }) {
  const [animations, setAnimations] = useState();
  const pixelRef = useRef({});
  const { schedule } = pixel;

  useEffect(() => {
    const { current } = pixelRef;

    if (current) {
      const timeLeft = startTime - (+moment.utc() + serverOffset);
      setAnimations(scheduleToCSS(schedule, timeLeft));
    }
  }, [pixelRef, schedule, serverOffset, startTime]);

  return (
    <Pixel ref={pixelRef} animations={animations} />
  );
}

EffectDisplay.propTypes = propTypes;

export default EffectDisplay;
