import {
  faAddressCard,
  faPlus,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  NavBar,
  NavController,
  NavControllerIcon,
  NavIcon,
  NavItem,
  NavItemText,
  NavWrapper,
} from './styles';

const items = [
  { icon: faAddressCard, path: '/profile', text: 'Profile' },
  { icon: faTrophy, path: '/prize', text: 'Prize' },
];

function NavMenu({ navigate }) {
  const [open, setOpen] = useState(false);
  return (
    <NavWrapper open={open} right>
      <NavBar>
        <NavController>
          <NavControllerIcon open={open} onClick={() => setOpen(!open)}>
            <FontAwesomeIcon color="white" icon={faPlus} />
          </NavControllerIcon>
        </NavController>
        {items.map((item, i) => (
          <NavItem
            onClick={open ? () => navigate(item.path) : null}
            open={open}
            index={i}
            key={item.text}
          >
            <NavIcon>
              <FontAwesomeIcon color="white" icon={item.icon} />
            </NavIcon>
            <NavItemText open={open} index={i}>
              {item.text}
            </NavItemText>
          </NavItem>
        ))}
      </NavBar>
    </NavWrapper>
  );
}

NavMenu.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default NavMenu;
