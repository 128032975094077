import { connect } from 'react-redux';

import { setGeolocationPermission } from '../../reducers/ConfigReducer/ConfigActions';
import { setPosition } from '../../reducers/LocationReducer/LocationActions';
import GeolocationTracker from './GeolocationTracker';

const mapStateToProps = (state) => ({
  location: state.location,
  permissions: state.config.permissions,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setGeolocationPermission: () => { dispatch(setGeolocationPermission()); },
  setPosition: (position) => dispatch(setPosition(position)),
});

const GeolocationTrackerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeolocationTracker);

export default GeolocationTrackerContainer;
