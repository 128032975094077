import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PRIZES_WON = gql`
  query getAllWin($userId: String!) {
    getAllWin(userId: $userId) {
      id
      code
    }
  }
`;
