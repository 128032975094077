import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Loading from '#components/Loading/Loading';
import settings from '#lib/settings';

import { GET_EVENT } from '../../lib/apollo/Event/queries';
import EventContext from './EventContext';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const defaultProps = {
  children: undefined,
};

function EventProvider(props) {
  const { children } = props;
  let eventId = 'DEFAULT_DEV_EVENT_ID';

  if (settings.useUrlEvent) {
    eventId = `Event_${window.location.host.split('.')[0]}`;
  }

  const { data, loading } = useQuery(GET_EVENT, {
    fetchPolicy: 'network-only',
    variables: { id: eventId },
  });

  const value = useMemo(() => {
    if (data) {
      const {
        getEvent: {
          isMono,
          cnamePrefix,
          eventInfo: { isIntegrated },
          onboarding,
        },
      } = data;
      return {
        cnamePrefix,
        eventId,
        isIntegrated,
        isMono,
        onboarding,
      };
    }
    return { eventId, isIntegrated: false, isMono: true };
  }, [data, eventId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  );
}

EventProvider.propTypes = propTypes;
EventProvider.defaultProps = defaultProps;

export default EventProvider;
