import { CloudImage } from '@novom/cloudinary-image';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`;

export const Loading = styled.div`
  margin-left: 10px;
`;

export const PermissionsContainer = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const PermissionContainer = styled(Row)`

  /* NOTHING */
`;

export const PermissionTextContainer = styled(Col)`

  /* NOTHING */
`;

export const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PermissionCheck = styled.input`
  transform: scale(2);
  margin-right: 20px;
`;

export const PermissionError = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 0.9em;
  text-align: center;
`;

export const PermissionForm = styled.div`
  margin-top: 20px;
`;

export const PermissionMessages = styled.div`
  height: 10vh;
`;

export const PermissionTitle = styled.div`
  width: 70%;
  color: #fff;
  font-weight: bold;
`;

export const PermissionText = styled.div`
  color: #fff;
  width: 100%;
  text-align: left;
  font-size: 4vmin;
  overflow: auto;
  display: flex;
  align-items: center;
`;

export const PermissionWarn = styled.div`
  margin-top: 5px;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
`;

export const AuthorizeButton = styled(Button)`
  margin-top: 1vh;
  font-size: 2.5vmax;
  background-color: ${(props) => props.theme.secondaryColor};
  border-color: ${(props) => props.theme.secondaryColor};
  color: white;

  :focus {
    background-color: ${(props) => props.theme.secondaryColor};
    border-color: ${(props) => props.theme.secondaryColor};
    color: white;
    box-shadow: none;
    outline: 0;
  }

  :active {
    box-shadow: none;
    outline: 0;
  }

  :disabled {
    background-color: ${(props) => props.theme.secondaryColor};
    border-color: ${(props) => props.theme.secondaryColor};
    color: white;
    opacity: 0.7;
  }
`;

export const SVGImage = styled(CloudImage)`
  max-height: 150px;
`;
