import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_WINNING_TEAM } from '#lib/apollo/queries';

import Loading from '../../../components/Loading/Loading';
import { FullScreenContainer } from '../../../styles/styledComponents';
import { FlashingDisplay, StaleDisplay } from './styles';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
};

function WinnerOrchestration({ activityId, team }) {
  const { data, loading } = useQuery(
    GET_WINNING_TEAM,
    { variables: { activityId } },
  );

  if (loading) return <Loading />;

  const winningTeam = data.getWinningTeam;

  return (
    <FullScreenContainer usePercents>
      {team === winningTeam?.name
        ? <FlashingDisplay color={winningTeam?.color} />
        : <StaleDisplay />}
    </FullScreenContainer>
  );
}

WinnerOrchestration.propTypes = propTypes;

export default WinnerOrchestration;
