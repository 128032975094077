import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  CarouselContainer,
  CarouselTitle,
  TutorialCarousel,
} from './styles';

const propTypes = {
  interval: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const defaultProps = {
  interval: 5000,
};

function Carousel({ interval, steps, title }) {
  return (
    <CarouselContainer>
      <CarouselTitle>
        {typeof title === 'string' ? (
          <FormattedMessage id={title} />
        ) : (
          title
        )}
      </CarouselTitle>
      <TutorialCarousel
        indicators={false}
        interval={interval}
        controls={false}
        pauseOnHover={false}
        wrap
      >
        {steps}
      </TutorialCarousel>
    </CarouselContainer>
  );
}

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
