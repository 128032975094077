import { useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Loading from '../../components/Loading/Loading';
import Transition from '../../components/Transition/Transition';
import Logger from '../../lib/Logger';
import { FullScreenContainer } from '../../styles/styledComponents';
import Introduction from '../Introduction/IntroductionContainer';
import Leaderboard from '../Leaderboard/LeaderboardContainer';
import TeamSelection from './TeamSelection/TeamSelectionContainer';
import TriviaScreen from './TriviaScreen/TriviaScreenContainer';
import Tutorial from './Tutorial';
import WinnerOrchestration from './WinnerOrchestration/WinnerOrchestrationContainer';

const logger = Logger.getLogger('Trivia');

const propTypes = {
  lastTrivia: PropTypes.string.isRequired,
  resetTeam: PropTypes.func.isRequired,
  resetTrivia: PropTypes.func.isRequired,
  setLastTrivia: PropTypes.func.isRequired,
  team: PropTypes.string,
  timer: PropTypes.shape().isRequired,
  triviaId: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
};

const defaultProps = {
  team: null,
};
function Trivia({
  lastTrivia,
  resetTeam,
  resetTrivia,
  setLastTrivia,
  team,
  timer,
  triviaId,
  user,
}) {
  logger.trace('render()');
  const [joinedBackend, setJoinedBackend] = useState(false);

  const { data, loading } = useSubscription('trivia');

  useEffect(() => {
    if (!joinedBackend && team) {
      setJoinedBackend(true);
    }
  }, [team, joinedBackend]);
  useEffect(() => {
    if (triviaId !== lastTrivia) {
      setLastTrivia(triviaId);
      setJoinedBackend(false);
      resetTeam();
      resetTrivia();
    }
  }, [triviaId, lastTrivia, resetTeam, resetTrivia, setLastTrivia]);

  if (loading) return <Loading />;

  const { teamMode } = data;
  const currentStep = data && data.step;

  return (
    <FullScreenContainer usePercents>
      {currentStep === 'introduction' && (
        <Introduction user={user} selectedGame="trivia" timer={timer} />
      )}
      {currentStep === 'quiz' && (
        <Transition currentStep={(team) ? 'game' : 'teamSelection'} startShown>
          {() => (
            <>
              {team && (
                <TriviaScreen activityId={triviaId} user={user} />
              )}
              {!team && (
                <TeamSelection
                  activityType="trivia"
                  activityId={triviaId}
                  teamMode={teamMode}
                />
              )}
            </>
          )}
        </Transition>
      )}
      {currentStep === 'teamSelection' && (
        <Transition currentStep={(team) ? 'tutorial' : 'teamSelection'} startShown>
          {() => (
            <>
              {!team && (
                <TeamSelection
                  activityType="trivia"
                  activityId={triviaId}
                  teamMode={teamMode}
                />
              )}
              {team && (
                <Tutorial />
              )}
            </>
          )}
        </Transition>
      )}
      {currentStep === 'winnerOrchestration' && (
        <WinnerOrchestration activityId={triviaId} />
      )}
      {currentStep === 'leaderboard' && (
        <Leaderboard activityId={triviaId} activityType="trivia" unit="pts" />
      )}
      {currentStep === '' && (
        <Loading />
      )}
    </FullScreenContainer>
  );
}

Trivia.defaultProps = defaultProps;
Trivia.propTypes = propTypes;

export default Trivia;
