import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  height: 60%;
  align-items: center;
  flex-direction: column;
  font-size: 7vmin;
  flex-grow: 1;
`;

export const CarouselTitle = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.textColor};
`;

export const CarouselStep = styled(Carousel.Item)`
  text-align: center;
  width: 100%;
  font-size: 0.33em;
  color: ${(props) => props.theme.textColor};
  user-select: none;
`;

export const TutorialCarousel = styled(Carousel)`
  width: 90vw;
  overflow-y: auto;
  user-select: none;

  .carousel-inner {
    user-select: none;
  }
`;
