import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

// import BlockedOrientation from '../../components/BlockedOrientation/BlockedOrientation';
import Logger from '../../lib/Logger';
import {
  ContentContainer,
  FullWidthImage,
  TextContainer,
  TutorialContainer,
  TutorialText,
} from './styles';

const Drums = 'benchmarks/images/rockBand/tutorial/tutorialDrum';
const Guitar = 'benchmarks/images/rockBand/tutorial/tutorialGuitar';
const Bass = 'benchmarks/images/rockBand/tutorial/tutorialBass';
const Synth = 'benchmarks/images/rockBand/tutorial/tutorialSynth';
const Trumpet = 'benchmarks/images/rockBand/tutorial/tutorialTrumpet';
const Micro = 'benchmarks/images/rockBand/tutorial/tutorialMicro';

const instruments = {
  Bassists: Bass,
  Drummers: Drums,
  Guitarists: Guitar,
  SynthPlayers: Synth,
  Trumpeters: Trumpet,
  Vocalists: Micro,
};

const propTypes = {
  inputType: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  theme: PropTypes.shape().isRequired,
};

const logger = Logger.getLogger('Tutorial');

function Tutorial({ inputType, team, theme }) {
  logger.trace('render()');
  return (
    <TutorialContainer team={team} usePercents>
      <TextContainer>
        <TutorialText><FormattedMessage id={`tutorial_text_${inputType}`} /></TutorialText>
      </TextContainer>
      <ContentContainer>
        <FullWidthImage publicId={`${instruments[team || 'Drummers']}${theme.cloudExtension}`} />
      </ContentContainer>
    </TutorialContainer>
  );
}

Tutorial.propTypes = propTypes;

export default withTheme(Tutorial);
