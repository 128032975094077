import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import { ResponsiveIcon, RoundButton } from './styles';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

function CustomBack({ onClick }) {
  return (
    <RoundButton id="roundButton" onClick={onClick}>
      <ResponsiveIcon icon={faArrowLeft} />
    </RoundButton>
  );
}

CustomBack.propTypes = propTypes;

export default CustomBack;
