import { connect } from 'react-redux';

import { setCurrentQuestion, setLastExtension, setTriviaJoined } from '../../reducers/GameReducer/GameActions';
import { setTeam } from '../../reducers/UserReducer/UserActions';
import Trivia from './Trivia';

const mapStateToProps = (state) => ({
  lastTrivia: state.game.trivia.lastId,
  team: state.user.team.trivia,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  resetTeam: () => dispatch(setTeam('', 'trivia')),
  resetTrivia: () => { dispatch(setCurrentQuestion(null)); dispatch(setTriviaJoined(false)); },
  setLastTrivia: (lastTrivia) => dispatch(setLastExtension(lastTrivia, 'trivia')),
});

const TriviaContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Trivia);

export default TriviaContainer;
