export default {
  eventId: '',
  onboarding: {
    onboardingSteps: [{ name: 'story' }, { name: 'username' }, { name: 'seat' }, { name: 'permissions' }],
    pathAfterOnboarding: '/orchestration',
  },
  orchestration: {
    gameName: 'rockBand',
    showBackButton: false,
    showNotification: false,
    showProfileBar: true,
  },
  theme: {
    cloudExtension: '',
    fontFamily: 'Montserrat',
    primaryColor: '#18135e',
    primaryColorLight: '#9047ff',
    secondaryColor: '#2de8b0',
    textColor: 'white',
    themeCode: 'normal',
  },
  themeRougeOr: {
    cloudExtension: '2',
    fontFamily: 'Montserrat',
    primaryColor: '#fa2f4c',
    primaryColorLight: '#f66168',
    secondaryColor: '#ffc903',
    textColor: 'white',
    themeCode: 'rougeOr',
  },
};
