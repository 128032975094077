import styled from 'styled-components';

import { doubleBrightness } from '../../styles/animations';
import { NORMAL_FONT_SIZE } from '../../styles/constants';

export const ButtonContainer = styled.div`
  width: ${(props) => props.width || 'fit-content'};
  padding: 0.3em 0.75em;
  font-size: ${(props) => props.fontSize || NORMAL_FONT_SIZE};
  color: ${(props) => props.theme.textColor};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (!props.disabled ? 1 : 0.5)};
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 9999px;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
  }

  > * {
    user-select: none;
  }

  &:active {
    animation: ${doubleBrightness} 500ms;
  }

  @media (hover: none) {

    &:hover {
      animation: ${doubleBrightness} 500ms;
    }
  }
`;

export const IconContainer = styled.img.attrs((props) => ({
  src: props.icon,
}))`
  position: relative;
  right: 22vmin;
  width: 6vmin;
`;
