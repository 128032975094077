import { connect } from 'react-redux';

import { resetApp } from '../../reducers/AppReducer/AppActions';
import ResetButton from './ResetButton';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  resetApp: () => { dispatch(resetApp()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetButton);
