import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

import { ADD_GEOLOCATION } from '../../lib/apollo/mutations';
import getAccurateCurrentPosition from '../../lib/getAccurateCurrentPosition';
import Logger from '../../lib/Logger';

const logger = Logger.getLogger('GeolocationTracker');

const propTypes = {
  location: PropTypes.shape,
  permissions: PropTypes.shape,
  setGeolocationPermission: PropTypes.func,
  setPosition: PropTypes.func,
  user: PropTypes.shape,
};

const defaultProps = {
  location: {},
  permissions: {},
  setGeolocationPermission: () => { },
  setPosition: () => { },
  user: {},
};

function GeolocationTracker(props) {
  const {
    location,
    permissions,
    setGeolocationPermission,
    setPosition,
    user,
  } = props;
  const [addGeolocation] = useMutation(ADD_GEOLOCATION);

  const sendGeolocation = useCallback((data) => {
    logger.trace('sendGeolocation()');
    if (data && data.coords) {
      const { position } = location;
      if (position) {
        const { lat, lon } = position;
        if (lat !== data.coords.latitude || lon !== data.coords.longitude) {
          addGeolocation({
            variables: {
              geolocation: {
                latitude: data.coords.latitude,
                longitude: data.coords.longitude,
              },
              id: user.id,
            },
          });
          setPosition({ lat: data.coords.latitude, lon: data.coords.longitude });
        }
      } else {
        addGeolocation({
          variables: {
            geolocation: {
              latitude: data.coords.latitude,
              longitude: data.coords.longitude,
            },
            id: user.id,
          },
        });
        setPosition({ lat: data.coords.latitude, lon: data.coords.longitude });
      }
    }
  }, [addGeolocation, location, setPosition, user.id]);

  const setCurrentPosition = useCallback((gpsInterval) => {
    if (user.id && permissions && permissions.geolocation) {
      getAccurateCurrentPosition(
        (data) => sendGeolocation(data),
        (data) => {
          logger.trace('stopGpsListener()', data);
          clearInterval(gpsInterval);
          setGeolocationPermission(false);
        },
        () => { },
        { desiredAccuracy: 1, maxWait: 5000 },
      );
    }
  }, [permissions, sendGeolocation, setGeolocationPermission, user.id]);

  useEffect(() => {
    logger.trace('startGpsListener()');
    setCurrentPosition();

    const gpsInterval = setInterval(() => {
      setCurrentPosition(gpsInterval);
    }, 60000);

    return () => clearInterval(gpsInterval);
  }, [setCurrentPosition]);

  return null;
}

GeolocationTracker.propTypes = propTypes;
GeolocationTracker.defaultProps = defaultProps;

export default GeolocationTracker;
