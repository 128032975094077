import { connect } from 'react-redux';

import TeamSelectionFrame from './TeamSelectionFrame';

const mapStateToProps = (state) => ({
  clustered: state.orchestration.clustered,
  extensionId: state.orchestration.currentExtensionId,
  location: state.location,
  user: state.user,
});

const TeamSelectionFrameContainer = connect(mapStateToProps)(TeamSelectionFrame);

export default TeamSelectionFrameContainer;
