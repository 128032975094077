import { useAction } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { IntroText } from '../styles';

const propTypes = {
  prizeCount: PropTypes.number.isRequired,
};

function Introduction({ prizeCount }) {
  const { id: userId } = useSelector((state) => state.user);
  const { emit: register } = useAction('prize', 'ADD_PLAYER');
  const { emit: unregister } = useAction('prize', 'REMOVE_PLAYER');

  useEffect(() => {
    register({ userId });
    return () => {
      unregister({ userId });
    };
  }, [register, unregister, userId]);

  return (
    <IntroText>
      <FormattedMessage id="prize_intro" values={{ itemCount: prizeCount }} />
    </IntroText>
  );
}

Introduction.propTypes = propTypes;

export default Introduction;
