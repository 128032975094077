import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

export const CardListItemContainer = styled.button`
  background: none;
  border: none;
  margin: 10px;
  height: 20em;
  width: 20em;
  margin-bottom: 10px;
  position: relative;
  opacity: ${(props) => ((props.selectedTeam && !props.selected) ? 0.5 : 1)};

  :focus {
    box-shadow: none;
    outline: 0;
  }
`;

export const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CardBackgroundImage = styled(CloudImage)`
  width: 100%;
  height: 100%;
`;

export const CardTitle = styled.h1`
  font-size: 28px;
  color: white;
`;

export const CardTitleContainer = styled.div``;
