import { connect } from 'react-redux';

import { setTeam } from '../../../reducers/UserReducer/UserActions';
import TeamSelection from './TeamSelection';

const mapStateToProps = (state) => ({
  team: state.user.team.trivia,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setTeam: (team) => dispatch(setTeam(team, 'trivia')),
});

const TeamSelectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamSelection);

export default TeamSelectionContainer;
