import moment from 'moment';
import { connect } from 'react-redux';

import {
  clearTimer,
  restartTimer,
  setTimeElapsed,
  setTimerID,
  startTimer,
} from '../../reducers/TimerReducer/TimerActions';
import CountdownHeader from './CountdownHeader';

const mapStateToProps = (state) => ({
  storedTimer: state.timer,
});

const mapDispatchToProps = (dispatch) => ({
  clearTimer: () => dispatch(clearTimer()),
  restartTimer: () => dispatch(restartTimer(+moment.utc())),
  setTimeElapsed: (timeElapsed) => dispatch(setTimeElapsed(timeElapsed)),
  setTimerID: (id) => dispatch(setTimerID(id)),
  startTimer: (duration) => dispatch(startTimer(+moment.utc(), duration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountdownHeader);
