import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import AppConfigReducer from '../AppConfigReducer/AppConfigReducer';
import ConfigReducer from '../ConfigReducer/ConfigReducer';
import ErrorsReducer from '../ErrorsReducer/ErrorsReducer';
import GameReducer from '../GameReducer/GameReducer';
import LocationReducer from '../LocationReducer/LocationReducer';
import OnboardingReducer from '../OnboardingReducer/OnboardingReducer';
import OrchestrationReducer from '../OrchestrationReducer/OrchestrationReducer';
import TimerReducer from '../TimerReducer/TimerReducer';
import UserReducer from '../UserReducer/UserReducer';

export default function appReducer(history) {
  const AppReducer = combineReducers({
    appConfig: AppConfigReducer,
    config: ConfigReducer,
    errors: ErrorsReducer,
    game: GameReducer,
    location: LocationReducer,
    onboarding: OnboardingReducer,
    orchestration: OrchestrationReducer,
    router: connectRouter(history),
    timer: TimerReducer,
    user: UserReducer,
  });

  return AppReducer;
}
