// List of tutorial step for the orchestration game TEMPORARY
export default {
  bingo: {
    carouselSteps: [
      {
        id: 'Game tutorial 1',
        image: 'benchmarks/images/app/drummer',
        text: 'introduction_text_bingo',
      },
    ],
    carouselTitle: 'introduction_carousel_title_lightShow',
    logo: 'benchmarks/images/app/drummer',
  },
  lightShow: {
    carouselSteps: [
      {
        id: 'Game tutorial 1',
        text: 'introduction_text_lightShow',
      },
    ],
    carouselTitle: 'introduction_carousel_title_lightShow',
    logo: 'benchmarks/images/app/drummer',
  },
  poll: {
    carouselSteps: [
      {
        id: 'poll_text_1',
        text: 'introduction_text_poll',
      },
    ],
    carouselTitle: 'introduction_carousel_title_poll',
    logo: 'benchmarks/images/app/trivia',
  },
  rockband: {
    carouselSteps: [
      {
        id: 'Game tutorial 1',
        image: 'benchmarks/images/app/drummer',
        text: 'introduction_text_1',
      },
    ],
    carouselTitle: 'introduction_carousel_title_rockband',
    logo: 'benchmarks/images/app/drummer',
  },
  trivia: {
    carouselSteps: [],
    carouselTitle: 'introduction_carousel_title_trivia',
    logo: 'benchmarks/images/app/trivia',
  },
  waiting: {
    carouselSteps: [
      {
        id: 'Game tutorial 1',
        image: '223ea0f5f6da4622ca5a7ed73ac50040',
        text: 'introduction_text_1',
      },
    ],
    carouselTitle: 'introduction_carousel_title_waiting',
    logo: '223ea0f5f6da4622ca5a7ed73ac50040',
  },
};
