import styled from 'styled-components';

export const FlashingDisplay = styled.div`
  width: 100%;
  height: 100%;
  animation: Flash 1s linear infinite;
  background-color: ${({ color }) => color};

  @keyframes Flash {
    0% { background-color: ${({ color }) => color}; }
    50% { background-color: #000; }
    100% { background-color: ${({ color }) => color}; }
  }
`;

export const StaleDisplay = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;
