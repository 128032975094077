import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import apolloClient from '../../lib/apollo/client';
import { GET_USER } from '../../lib/apollo/queries';
import EventContext from '../../providers/EventProvider/EventContext';

const propTypes = {
  goBackToOnboarding: PropTypes.function,
  orchestration: PropTypes.shape(),
  resetApp: PropTypes.function,
  setOrchestration: PropTypes.function,
  user: PropTypes.shape(),
};

const defaultProps = {
  goBackToOnboarding: () => { },
  orchestration: {},
  resetApp: () => { },
  setOrchestration: () => { },
  user: { },
};

function UserExistenceChecker(props) {
  const {
    goBackToOnboarding,
    orchestration,
    resetApp,
    setOrchestration,
    user,
  } = props;
  const { eventId } = useContext(EventContext);
  const [currentEventId, setCurrentEventId] = useState(undefined);

  useEffect(() => {
    const checkExistence = async () => {
      if (eventId !== currentEventId && user.id) {
        const result = await apolloClient.query({ query: GET_USER, variables: { id: user.id } });
        if (!result.data.user || result.data.user.event.id !== eventId) {
          setCurrentEventId(eventId);
          resetApp();
          setOrchestration(orchestration);
          goBackToOnboarding();
        }
      }
    };
    checkExistence();
  }, [
    eventId,
    currentEventId,
    goBackToOnboarding,
    user.id,
    resetApp,
    orchestration,
    setOrchestration,
  ]);
  return null;
}

UserExistenceChecker.propTypes = propTypes;
UserExistenceChecker.defaultProps = defaultProps;

export default UserExistenceChecker;
