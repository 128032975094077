export const RESET_USER = 'RESET_USER';
export const SET_ID = 'SET_ID';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_SCORE = 'SET_SCORE';
export const SET_TEAM = 'SET_TEAM';

export function resetUser() {
  return { type: RESET_USER };
}

export function setId(newId) {
  return { id: newId, type: SET_ID };
}

export function setScore(score, game) {
  return { game, score, type: SET_SCORE };
}

export function setUsername(username) {
  return { type: SET_USERNAME, username };
}

export function setTeam(team, game) {
  return { game, team, type: SET_TEAM };
}
