import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  HeaderContainer,
  HeaderRankColumn,
  HeaderRow,
  HeaderText,
  HeaderUsernameColumn,
  ScoreColumn,
} from './styles';

const propTypes = {
  unit: PropTypes.string,
};

const defaultProps = {
  unit: '%',
};

function LeaderboardHeader({ unit }) {
  return (
    <HeaderContainer>
      <HeaderRow>
        <HeaderRankColumn><HeaderText><FormattedMessage id="leaderboard_rank" /></HeaderText></HeaderRankColumn>
        <HeaderUsernameColumn><HeaderText><FormattedMessage id="leaderboard_player" /></HeaderText></HeaderUsernameColumn>
        <ScoreColumn><HeaderText>{unit}</HeaderText></ScoreColumn>
      </HeaderRow>
    </HeaderContainer>
  );
}

LeaderboardHeader.propTypes = propTypes;
LeaderboardHeader.defaultProps = defaultProps;

export default LeaderboardHeader;
