import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_ONBOARDING,
  SET_NEXT_ONBOARDING_STEP,
  SET_ONBOARDING_TO_COMPLETED,
} from './OnboardingActions';

const logger = Logger.getLogger('OnboardingReducer');

function OnboardingReducer(state = initialStore.onboarding, action) {
  switch (action.type) {
    case SET_NEXT_ONBOARDING_STEP:
      logger.trace('SET_NEXT_ONBOARDING_STEP');
      return {
        ...state,
        currentIndex: action.nextIndex,
      };

    case RESET_APP:
      logger.trace('RESET_ONBOARDING');
      return {
        ...initialStore.onboarding,
      };

    case RESET_ONBOARDING:
      logger.trace('RESET_ONBOARDING');
      return {
        ...initialStore.onboarding,
      };

    case SET_ONBOARDING_TO_COMPLETED:
      logger.trace('SET_ONBOARDING_TO_COMPLETED');
      return {
        ...state,
        onboardingCompleted: true,
      };

    default:
      return state;
  }
}

export default OnboardingReducer;
