import PropTypes from 'prop-types';
import { useState } from 'react';

import NavMenu from '../../components/NavMenu/NavMenuContainer';
import ProfileBar from './ProfileBar/ProfileBar';
import {
  HeaderContainer,
} from './styles';
import WaitingScreen from './WaitingScreen/WaitingScreen';

export const ANIMATION_DURATION_MS = 500;
const BUTTON_DURATION_MS = 250;
export const EXIT_DURATION_MS = ANIMATION_DURATION_MS + BUTTON_DURATION_MS;

const propTypes = {
  selectedGame: PropTypes.string,
  user: PropTypes.shape().isRequired,
};

const defaultProps = {
  selectedGame: null,
};

function Introduction(props) {
  const [opening] = useState(true);
  const {
    selectedGame,
    user,
  } = props;

  return (
    <>
      <NavMenu />
      <HeaderContainer>
        <ProfileBar
          user={user}
          extensionName={selectedGame}
        />
      </HeaderContainer>
      <WaitingScreen
        opening={opening}
        selectedGame={selectedGame}
      />
    </>
  );
}

Introduction.defaultProps = defaultProps;
Introduction.propTypes = propTypes;

export default Introduction;
