import { useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Loading from '../../../components/Loading/Loading';
import { FlashingColors } from '../styles';
import RevealResult from './RevealResult';

const propTypes = {
  code: PropTypes.string.isRequired,
  winners: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const flashingColors = [
  'rgb(19, 133, 221)',
  'rgb(0, 190, 120)',
  'rgb(255, 244, 88)',
  'rgb(230, 159, 0)',
];

function Reveal({ winners, code }) {
  const user = useSelector((state) => state.user);
  const [reveal, setReveal] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const { data, loading } = useSubscription('prize');

  useEffect(() => {
    let timeoutHandle;
    if (winners) {
      const winning = winners.some((id) => id === user.id);
      setIsWinner(winning);
      const timeoutDuration = 5000;
      timeoutHandle = setTimeout(() => {
        setReveal(true);
      }, timeoutDuration);
    }
    return () => { clearTimeout(timeoutHandle); };
  }, [data, user.id, winners]);

  if (loading) return <Loading />;

  if (reveal && data?.winners) {
    return (
      <RevealResult
        isWinner={isWinner}
        code={code}
      />
    );
  }

  return (
    <FlashingColors
      colors={flashingColors}
    >
      <FormattedMessage id="prize_revealing" />
    </FlashingColors>
  );
}

Reveal.propTypes = propTypes;

export default Reveal;
