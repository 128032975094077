import { CloudImage } from '@novom/cloudinary-image';
import styled, { css } from 'styled-components';

export const TrackCanvas = styled.canvas`
  position: absolute;
`;

const verticalInstrument = css`
  left: 0;
  bottom: 0;
  max-height: 27%;
`;

const horizontalInstrument = css`
  top: 0;
  right: 0;
  max-width: 12%;
`;

export const Instrument = styled(CloudImage)`
  position: absolute;
  user-select: none;
  ${(props) => (props.direction === 'vertical' ? verticalInstrument : horizontalInstrument)};
`;

export const TrackContainer = styled.div`
  position: relative;
  height: ${({ dimensions }) => dimensions.height}px;
  width: ${({ dimensions }) => dimensions.width}px;
`;
