/* cSpell:language en */

export default {
  // GraphQL Errors
  'GraphQL error: Resource not found': 'Error: Unable to find the server associated to this app',

  // Beacon Your Friends
  beaconYourFriends_generate: 'Find a friend',
  beaconYourFriends_intro: 'Find your friends with a flashing color code on your phone',
  beaconYourFriends_nocode: 'Oh no, there is no more codes available. Please try again later',
  beaconYourFriends_retry: 'Try again',
  beaconYourFriends_share: 'Share',
  beaconYourFriends_shareInfo: 'Send this color code to your friends and put your phone in the air so they can find you',
  beaconYourFriends_start: 'Start',
  beaconYourFriends_stop: 'Stop',

  // Bingo
  bingo_invalid: 'Invalid bingo',
  bingo_tutorial_explication: 'Complete a row, a column or a diagonal',
  bingo_tutorial_title: 'How to play',

  click_to_start: 'Click to Start',
  code: 'Code: {code}',

  // Errors
  error_location: 'Sorry. We cannot establish your location.',
  error_newAttemptIn: 'New attempt in {secondes} sec.',
  error_orientation: 'Please rotate your screen.',
  error_server: 'Sorry. Can not establish a connection with the server.',

  // Explanation
  explanation_message: 'Shake your device at the right time following the music\'s rhythm.',
  explanation_title: 'How to play',

  // Exploration
  exploration_start: 'Play',

  // Inputs
  inputType_shake: 'Shake',
  inputType_shake_unavailable: 'Shake is not available on your device. Check your browser settings to enable the accelerometer.',
  inputType_title: 'Interaction Type',
  inputType_touch: 'Tap',

  // Introduction
  introduction_carousel_title_lightShow: 'Prepare yourself!',
  introduction_carousel_title_poll: 'Voice your opinions!',
  introduction_carousel_title_rockband: 'GET READY!',
  introduction_carousel_title_trivia: 'Did you know?',
  introduction_carousel_title_waiting: 'GET READY!',
  introduction_text_1: 'You will be playing in no time, please sit tight.',
  introduction_text_2: 'You can either play as a guitarist or drummer, your phone will be your instrument.',
  introduction_text_3: 'Look at the screen on stage and follow the rhythm as closely as you can to score points!',
  introduction_text_bingo: 'Soon you will have to pay attention to your television program. Your phone will be your Bingo card.',
  introduction_text_lightShow: 'Soon you will have to raise your phone and hold it screen facing forwards!',
  introduction_text_poll: 'In a few moments, you will take part in a poll alongside many other users.',

  // Languages
  language_en: 'English',
  language_fr: 'Français',
  language_selection: 'Select your language',

  // Leaderbord
  leaderboard_all_players: 'All players',
  leaderboard_player: 'Player',
  leaderboard_rank: '#',
  leaderboard_score: '%',
  leaderboard_team: 'My team',
  leaderboard_title: 'Leaderboard',

  // LightShow
  lightShow_geolocation: 'We are trying to find you!',
  lightShow_geolocation_subtext: 'Thank you for waiting',
  lightShow_intro_text: 'The effects will start soon. Be ready to raise your phone!',

  // Location
  location_stand_message: 'Find your place in the crowd.',
  location_stay_message: 'Please keep still.',
  location_user_ready: 'I\'m ready',
  location_waiting: 'Please wait while we locate you.',

  look_up: 'Look at the giant screen',

  minute: 'test',
  minutes: 'minutes',

  // MultiplayerWaiting
  multiplayerWaiting_subtitle: 'The game is about to begin',
  multiplayerWaiting_title: 'Ready up!',

  // Music Waiting
  music_waiting_sub: 'We are compiling your results.',
  music_waiting_text: 'Thank you for participating!',
  music_warmup_title: 'Warmup',

  // Navigation
  navigation_cancel: 'Cancel',
  navigation_choose: 'Choose',
  navigation_close: 'Close',
  navigation_continue: 'Continue',
  navigation_skip: 'Skip',

  // Notification
  notification_go_to_game: 'Go to game',

  ok: 'OK',

  // Onboarding Seat
  onboarding_seat_invalid_error: 'The seat number entered is invalid. Please check again.',
  onboarding_seat_pixel_error: 'There seems to be an issue with the seat number you entered... Please check again.',
  onboarding_seat_text: 'Enter your seat number',

  // Orchestration
  orchestration_error: 'Oops! Something went wrong.',
  orchestration_notification: 'Receive notification',
  orchestration_time_title: 'Game starts in',

  // Page Not found
  page_not_found: 'Oops! Page not found.',
  page_not_found_go_back: 'Back to the website.',

  // Permissions
  permission_error_location_instruction: 'Since geolocation is disabled on your device, you will need to manually choose one from the list below.',
  permission_error_location_placeholder: 'Choose...',
  permission_error_location_required: 'Geolocation is required for this part of the experience',
  permission_error_location_submit: 'Submit',
  permission_geolocation_text: 'The geolocation makes the experience more precise.',
  permission_geolocation_title: 'Geolocation',
  permission_microphone_text: 'The microphone can be used during the experience.',
  permissions_deny_geoloc: 'Deny',
  permissions_enable_geoloc: 'Authorize',
  permissions_enable_media: 'Authorize',
  permissions_error_geoloc: 'Please either authorize or deny geolocation.',
  permissions_title: 'The app requires access to',
  permissions_warn_geoloc: 'By denying, some parts of the experience will not be accessible. Verify your browser settings if you are unable to authorize.',

  // Prize
  prize_better_luck: 'Better luck next time.',
  prize_id: 'code: {id}',
  prize_intro: '{itemCount, plural, one {The winner will be revealed shortly.} other {The winners will be revealed shortly.}}',
  prize_no_prize: 'You haven\'t won any prize yet.',
  prize_not_winner: 'Thank you for participating',
  prize_revealing: 'Are you the winner?',
  prize_winner: ', you won!',
  prize_winner_name: 'Congratulations {name}',
  prizes_won: 'Prizes won',

  // Profile page
  profile_language: 'Language',
  profile_team: 'My team',
  profile_termsAndConditions: 'Terms & conditions',
  profile_title: 'Player\'s profile',
  profile_username: 'My name',

  pts: 'pts',

  result_good: 'Too good!',
  result_poor: 'Thank you!',

  // Reward Page
  reward_congrats_collect: 'Collect',
  reward_congrats_subtitle: 'You\'ve won',
  reward_congrats_title: 'Congratulations!',

  // Server errors
  server_error_unreachable: 'Error: Server unreachable, please try again later',

  // Server offset
  server_offset_loading: 'Synchronizing...',

  // SocialShare
  social_share_subtitle: 'To know when the video will be available!',
  social_share_title: 'Follow us on social media',

  // Story page
  story_desc_1: 'You will be able to play different mini-games during the match.',
  story_desc_2: 'Take part in the experience and help your team achieve victory.',
  story_subtitle_1: 'to win a prize',
  story_subtitle_2: '& following the instructions',
  story_title_1: 'Play for a chance',
  story_title_2: 'Using your phone',

  // Team
  team_selection_confirm: 'Confirm',
  team_selection_difficulty_easy: 'Easy',
  team_selection_difficulty_hard: 'Hard',
  team_selection_difficulty_medium: 'Medium',
  team_selection_found: 'You joined team: {name}!',
  team_selection_team_bassists: 'Bassists',
  team_selection_team_drummers: 'Drummers',
  team_selection_team_guitarists: 'Guitarists',
  team_selection_team_synthPlayers: 'Keyboardists',
  team_selection_team_trumpeters: 'Trumpeters',
  team_selection_team_vocalists: 'Vocalists',
  team_selection_text: 'The game will soon begin.',
  team_selection_title: 'Choose your team!',
  team_selection_title_geoloc: 'A team will be given to you!',

  // Terms And Conditions
  terms_privacy_access_text: "Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant. L'exercice de ce droit se fera: Code postal: 155, rue Principale Ouest, Suite 100, Magog J1X2A7, Courriel: support@novominteractive.com, Téléphone: (819) 769-0741, Section du site web: www.skyshow.app/",
  terms_privacy_access_title: "Droit d'accès",
  terms_privacy_cookie_text: "Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers témoins (cookies). Il s'agit principalement des informations suivantes: Adresse IP, Système d'exploitation, Pages visitées et requêtes, Heure et jour de connexion. Le recours à de tels fichiers nous permet: Amélioration du service et accueil personnalisé, Statistique.",
  terms_privacy_cookie_title: 'Fichiers journaux et témoins',
  terms_privacy_form_text: "Vos renseignements personnels sont collectés par le biais de formulaire, à savoir: Jeu. Nous utilisons les renseignements ainsi collectés pour les finalités suivantes: Informations / Offres promotionnelles. Vos renseignements sont également collectés par le biais de l'interactivité pouvant s'établir entre vous et notre site Web et ce, de la façon suivante: Nous utilisons les renseignements ainsi collectés pour les finalités suivantes: Forum ou aire de discussion, Commentaires.",
  terms_privacy_form_title: 'Formulaires  et interactivité',
  terms_privacy_intro_text: "Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée. C'est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.",
  terms_privacy_intro_title: 'Introduction',
  terms_privacy_opposition_text: "Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels. Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte. Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion. Pour pouvoir exercer ces droits, vous pouvez: Code postal: 155, rue Principale Ouest, Suite 100, Magog J1X2A7, Courriel: support@novominteractive.com, Téléphone: (819) 769-0741, Section du site web: www.skyshow.app/",
  terms_privacy_opposition_title: "Droit d'opposition et de retrait",
  terms_privacy_privacy_text: "Nous collectons les renseignements suivants: Prénom, Préférences (musicales, littéraires, cinématographiques, etc.) Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l'interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations vous concernant.",
  terms_privacy_privacy_title: 'Collecte des renseignements personnels',
  terms_privacy_security_text: "Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations. Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes: Protocole SSL (Secure Sockets Layer), Gestion des accès - personne concernée, Sauvegarde informatique. Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours présente lorsque l'on utilise Internet pour transmettre des renseignements personnels.",
  terms_privacy_security_title: 'Sécurité',
  terms_privacy_title: 'POLITIQUE MODÈLE DE CONFIDENTIALITÉ',

  // Trivia
  trivia_correct_answer: 'Correct',
  trivia_expand: 'Detailed view',
  trivia_minimize: 'Simplified view',
  trivia_out_of: 'of',
  trivia_time: 'You answered in {time} sec',
  trivia_waiting: 'Please wait for the next question.',
  trivia_wrong_answer: 'Wrong',

  // Tutorial
  tutorial_text_shake: 'When the notes hit the green zone on stage, SHAKE your phone.',
  tutorial_text_touch: 'When the notes hit the green zone on stage, PRESS your screen.',
  tutorial_title: 'Tutorial',
  tutorial_trivia: 'Answer the questions as fast as you possibly can.',

  user_feedback_send: 'Send',
  userFeedback_leave_comment: 'Please let us know what are your thoughts on the experience!',
  userFeedback_thanks: 'Thank you for participating!\nEnjoy the show.',

  // Username page
  username_alreadyTaken: 'This username is already taken',
  username_censored: 'Your username has been censored because it had inappropriate language.',
  username_input: 'Enter a username',
  username_question: 'What is \n     your name?',

  waiting_for_score: 'Your score is being calculated...',
  your_result_is: 'Your result is',

};
