import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PRIZE = gql`
  query getPrize($id: String!) {
    getPrize(id: $id) {
      id
      name
      quantity
      basedOnScore
      code
      activityId
    }
  }
`;
