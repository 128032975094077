import { useSubscription } from '@novom/react-anyware-intermedia-client';

import Carousel from './Carousel';
import {
  CarouselStep,
} from './styles';

function TriviaCarousel() {
  const { data } = useSubscription('trivia');
  return (
    <Carousel
      interval={15000}
      title="introduction_carousel_title_trivia"
      steps={data?.facts?.map((fact) => (
        <CarouselStep key={fact}>
          {fact}
        </CarouselStep>
      ))}
    />
  );
}

export default TriviaCarousel;
