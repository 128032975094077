import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation($eventId: String!, $username: String!) {
    createUser(eventId: $eventId, username: $username) {
      id
    }
  }
`;

export const JOIN_TEAM = gql`
  mutation($activityId: String!, $team: String!, $userId: String!){
    joinTeam(activityId: $activityId, team: $team, userId: $userId)
  }
`;

export const UPDATE_SCORE = gql`
  mutation($userId: String!, $activityId: String!, $score: Float!) {
    updateScore(userId: $userId, activityId: $activityId, score: $score){
      id
    }
  }
`;

export const ADD_GEOLOCATION = gql`
  mutation($id: String!, $geolocation: GeolocationInput!) {
    addGeolocation(
      id: $id
      geolocation: $geolocation
    ){
      id
    }
  }
`;

export const LATE_JOIN_GEOLOC_TEAM = gql`
  mutation($extensionId: String!, $geolocation: GeolocationInput!, $userId: String!) {
    lateJoinGeolocTeam(extensionId:$extensionId, geolocation:$geolocation, userId:$userId)
  }
`;

export const VALIDATE_BINGO_CARD = gql`
  mutation($extensionId: String!, $stampedWords: [StampedWord]!, $userId: String!) {
    validateBingoCard(extensionId: $extensionId, stampedWords: $stampedWords, userId: $userId) {
      isValid
      score
    }
  }
`;
