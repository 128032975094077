import packageJson from '../../package.json';
import appConfig from '../appConfigs';
import getBrowserLanguage from './getBrowserLanguage';
import { supportedLanguages } from './i18n/i18n';

const browserLanguage = getBrowserLanguage();
const languageIsSupported = supportedLanguages.includes(browserLanguage);

export default {
  appConfig,
  config: {
    language: languageIsSupported ? browserLanguage : 'en',
    permissions: {
      geolocation: false,
      mediaCapture: false,
    },
    version: packageJson.version,
  },
  errors: {},
  game: {
    bingo: {
      lastId: '',
      stampedWords: [],
    },
    music: {
      lastId: '',
    },
    poll: {
      currentQuestion: null,
    },
    trivia: {
      currentQuestion: null,
      joined: false,
      lastId: '',
    },
  },
  location: {
    position: undefined,
    seat: '',
  },
  onboarding: {
    currentIndex: 0,
    onboardingCompleted: false,
  },
  orchestration: {},
  timer: {
    duration: 0,
    id: null,
    timeElapsed: 0,
    timeStarted: null,
  },
  user: {
    id: null,
    score: {
      bingo: 0,
      music: 0,
      trivia: 0,
    },
    team: {
      bingo: '',
      music: '',
      trivia: '',
    },
    username: '',
  },
  visibility: {
    visible: true,
  },
};
