import { ApolloProvider } from '@apollo/client';
import { CloudinaryProvider } from '@novom/cloudinary-image';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import AppIntlProvider from '#components/AppIntlProvider/AppIntlProvider';
import AppThemeProvider from '#components/AppThemeProvider/AppThemeProvider';
import IntermediaProviderManager from '#components/IntermediaProviderManager';
import OrchestrationSubscriber from '#components/OrchestrationSubscriber/OrchestrationSubscriber';
import ResetButton from '#components/ResetButton/ResetButtonContainer';
import UserExistenceChecker from '#components/UserExistenceChecker/UserExistenceContainer';
import apolloClient from '#lib/apollo/client';
import configureStore, { history } from '#lib/configureStore';
import env from '#lib/environment';
import { GoogleAnalyticsProvider } from '#lib/GoogleAnalytics';
import settings from '#lib/settings';
import GeolocationTracker from '#modules/GeolocationTracker/GeolocationTrackerContainer';

import EventProvider from '../providers/EventProvider/EventProvider';
import Routes from '../routes/Routes';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <AppIntlProvider>
          <AppThemeProvider>
            <CloudinaryProvider
              cloudName={settings.cloudinary.cloudName}
              projectName={settings.cloudinary.folder}
            >
              <GoogleAnalyticsProvider>
                <EventProvider>
                  <IntermediaProviderManager>
                    <GeolocationTracker />
                    <OrchestrationSubscriber>
                      <UserExistenceChecker />
                      <ConnectedRouter history={history}>
                        {Routes}
                      </ConnectedRouter>
                    </OrchestrationSubscriber>
                  </IntermediaProviderManager>
                </EventProvider>
              </GoogleAnalyticsProvider>
            </CloudinaryProvider>
          </AppThemeProvider>
        </AppIntlProvider>
      </ApolloProvider>
      {settings.env === env.DEV && <ResetButton />}
    </Provider>
  );
}

export default App;
