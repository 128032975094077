import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { GREY_LIGHT, WHITE } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.div`
  font-size: 7vmin;
  color: ${WHITE};
`;

export const Subtext = styled.div`
  font-size: 5vmin;
  color: ${GREY_LIGHT};
`;

export const StyledSpinner = styled(Spinner)`
  width: 15vmin;
  height: 15vmin;
  border-width: 0.3em;
  margin: 10vmin;
`;
