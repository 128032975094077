import styled, { keyframes } from 'styled-components';

import { LILAC } from '../../../styles/colors';

const bkgDrums = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgDrum.jpg';
const bkgGuitar = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgGuitar.jpg';
const bkgSynth = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgSynth.jpg';
const bkgMicro = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgMicro.jpg';
const bkgBass = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgBass.jpg';
const bkgTrumpet = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgTrumpet.jpg';

const bkgDrums2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgDrum2.jpg';
const bkgGuitar2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/skyshow/benchmarks/images/orchestration/bkgGuitar2.jpg';
const bkgSynth2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgSynth2.jpg';
const bkgMicro2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgMicro2.jpg';
const bkgBass2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgBass2.jpg';
const bkgTrumpet2 = 'https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1566313063/skyshow/benchmarks/images/orchestration/bkgTrumpet2.jpg';

const bkgImage = {
  bassists: bkgBass,
  drummers: bkgDrums,
  guitarists: bkgGuitar,
  synthPlayers: bkgSynth,
  trumpeters: bkgTrumpet,
  vocalists: bkgMicro,
};

const bkgImage2 = {
  bassists: bkgBass2,
  drummers: bkgDrums2,
  guitarists: bkgGuitar2,
  synthPlayers: bkgSynth2,
  trumpeters: bkgTrumpet2,
  vocalists: bkgMicro2,
};

export const GameContainer = styled.div`
  background-image: url(${(props) => (props.team && props.theme.themeCode === 'normal' ? bkgImage[props.team] : bkgImage2[props.team])});
  background-size: cover;
  touch-action: none;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: fixed;
  overflow: hidden;
`;

export const Touchable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const pulseAnimation = keyframes`

  0% {
    box-shadow: 0 0 0 0 rgba(144, 71, 255, 0.8);
    transform: rotate3d(13, 0, 0, 245deg);
  }

  50% {
    transform: rotate3d(13, 0, 0, 245deg);
  }

  100% {
    box-shadow: 0 0 0 200px rgba(144, 71, 255, 0);
    transform: rotate3d(13, 0, 0, 245deg);
  }
`;

export const Pulse = styled.span`
  position: relative;
  z-index: -1;
  margin-top: -25px;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: ${(props) => (props.colorCenter ? '#9047ff' : `${LILAC}`)};
  transform: rotate3d(13, 0, 0, 245deg);
  animation: ${pulseAnimation} ${(props) => props.pulseInterval}ms infinite;
`;
