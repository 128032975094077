export const RESET_CONFIG = 'RESET_CONFIG';
export const SET_GEOLOCATION_PERMISSION = 'SET_GEOLOCATION_PERMISSION';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_VERSION = 'SET_VERSION';
export const SET_IS_INTEGRATED = 'SET_IS_INTEGRATED';

export function resetConfig() {
  return { type: RESET_CONFIG };
}

export function setLanguage(language) {
  return { language, type: SET_LANGUAGE };
}

export function setPermissions(permissions) {
  return { permissions, type: SET_PERMISSIONS };
}

export function setGeolocationPermission(permission) {
  return { permission, type: SET_GEOLOCATION_PERMISSION };
}

export function setVersion(version) {
  return { type: SET_VERSION, version };
}

export function setIsIntegrated(isIntegrated) {
  return { isIntegrated, type: SET_IS_INTEGRATED };
}
