/* eslint-disable no-console */

import moment from 'moment';

import settings from './settings';

export const logLevels = {
  DEBUG: { name: 'DEBUG', order: 4 },
  ERROR: { name: 'ERROR', order: 1 },
  INFO: { name: 'INFO', order: 3 },
  OFF: { name: 'OFF', order: 0 },
  TRACE: { name: 'TRACE', order: 5 },
  WARNING: { name: 'WARNING', order: 2 },
};

/**
 * Allows to log information to the debug console.
 */
class Logger {
  /**
   * Instanciate a new Logger.
   * @param {string} alias - A name for the logger, typically the name of the current module.
   * @param {object} logLevel - A value from the `logLevels` enumeration, representing the
   *                            desired log level.
   */
  constructor(alias, logLevel) {
    this.alias = alias;
    this.logLevel = logLevel || logLevels.INFO;
  }

  /**
   * @private
   */
  log(level, handler, ...args) {
    if (this.logLevel.order >= level.order) {
      handler(`[${moment().format('YYYY-MM-DDTHH:mm:ss:SSS')}] [${level.name}] ${this.alias} -`, ...args);
    }
  }

  /**
   * Log an error message.
   * @param message - The data to log.
   */
  error(...args) {
    this.log(logLevels.ERROR, console.error, ...args);
  }

  /**
   * Log a warning message.
   * @param {*} args
   */
  warn(...args) {
    this.log(logLevels.WARNING, console.warn, ...args);
  }

  /**
   * Log an information message.
   * @param message - The data to log.
   */
  info(...args) {
    this.log(logLevels.INFO, console.info, ...args);
  }

  /**
   * Log a debug message.
   * @param message - The data to log.
   */
  debug(...args) {
    this.log(logLevels.DEBUG, console.log, ...args);
  }

  /**
   * Log a trace message.
   * @param message - The data to log.
   */
  trace(...args) {
    this.log(logLevels.TRACE, console.log, ...args);
  }
}

const defaultLogLevel = logLevels[settings.logLevel];

export default {
  /**
   * Get a logger for the current module.
   * @param {string} alias - The name to give to the logger, typically the name
   *                         of the current module.
   * @param {string} [logLevel] - The log level to use (overrides default).
   */
  getLogger(alias, logLevel = defaultLogLevel) {
    return new Logger(alias, logLevel);
  },
};
