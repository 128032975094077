import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import { GET_ACTIVITY_LEADERBOARD, GET_ACTIVITY_TEAM_LEADERBOARD } from '../../lib/apollo/queries';
import LeaderboardTab from './LeaderboardTab';
import {
  BackgroundImage,
  LeaderboardTabContainer,
  PageContainer,
  Pts,
  Tab,
  Tabs,
  UserContainer,
  Username,
  UserPoints,
  UserPointsContainer,
  UserResults,
} from './styles';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  showTeamTab: PropTypes.bool,
  unit: PropTypes.string,
};

const defaultProps = {
  showTeamTab: true,
  unit: '%',
};

function Leaderboard({
  activityId,
  showTeamTab,
  unit,
  activityType,
}) {
  const [activeTab, setActiveTab] = useState('all');
  const {
    username,
    score: { [activityType]: activityScore },
    team: { [activityType]: activityTeam },
  } = useSelector((state) => state.user);

  const { data, loading, error } = useQuery(
    GET_ACTIVITY_LEADERBOARD,
    {
      fetchPolicy: 'network-only',
      variables: { activityId },
    },
  );

  const {
    data: teamLeaderboardData,
    loading: teamLeaderboardLoading,
    error: teamLeaderboardError,
  } = useQuery(
    GET_ACTIVITY_TEAM_LEADERBOARD,
    {
      fetchPolicy: 'network-only',
      variables: { activityId, team: activityTeam },
    },
  );

  if (loading || error || teamLeaderboardLoading || teamLeaderboardError) return <Loading />;
  return (
    <PageContainer>
      <BackgroundImage />
      <UserContainer>
        <UserResults>
          <UserPointsContainer>
            <UserPoints>{Math.round(activityScore) || 0}</UserPoints>
            <Pts>{unit}</Pts>
          </UserPointsContainer>
        </UserResults>
        <Username>{username}</Username>
      </UserContainer>
      <LeaderboardTabContainer>
        <Tabs>
          <Tab active={activeTab === 'all'} onClick={() => setActiveTab('all')}>
            <FormattedMessage id="leaderboard_all_players" />
          </Tab>
          {showTeamTab
            && (
              <Tab active={activeTab === 'team'} onClick={() => setActiveTab('team')}>
                <FormattedMessage id="leaderboard_team" />
              </Tab>
            )}
        </Tabs>
        {activeTab === 'all' && <LeaderboardTab data={data.getActivityLeaderboard.users || []} unit={unit} />}
        {activeTab === 'team' && <LeaderboardTab data={teamLeaderboardData.getActivityTeamLeaderboard.users || []} unit={unit} />}
      </LeaderboardTabContainer>
    </PageContainer>
  );
}

Leaderboard.propTypes = propTypes;
Leaderboard.defaultProps = defaultProps;

export default Leaderboard;
