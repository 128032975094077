import { connect } from 'react-redux';

import { setLanguage } from '../../../reducers/ConfigReducer/ConfigActions';
import Language from './Language';

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => { dispatch(setLanguage(language)); },
});

const LanguageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Language);

export default LanguageContainer;
