import styled from 'styled-components';

const StepContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (!props.hidden ? 'flex' : 'none')};
  animation: ${(props) => props.animation} ${(props) => props.animationDurationMs}ms forwards;
`;

export default StepContainer;
