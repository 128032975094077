import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import MessageScreen from '../MessageScreen/MessageScreen';
import HandleOrientation from './styles';

const propTypes = {
  orientation: PropTypes.oneOf(['landscape', 'portrait']).isRequired,
};

function BlockedOrientation(props) {
  const { orientation } = props;

  const isIntegrated = useSelector((state) => state.config.isIntegrated);

  if (isIntegrated) return null;
  return (
    <HandleOrientation orientation={orientation}>
      <MessageScreen messageLabelKey="error_orientation" />
    </HandleOrientation>
  );
}

BlockedOrientation.propTypes = propTypes;

export default BlockedOrientation;
