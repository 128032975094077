import PropTypes from 'prop-types';
import React from 'react';

import {
  BackgroundImageContainer,
  CardBackgroundImage,
  CardListItemContainer,
  CardTitle,
  ContentContainer,
} from './styles';

const defaultProps = {
  background: null,
  disabled: false,
  selected: false,
  selectedTeam: '',
  subTitle: 'subtitle',
  title: 'title',
};

const propTypes = {
  background: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  selectedTeam: PropTypes.string,
  subTitle: PropTypes.node,
  title: PropTypes.node,
};

function CardListItem(props) {
  const {
    background,
    disabled,
    onClick,
    title,
    selected,
    selectedTeam,
    subTitle,
  } = props;

  return (
    <CardListItemContainer
      onClick={onClick}
      selected={selected}
      selectedTeam={selectedTeam}
      disabled={disabled}
    >
      <BackgroundImageContainer>
        <CardBackgroundImage publicId={background} />
      </BackgroundImageContainer>
      <ContentContainer>
        <CardTitle>
          {title}
        </CardTitle>
        <CardTitle>
          {subTitle}
        </CardTitle>
      </ContentContainer>
    </CardListItemContainer>
  );
}

CardListItem.propTypes = propTypes;
CardListItem.defaultProps = defaultProps;

export default CardListItem;
