import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { setSeat } from '../../../reducers/LocationReducer/LocationActions';
import Seat from './Seat';

const mapStateToProps = (state) => ({
  location: state.location,
});

const mapDispatchToProps = (dispatch) => ({
  setSeat: (seat) => dispatch(setSeat(seat)),
});

const SeatContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTheme,
)(Seat);

export default SeatContainer;
