import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import Logger from '../../lib/Logger';
import {
  FullWidthImage,
  TutorialContainer,
  TutorialText,
  TutorialTitle,
} from './styles';

const tutorialImage = 'benchmarks/images/tutorial/story3';
const logger = Logger.getLogger('Tutorial');

function Tutorial({ theme }) {
  logger.trace('render()');
  return (
    <TutorialContainer usePercents>
      <FullWidthImage publicId={`${tutorialImage}${theme.cloudExtension}`} />
      <TutorialTitle>
        <FormattedMessage id="team_selection_text" />
      </TutorialTitle>
      <TutorialText>
        <FormattedMessage id="tutorial_trivia" />
      </TutorialText>
    </TutorialContainer>
  );
}

Tutorial.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Tutorial);
