import { createRef } from 'react';
import { css, keyframes } from 'styled-components';

import { EffectFrame } from './styles';

function getKeyframe(color, oldColor) {
  return keyframes`
    0% { background-color: ${oldColor}; }
    20% { background-color: ${color}; }
    100% { background-color: ${color}; }
  `;
}

/**
 *
 * @param {Array<Array<{ color, duration }>>} schedule
 */
export function scheduleToCSS(schedule, initialDelay = 0) {
  let delay = initialDelay;
  let oldColor = '#000000';

  return css`
    animation:
      ${
  schedule.reduce((acc, effect) => ([
    ...acc,
    ...effect.reduce((steps, step) => {
      const { color, duration } = step;
      if (oldColor !== color && delay >= 0) {
        if (acc.length === 0 && steps.length === 0) {
          steps.push(css`${getKeyframe(color, oldColor)} ${duration}ms linear ${delay}ms forwards`);
        } else {
          steps.push(css`, ${getKeyframe(color, oldColor)} ${duration}ms linear ${delay}ms forwards`);
        }
        oldColor = color;
      }
      delay += duration;

      return steps;
    }, []),
  ]), [])
};
  `;
}

/**
 *
 * @param {Array<Array<{ color, duration }>>} schedule
 */
export function scheduleToDivArray(schedule, setRefs, initialDelay = 0) {
  let delay = initialDelay;
  let oldColor = '#000000';
  const divArray = [];
  const refArray = [];

  schedule.forEach((effect, effectIndex) => {
    effect.forEach((step, stepIndex) => {
      const { color, duration } = step;
      if (oldColor !== color && delay >= 0) {
        const ref = createRef();
        refArray.push(ref);
        divArray.push(
          <EffectFrame
            ref={ref}
            key={`frame-${effectIndex}-${stepIndex}`} // eslint-disable-line react/no-array-index-key
            color={color}
            duration={duration}
            delay={delay}
            style={{ animation: 'none' }}
          />,
        );
        oldColor = color;
      }
      delay += duration;
    });
  });

  setRefs(refArray);
  return divArray;
}
