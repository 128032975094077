/* eslint-disable sort-keys */
const midiSettings = {
  instruments: {
    drummers: {
      noteName: 'C2',
      targetNumber: 0,
    },
    guitarists: {
      noteName: 'C4',
      targetNumber: 1,
    },
    bassists: {
      noteName: 'F2',
      targetNumber: 2,
    },
    synthplayers: {
      noteName: 'C3',
      targetNumber: 3,
    },
    trumpeters: {
      noteName: 'D2',
      targetNumber: 4,
    },
    vocalists: {
      noteName: 'G2',
      targetNumber: 5,
    },
  },
  specialTrack: 6,
};

export default midiSettings;
