import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import settings from '../settings';
import GoogleAnalyticsContext from './GoogleAnalyticsContext';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const firebaseApp = initializeApp(settings.firebase);

function GoogleAnalyticsProvider({ children }) {
  const analytics = useRef({
    logEvent: (event, params = {}) => {
      logEvent(getAnalytics(firebaseApp), event, params);
    },
  }).current;

  return (
    <GoogleAnalyticsContext.Provider value={analytics}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}

GoogleAnalyticsProvider.propTypes = propTypes;

export default GoogleAnalyticsProvider;
