import development from '../settings/development';
import production from '../settings/production';
import staging from '../settings/staging';
import testing from '../settings/testing';
import environment from './environment';

const env = process.env.REACT_APP_ENV || environment.DEV;

const settings = {
  development,
  production,
  staging,
  testing,
}[env];

export default settings;
