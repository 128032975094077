import { createElement, forwardRef, useContext } from 'react';

import EventContext from './EventContext';

function withEventId(WrappedComponent) {
  return forwardRef((props, ref) => {
    const event = useContext(EventContext);
    return createElement(WrappedComponent, {
      ...props,
      event,
      ref,
    });
  });
}

export default withEventId;
