import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  SET_CURRENT_QUESTION,
  SET_INPUT_TYPE,
  SET_JOINED_TRIVIA,
  SET_LAST_EXTENSION,
  SET_POLL_QUESTION,
  SET_STAMPED_WORDS,
} from './GameActions';

const logger = Logger.getLogger('GameReducer');

function GameReducer(state = initialStore.game, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_GAME');
      return {
        ...initialStore.game,
      };

    case SET_CURRENT_QUESTION:
      logger.trace('SET_CURRENT_QUESTION', action);
      return {
        ...state,
        trivia: {
          ...state.trivia,
          currentQuestion: action.id,
        },
      };

    case SET_POLL_QUESTION:
      logger.trace('SET_POLL_QUESTION', action);
      return {
        ...state,
        poll: {
          ...state.poll,
          currentQuestion: action.id,
        },
      };

    case SET_INPUT_TYPE:
      logger.trace('SET_INPUT_TYPE', action);
      return {
        ...state,
        music: {
          ...state.music,
          inputType: action.inputType,
        },
      };

    case SET_JOINED_TRIVIA:
      logger.trace('SET_JOINED_TRIVIA', action);
      return {
        ...state,
        trivia: {
          ...state.trivia,
          joined: !!action.joined,
        },
      };

    case SET_LAST_EXTENSION:
      logger.trace('SET_LAST_EXTENSION', action.id);
      return {
        ...state,
        [action.extensionType]: {
          ...state[action.extensionType],
          lastId: action.id,
        },
      };

    case SET_STAMPED_WORDS:
      logger.trace('SET_STAMPED_WORDS', action);
      return {
        ...state,
        bingo: {
          ...state.bingo,
          stampedWords: action.stampedWords,
        },
      };

    default:
      return state;
  }
}

export default GameReducer;
