import { FormattedMessage } from 'react-intl';

import {
  Container,
  StyledSpinner,
  Subtext,
  Text,
} from './styles';

function GeopositionWait() {
  return (
    <Container>
      <Text>
        <FormattedMessage id="lightShow_geolocation" />
      </Text>
      <Subtext>
        <FormattedMessage id="lightShow_geolocation_subtext" />
      </Subtext>
      <StyledSpinner animation="border" variant="light" />
    </Container>
  );
}

export default GeopositionWait;
