import styled from 'styled-components';

const SecretButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15vmin;
  height: 15vmin;
  z-index: 9999;
`;

export default SecretButton;
