import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  BigTitle,
  Content,
  EntryTitle,
  Item,
  Text,
  Title,
} from './styles';

const propTypes = {
  data: PropTypes.shape().isRequired,
};

function Term({ data }) {
  return (
    <div key={data.title}>
      <BigTitle>
        <FormattedMessage id={data.title} />
      </BigTitle>
      <Content>
        {data.entries.map((entry, index) => (
          <Item eventKey={index}>
            <Title
              id={index}
              eventKey={index}
            >
              <EntryTitle><FormattedMessage id={entry.title} /></EntryTitle>
            </Title>
            <Text eventKey={index}>
              <FormattedMessage id={entry.text} />
            </Text>
          </Item>
        ))}
      </Content>
    </div>
  );
}

Term.propTypes = propTypes;

export default Term;
