export default [
  {
    labelKey: 'language_en',
    optionValue: 'en',
  },
  {
    labelKey: 'language_fr',
    optionValue: 'fr',
  },
];
