import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

import {
  BASS,
  DRUMS,
  GUITAR,
  MICRO,
  SYNTH,
  TRUMPET,
} from '../../styles/colors';
import { FullScreenContainer, Text } from '../../styles/styledComponents';

const teamColors = {
  Bassists: BASS,
  Drummers: DRUMS,
  Guitarists: GUITAR,
  SynthPlayers: SYNTH,
  Trumpeters: TRUMPET,
  Vocalists: MICRO,
};

export const TimerContainer = styled.div`
  width: 100%;
  padding-left: 10vw;
  padding-right: 10vw;
`;

export const TutorialContainer = styled(FullScreenContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: ${(props) => teamColors[props.team] || 'transparent'};
`;

export const FullWidthImage = styled(CloudImage)`
  margin-top: auto;
  width: 50%;
  height: auto;
  max-height: 70%;
`;

export const TutorialText = styled(Text)`
  flex: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: 5em;
  margin-top: 2em;
`;

export const WarmUpTitle = styled(Text)`
  position: absolute;
  top: 5vh;
  color: whitesmoke;
`;

export const ContentContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 75%;
`;

export default TimerContainer;
