import PropTypes from 'prop-types';

import {
  AnswerButton,
  AnswerContent,
  AnswerIcon,
  AnswerText,
  AnswerTextDiv,
} from '../styles';

const propTypes = {
  answer: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  isSelectedAnswer: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  showDetails: PropTypes.bool.isRequired,
};

const defaultProps = {
  isSelectedAnswer: null,
};

function Answer({
  answer,
  index,
  isSelectedAnswer,
  onSelect,
  showDetails,
}) {
  return (
    <AnswerButton onClick={onSelect}>
      <AnswerContent
        isSelectedAnswer={isSelectedAnswer}
        colorIndex={index}
        showDetails={showDetails}
      >
        <AnswerIcon iconIndex={index} />
        {showDetails
          && (
            <AnswerTextDiv>
              <AnswerText>
                {answer.text}
              </AnswerText>
            </AnswerTextDiv>
          )}
      </AnswerContent>
    </AnswerButton>
  );
}

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

export default Answer;
