import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import { ADD_APP_CONFIG } from './AppConfigActions';

const logger = Logger.getLogger('AppConfigReducer');

function AppConfigReducer(state = initialStore.appConfig, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_HUB');
      return {
        ...initialStore.appConfig,
      };

    case ADD_APP_CONFIG:
      logger.trace('ADD_APP_CONFIG', action);
      return {
        ...action.appConfig,
      };

    default:
      return state;
  }
}

export default AppConfigReducer;
