import IntermediaClient from '@novom/anyware-intermedia-engine/dist/client';
import { IntermediaProvider } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import settings from '#lib/settings';

import EventContext from '../../providers/EventProvider/EventContext';

function getStateUrl(cnamePrefix) {
  return `https://${cnamePrefix}-rt.skyshow.app`;
}

function getAggregationUrl(cnamePrefix) {
  return `https://${cnamePrefix}-agg.skyshow.app`;
}

function getSynchronizationUrl(cnamePrefix) {
  return `https://${cnamePrefix}-sync.skyshow.app`;
}

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const defaultProps = {
  children: undefined,
};

function IntermediaProviderManager({
  children,
}) {
  const { isMono, cnamePrefix } = useContext(EventContext);
  const { useUrlEvent, anyware: { intermedia: { url } } } = settings;

  let intermediaClient = null;
  if (useUrlEvent) {
    if (isMono) {
      intermediaClient = new IntermediaClient({ url: getStateUrl(cnamePrefix) });
    } else {
      intermediaClient = new IntermediaClient({
        actionUrl: getAggregationUrl(cnamePrefix),
        subscriptionUrl: getSynchronizationUrl(cnamePrefix),
      });
    }
  } else {
    intermediaClient = new IntermediaClient({ url });
  }

  return (
    <IntermediaProvider client={intermediaClient}>
      {children}
    </IntermediaProvider>
  );
}

IntermediaProviderManager.propTypes = propTypes;
IntermediaProviderManager.defaultProps = defaultProps;
export default IntermediaProviderManager;
