import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import CustomButton from '../../../components/CustomButton/CustomButton';
import {
  Container,
  ErrorPopover,
  InputControls,
  SeatInput,
  TextContainer,
} from './styles';

/**
 * @param {String} input
 */
function validateInput(input, range) {
  let isIncluded = true;
  const match = input.match('(^\\w+\\d+$)');
  if (range && !range.includes(input)) {
    isIncluded = false;
  }
  return (
    match
    && match.length > 0
    && isIncluded
  );
}

const propTypes = {
  location: PropTypes.shape().isRequired,
  onNext: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.string),
  seatError: PropTypes.bool,
  setSeat: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

const defaultProps = {
  range: null,
  seatError: false,
};

function Seat({
  location,
  onNext,
  range,
  seatError,
  setSeat,
  theme,
}) {
  const [newSeat, setNewSeat] = useState(location.seat);
  const [error, setError] = useState({ hasError: false, text: '' });
  const seatInputRef = useRef();

  const handleChange = useCallback(({ target: { value } }) => {
    setNewSeat(value);
    setError((e) => ({ ...e, hasError: false }));
  }, []);

  return (
    <Container>
      <TextContainer>
        {seatError
          ? <FormattedMessage id="onboarding_seat_pixel_error" />
          : <FormattedMessage id="onboarding_seat_text" />}
      </TextContainer>
      <InputControls>
        <SeatInput
          ref={seatInputRef}
          placeholder="Ex. : C15"
          value={newSeat}
          onChange={handleChange}
        />
        <Overlay show={error.hasError} target={seatInputRef.current} placement="bottom">
          <ErrorPopover><FormattedMessage id={error.text} /></ErrorPopover>
        </Overlay>
        <CustomButton
          disabled={error.hasError}
          onClick={() => {
            if (!validateInput(newSeat, range)) {
              setError({ hasError: true, text: 'onboarding_seat_invalid_error' });
              return;
            }
            setSeat(newSeat);
            onNext();
          }}
          themeCode={theme && theme.themeCode}
        >
          <FormattedMessage id="navigation_continue" />
        </CustomButton>
      </InputControls>
    </Container>
  );
}

Seat.propTypes = propTypes;
Seat.defaultProps = defaultProps;

export default Seat;
