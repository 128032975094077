import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import BlockOrientation from '../../../components/BlockedOrientation/BlockedOrientation';
import Carousel from '../../../components/Carousel/Carousel';
import { CarouselStep } from '../../../components/Carousel/styles';
import TriviaCarousel from '../../../components/Carousel/TriviaCarouselContainer';
import Logger from '../../../lib/Logger';
import EventContext from '../../../providers/EventProvider/EventContext';
import {
  BackgroundImage,
  InfoContainer,
  TextShadow,
  WaitingContainer,
  WaitingImage,
  WaitingImageContainer,
  WaitingTimeText,
  WaitingTitleContainer,
} from './styles';
import tutorialSteps from './tutorialSteps';

export const ANIMATION_DURATION_MS = 500;
const BUTTON_DURATION_MS = 250;
export const EXIT_DURATION_MS = ANIMATION_DURATION_MS + BUTTON_DURATION_MS;
const logger = Logger.getLogger('WaitingScreen');

const propTypes = {
  remainingMinutes: PropTypes.number.isRequired,
  selectedGame: PropTypes.string,
  theme: PropTypes.shape().isRequired,
  timerIsStarted: PropTypes.bool.isRequired,
};

const defaultProps = {
  selectedGame: null,
};

function WaitingScreen({
  remainingMinutes,
  selectedGame,
  theme,
  timerIsStarted,
}) {
  logger.trace('render()');

  const tutorialStep = tutorialSteps[selectedGame || 'waiting'];
  const { isIntegrated } = useContext(EventContext);

  return (
    <>
      <BackgroundImage selectedGame={selectedGame} />
      <WaitingContainer usePercents>
        <WaitingImageContainer>
          {theme.themeCode === 'normal' && <WaitingImage publicId={tutorialStep.logo} />}
        </WaitingImageContainer>
        <InfoContainer>
          {selectedGame !== 'trivia' && (
            <Carousel
              title={(
                <TextShadow>
                  <FormattedMessage id={tutorialStep.carouselTitle} />
                </TextShadow>
              )}
              steps={tutorialStep.carouselSteps.map((step) => (
                <CarouselStep key={step.id}>
                  <TextShadow>
                    <FormattedMessage id={step.text} />
                  </TextShadow>
                </CarouselStep>
              ))}
            />
          )}
          {selectedGame === 'trivia' && <TriviaCarousel />}
          {timerIsStarted && (
            <WaitingTitleContainer>
              <FormattedMessage id="orchestration_time_title" />
              <WaitingTimeText>
                <FormattedMessage
                  id="min"
                  defaultMessage={`{remainingMinutes, number} {remainingMinutes, plural,
                    =0 {minute}
                    one {minute}
                    other {minutes}
                  }`}
                  values={{ remainingMinutes }}
                />
              </WaitingTimeText>
            </WaitingTitleContainer>
          )}
        </InfoContainer>
      </WaitingContainer>
      {!isIntegrated && <BlockOrientation orientation="landscape" />}
    </>
  );
}

WaitingScreen.defaultProps = defaultProps;
WaitingScreen.propTypes = propTypes;

export default withTheme(WaitingScreen);
