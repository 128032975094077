import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import BlockedOrientation from '../../../components/BlockedOrientation/BlockedOrientation';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Logger from '../../../lib/Logger';
import CarouselImages from './carouselImages';
import {
  CarouselImage,
  CustomCarousel,
  Description,
  StoryContainer,
  StoryText,
  SubTitle,
  Title,
} from './styles';

const propTypes = {
  onNext: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

class Story extends Component {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);

    this.state = {
      currentImageIndex: 0,
    };
    this.nextStep = this.nextStep.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedIndex) {
    this.setState({
      currentImageIndex: selectedIndex,
    });
  }

  nextImage() {
    const { currentImageIndex } = this.state;
    if (currentImageIndex === CarouselImages.length - 1) {
      this.nextStep();
    } else {
      this.setState({ currentImageIndex: currentImageIndex + 1 });
    }
  }

  nextStep() {
    this.logger.trace('nextStep()');
    const { onNext } = this.props;
    onNext();
  }

  render() {
    const { currentImageIndex } = this.state;
    const { theme } = this.props;
    return (
      <>
        <StoryContainer>
          <CustomCarousel
            activeIndex={currentImageIndex}
            controls={false}
            indicators={false}
            interval={null}
            onSelect={this.handleSelect}
          >
            {CarouselImages.map((item, i) => (
              <CustomCarousel.Item key={item.title}>
                <CarouselImage publicId={`${item.image}${theme.cloudExtension}`} alt={`Carousel ${i}`} />
              </CustomCarousel.Item>
            ))}
          </CustomCarousel>
          <StoryText>
            {CarouselImages[currentImageIndex]
              && (
              <>
                <Title>
                  <FormattedMessage id={CarouselImages[currentImageIndex].title} />
                </Title>
                <SubTitle>
                  <FormattedMessage id={CarouselImages[currentImageIndex].subtitle} />
                </SubTitle>
                <Description>
                  <FormattedMessage id={CarouselImages[currentImageIndex].description} />
                </Description>
              </>
              )}
          </StoryText>
          <CustomButton type="dark" themeCode={theme && theme.themeCode} onClick={this.nextImage} id="nextButton">
            <FormattedMessage id="navigation_continue" />
          </CustomButton>
        </StoryContainer>
        <BlockedOrientation orientation="landscape" />
      </>
    );
  }
}

Story.propTypes = propTypes;

export default withTheme(Story);

export const StoryWithoutTheme = Story;
