import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {
  DefaultEmblem,
  DefaultTeamBox,
  Username,
} from './styles';

const propTypes = {
  extensionName: PropTypes.string,
  user: PropTypes.shape().isRequired,
};

const defaultProps = {
  extensionName: 'rockband',
};

function ProfileBar(props) {
  const {
    user,
    extensionName,
  } = props;

  return (
    <>
      <Username>{user.username}</Username>
      <DefaultTeamBox>
        {extensionName === 'rockband'
          ? <DefaultEmblem publicId="benchmarks/images/profile/rock" />
          : <FontAwesomeIcon icon={faLightbulbOn} style={{ color: 'white' }} />}
      </DefaultTeamBox>
    </>
  );
}

ProfileBar.propTypes = propTypes;
ProfileBar.defaultProps = defaultProps;

export default ProfileBar;
