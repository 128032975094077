import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

export const WaitingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Teams = styled.div`
  overflow: auto;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
`;

export const TeamWrapper = styled.div`
  width: 50%;
  display: flex;
  padding: 3%;
`;

export const Team = styled.button`
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  outline: none;
  opacity: ${(props) => ((props.selectedTeam && !props.selected) ? 0.5 : 1)};
  transition: opacity 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :focus {
    box-shadow: none;
    outline: 0;
  }
`;

export const TeamName = styled.div`
  width: 40vw;
  font-size: 2vmax;
  font-weight: bold;
  text-align: center;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TeamImage = styled(CloudImage)`
  filter: drop-shadow(0 8px 0 rgba(0, 0, 0, 0.3));
  max-width: 35vw;
  max-height: 35vw;
`;

export const TeamImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
`;
