import { gql } from '@apollo/client';

export const GET_ACTIVITY_USER_SCORE = gql`
  query($activityId: String!, $userId: String!){
    getActivityUser(activityId: $activityId, userId: $userId) {
      id
      score
      team
    }
  }
`;

export const GET_ACTIVITY_LEADERBOARD = gql`
  query($activityId: String!) {
    getActivityLeaderboard(activityId: $activityId) {
      users {
        id
        score
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_WINNING_TEAM = gql`
  query($activityId: String!) {
    getWinningTeam(activityId: $activityId) {
      name
      color
    }
  }
`;

export const GET_ACTIVITY_TEAM_LEADERBOARD = gql`
  query($activityId: String!, $team: String!) {
    getActivityTeamLeaderboard(activityId: $activityId, team: $team) {
      users {
        id
        score
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_MATRIX = gql`
  query($id: String!) {
    getMatrix(id: $id) {
      matrix
    }
  }
`;

export const GET_IS_APP_INTEGRATED = gql`
  query($id: String!) {
    getApp(id: $id) {
      appInfo {
        isIntegrated
      }
    }
  }
`;

export const GET_APP_INFO = gql`
  query($id: String!) {
    getApp(id: $id) {
      appInfo {
        eventName
          eventLogo
          eventDesc {
            en
            fr
          }
          socialNetworkLinks {
            twitter
            facebook
          }
          welcomeMessage
        }
    }
  }
`;

export const GET_PIXEL_BY_SEAT = gql`
  query($matrixId: String!, $seat: String!) {
    getMatrixPixelBySeat(matrixId: $matrixId, seat: $seat) {
      id
      location {
        seat
        geofence
      }
      schedule {
        color
        duration
      }
    }
  }
`;

export const GET_PIXEL_BY_LOCATION = gql`
  query($matrixId: String!, $fenceId: String!) {
    getMatrixPixelByGeofenceId(matrixId: $matrixId, fenceId: $fenceId) {
      id
      location {
        seat
        geofence
      }
      schedule {
        color
        duration
      }
    }
  }
`;

export const GET_USER = gql`
  query($id:String!) {
    user(id: $id){
      event {
        id
      }
    }
  }
`;

export const GET_USER_TEAM = gql`
  query($userId:String!, $activityId: String!) {
    getActivityUser(userId: $userId, activityId: $activityId){
      team
    }
  }
`;

export const GET_VALID_SEATS = gql`
  query($matrixId: String!) {
    getMatrixValidSeats(matrixId: $matrixId)
  }
`;

export const GET_ALL_DISPLAYS = gql`
  query($eventId: String!) {
    getAllDisplay(eventId: $eventId){
      id
      location {
        latitude
        longitude
      }
    }
  }
`;
