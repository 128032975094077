import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import CircularCountdown from '../CircularCountdown/CircularCountdown';
import {
  HeaderCol,
  HeaderContainer,
  Username,
} from './styles';

const propTypes = {
  currentQuestionIndex: PropTypes.number,
  duration: PropTypes.number.isRequired,
  restartTimer: PropTypes.func.isRequired,
  setTimeElapsed: PropTypes.func.isRequired,
  setTimerID: PropTypes.func.isRequired,
  startTimer: PropTypes.func.isRequired,
  storedTimer: PropTypes.shape().isRequired,
  username: PropTypes.string.isRequired,
};

const defaultProps = {
  currentQuestionIndex: null,
};

function CountdownHeader({
  currentQuestionIndex,
  duration,
  restartTimer,
  setTimeElapsed,
  setTimerID,
  startTimer,
  storedTimer,
  username,
}) {
  const [countdownElapsed, setCountdownElapsed] = useState(false);
  const [started, setStarted] = useState(false);
  const {
    duration: storedDuration,
    id,
    timeElapsed,
    timeStarted,
  } = storedTimer;

  useEffect(() => {
    setCountdownElapsed(false);

    if (currentQuestionIndex !== null) {
      if (currentQuestionIndex >= 0 && id !== currentQuestionIndex) {
        setTimerID(currentQuestionIndex);
        startTimer(duration);
        setStarted(true);
      } else if (currentQuestionIndex === id && timeStarted && !started) {
        restartTimer();
      }
    }
  }, [
    currentQuestionIndex,
    duration,
    id,
    restartTimer,
    setTimerID,
    startTimer,
    timeStarted,
    started,
  ]);

  return (
    <HeaderContainer>
      <CircularCountdown
        elapsed={countdownElapsed}
        onElapsed={() => setCountdownElapsed(true)}
        size={40}
        strokeOnColor="white"
        strokeWidth={2.5}
        showTimeLeft
        fontSize="0.7em"
        onSecondCallback={setTimeElapsed}
        timeElapsed={timeElapsed}
        milliseconds={storedDuration}
      />
      <HeaderCol>
        <Username>{`${username}`}</Username>
      </HeaderCol>
    </HeaderContainer>
  );
}

CountdownHeader.propTypes = propTypes;
CountdownHeader.defaultProps = defaultProps;

export default CountdownHeader;
