import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import CardListItem from '#components/CardListItem/CardListItem';

import Logger from '../../../../lib/Logger';
import { setTeam as setReduxTeam } from '../../../../reducers/UserReducer/UserActions';
import {
  Teams,
} from '../../TeamSelection/styles';
import IntegratedTeamSelection from './IntegratedTeamSelection';

const logger = Logger.getLogger('IntegratedTeamSelectionLogic');

const propTypes = {
  activityId: PropTypes.string.isRequired,
  emit: PropTypes.func.isRequired,
  joinTeam: PropTypes.func.isRequired,
  setTeam: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  theme: PropTypes.shape().isRequired,
  userId: PropTypes.string.isRequired,
};

const teamNames = {
  bassists: 'team_selection_team_bassists',
  drummers: 'team_selection_team_drummers',
  guitarists: 'team_selection_team_guitarists',
  synthplayers: 'team_selection_team_synthPlayers',
  trumpeters: 'team_selection_team_trumpeters',
  vocalists: 'team_selection_team_vocalists',
};

const difficulty = {
  bassists: 'team_selection_difficulty_medium',
  drummers: 'team_selection_difficulty_medium',
  guitarists: 'team_selection_difficulty_hard',
  synthplayers: 'team_selection_difficulty_medium',
  trumpeters: 'team_selection_difficulty_easy',
  vocalists: 'team_selection_difficulty_medium',
};

function IntegratedTeamSelectionLogic(props) {
  const {
    activityId,
    emit,
    joinTeam,
    setTeam,
    teams,
    theme,
    userId,
  } = props;
  const intl = useIntl();

  const [selectedTeam, setSelectedTeam] = useState('');
  const [confirmedTeam, setConfirmedTeam] = useState('');
  const dispatch = useDispatch();

  const handleTeamSelection = useCallback((teamName) => setSelectedTeam(teamName), []);

  const handleTeamConfirm = useCallback(async () => {
    try {
      const success = await joinTeam({
        variables: {
          activityId,
          team: selectedTeam,
          userId,
        },
      });
      if (success) {
        emit({ team: selectedTeam });
        setConfirmedTeam(selectedTeam);
        setTeam(selectedTeam);
        dispatch(setReduxTeam(selectedTeam, 'music'));
      }
    } catch (err) {
      logger.error(err);
    }
  }, [activityId, dispatch, emit, joinTeam, selectedTeam, setTeam, userId]);

  return (
    <IntegratedTeamSelection
      handleTeamConfirm={handleTeamConfirm}
      confirmedTeam={confirmedTeam}
      selectedTeam={selectedTeam}
      theme={theme}
    >
      <Teams>
        {teams.map(({ name: teamName }) => (
          <CardListItem
            key={teamName}
            background={`assets/app/mobile/${teamName}-card-bg-round`}
            disabled={confirmedTeam && confirmedTeam !== teamName}
            onClick={() => handleTeamSelection(teamName)}
            selectedTeam={selectedTeam}
            selected={selectedTeam === teamName}
            title={intl.formatMessage({ id: teamNames[teamName.toLowerCase()] })}
            subTitle={intl.formatMessage({ id: difficulty[teamName.toLowerCase()] })}
          />
        ))}
      </Teams>
    </IntegratedTeamSelection>
  );
}

IntegratedTeamSelectionLogic.propTypes = propTypes;

export default IntegratedTeamSelectionLogic;
