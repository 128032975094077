import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { QuestionContainer, QuestionNumber, Text } from '../styles';

const propTypes = {
  question: PropTypes.string.isRequired,
  triviaInfo: PropTypes.shape().isRequired,
};

function QuestionText({ question, triviaInfo }) {
  const { numberOfQuestions, questionsCompleted } = triviaInfo;
  return (
    <QuestionContainer>
      <QuestionNumber>
        {`Question ${questionsCompleted + 1} `}
        <FormattedMessage id="trivia_out_of" />
        {` ${numberOfQuestions}`}
      </QuestionNumber>
      <Text>{question}</Text>
    </QuestionContainer>
  );
}

QuestionText.propTypes = propTypes;

export default QuestionText;
