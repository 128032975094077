import { useSubscription } from '@novom/react-anyware-intermedia-client';
import React, { useMemo } from 'react';

import Prize from '#modules/Prize/Prize';
import Trivia from '#modules/Trivia/TriviaContainer';

import Loading from '../../components/Loading/Loading';
import Transition from '../../components/Transition/Transition';
import Logger from '../../lib/Logger';
import IntroductionContainer from '../Introduction/IntroductionContainer';
import LightShow from '../LightShow/LightShowContainer';
import RockBand from '../RockBand/RockBandContainer';

const logger = Logger.getLogger('Orchestration');

function Orchestration() {
  logger.trace('render()');
  const { data, loading, error } = useSubscription('orchestration');

  const {
    gameId,
    gameType,
    autoplayStep,
  } = useMemo(() => {
    if (data) {
      return data;
    }
    return {};
  }, [data]);

  // TODO : The offset should be calculated with intermediaClient.getServerTimeOffset()
  // TODO : But getServerTimeOffset seems to fail with a scalable rt backend.
  const offset = 0;

  if (loading || error) return <Loading />;

  return (
    <Transition currentGameType={gameType}>
      {() => (
        <>
          {gameType === 'musicGame' && (
            <RockBand
              key={gameId}
              musicGameId={gameId}
            />
          )}
          {gameType === 'prize' && (
            <Prize
              gameId={gameId}
            />
          )}
          {gameType === 'trivia' && (
            <Trivia
              key={gameId}
              triviaId={gameId}
            />
          )}
          {gameType === 'lightShow' && (
            <LightShow
              serverOffset={offset}
              lightShowId={gameId}
            />
          )}
          {(!gameType || gameType === 'slide') && (
            <IntroductionContainer
              currentStep={autoplayStep}
            />
          )}
        </>
      )}
    </Transition>
  );
}

export default React.memo(Orchestration);
