import PropTypes from 'prop-types';

import Logger from '../../lib/Logger';
import IntegratedGame from './Integrated/Game';
import IntegratedWarmup from './Integrated/Warmup/IntegratedWarmup';
import Tutorial from './Tutorial';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  song: PropTypes.shape().isRequired,
  team: PropTypes.string.isRequired,
  teamInfos: PropTypes.shape().isRequired,
};

const logger = Logger.getLogger('MultiplayerGame');

function MultiplayerGame(props) {
  logger.trace('render()');

  const {
    activityId,
    currentStep,
    inputType,
    song,
    team,
    teamInfos,
  } = props;

  return (
    <>
      {currentStep === 'WAITING' && (
        <Tutorial inputType={inputType} team={team} />
      )}
      {currentStep === 'WARMUP' && (
        <IntegratedWarmup />
      )}
      {currentStep === 'GAME' && (
        <IntegratedGame
          activityId={activityId}
          currentStep={currentStep}
          song={song}
          team={team}
          inputType={inputType}
          teamInfos={teamInfos}
        />
      )}
    </>
  );
}

MultiplayerGame.propTypes = propTypes;

export default MultiplayerGame;
