import { push } from 'connected-react-router';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import GoogleAnalyticsContext from '#lib/GoogleAnalytics/GoogleAnalyticsContext';

import getBrowserLanguage from '../../lib/getBrowserLanguage';
import { supportedLanguages } from '../../lib/i18n/i18n';
import EventContext from '../../providers/EventProvider/EventContext';
import {
  setNextOnboardingStep as setNextOnboardingStepAction,
  setOnboardingToCompleted as setOnboardingToCompletedAction,
} from '../../reducers/OnboardingReducer/OnboardingActions';
import Onboarding from './Onboarding';
import {
  language,
  permissions,
  seat,
  username,
} from './onboardingSteps';

const mapStateToProps = (state) => ({
  currentIndex: state.onboarding.currentIndex,
  onboardingCompleted: state.onboarding.onboardingCompleted,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  redirectToAfterOnboarding: () => dispatch(push('/orchestration')),
  setNextOnboardingStep: (nextIndex) => { dispatch(setNextOnboardingStepAction(nextIndex)); },
  setOnboardingToCompleted: () => { dispatch(setOnboardingToCompletedAction()); },
});

const propTypes = {
  currentIndex: PropTypes.number.isRequired,
  onboardingCompleted: PropTypes.bool.isRequired,
  redirectToAfterOnboarding: PropTypes.func.isRequired,
  setNextOnboardingStep: PropTypes.func.isRequired,
  setOnboardingToCompleted: PropTypes.func.isRequired,
};
function OnboardingContainer(props) {
  const {
    currentIndex,
    onboardingCompleted,
    redirectToAfterOnboarding,
    setNextOnboardingStep,
    setOnboardingToCompleted,
  } = props;

  const { onboarding } = useContext(EventContext);

  useEffect(() => {
    if (onboardingCompleted) {
      redirectToAfterOnboarding();
    }
  }, [onboardingCompleted, redirectToAfterOnboarding]);

  const analytics = useContext(GoogleAnalyticsContext);
  const { logEvent } = analytics;
  useEffect(() => {
    logEvent('first_visit');
  }, [analytics, logEvent]);

  const languageIsSupported = supportedLanguages.includes(getBrowserLanguage());

  const steps = [
    ...languageIsSupported ? [language] : [],
    username,
    ...onboarding?.permissionsEnabled ? [permissions] : [],
    ...onboarding?.seatEnabled ? [seat] : [],
  ];

  return (
    <Onboarding
      currentIndex={currentIndex}
      onboardingCompleted={onboardingCompleted}
      redirectToAfterOnboarding={redirectToAfterOnboarding}
      setNextOnboardingStep={setNextOnboardingStep}
      setOnboardingToCompleted={setOnboardingToCompleted}
      steps={steps}
    />
  );
}

OnboardingContainer.propTypes = propTypes;

const ConnectedOnboarding = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OnboardingContainer);

export default ConnectedOnboarding;
