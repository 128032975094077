import styled from 'styled-components';

import {
  BLUE_PURPLE,
  LILAC,
  UNI_RED,
} from '../../styles/colors';

export const NavWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  background-color: ${(props) => (props.open ? 'rgba(0, 0, 0, 0.4)' : 'transparent')};
  user-select: none;
  pointer-events: none;
`;

export const NavBar = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  pointer-events: all;
`;

export const NavControllerIcon = styled.div`
  transform: ${(props) => (props.open ? 'rotate(315deg)' : 'rotate(0)')};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color:
    ${(props) => (
    // eslint-disable-next-line no-nested-ternary
    props.open
      ? props.theme.themeCode === 'normal'
        ? LILAC
        : props.theme.primaryColorLight
      : props.theme.themeCode === 'normal'
        ? BLUE_PURPLE
        : props.theme.primaryColor
  )};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.5s;
  cursor: pointer;
`;

export const NavController = styled.div`
  height: 10vh;
  width: 10vh;
  padding: 1rem;
  display: flex;
`;

export const NavIcon = styled.div`
  height: 5vh;
  width: 5vh;
  border-radius: 50%;
  background-color: ${(props) => (props.theme.themeCode === 'normal' ? BLUE_PURPLE : UNI_RED)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const NavItem = styled.div`
  transform:
    ${(props) => (props.open
    ? `scale(1) translateY(${3 * (props.index + 1)}vh)`
    : 'scale(0.8) translateY(0)'
  )};
  opacity: ${(props) => (props.open ? 1 : 0)};
  display: flex;
  top: 0;
  left: 0;
  margin-left: 2.5vh;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  transition: all 0.5s;
`;

export const NavItemText = styled.p`
  margin: 0;
  display: flex;
  color: white;
  border-radius: 50%;
  align-items: center;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.5s;
  padding-left: 10px;
`;
