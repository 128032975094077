/* cSpell:language fr, en */

export default {
  // GraphQL Errors
  'GraphQL error: Resource not found': 'Erreur: Impossible de trouver le serveur associé à cette app',

  // Beacon Your Friends
  beaconYourFriends_generate: 'Trouver un ami',
  beaconYourFriends_intro: 'Retrouvez vos amis en faisant clignoter un code de couleurs sur votre téléphone.',
  beaconYourFriends_nocode: "Oups, il n'y a plus de codes disponibles. Veuillez réessayer plus tard.",
  beaconYourFriends_retry: 'Réessayer',
  beaconYourFriends_share: 'Partager',
  beaconYourFriends_shareInfo: "Envoyez ce code de couleurs à vos amis et levez votre téléphone en l'air pour qu'ils puissent vous repérer.",
  beaconYourFriends_start: 'Démarrer',
  beaconYourFriends_stop: 'Arrêter',

  // Bingo
  bingo_invalid: 'Bingo invalide',
  bingo_tutorial_explication: 'Complétez une rangée, une colonne ou une diagonale',
  bingo_tutorial_title: 'Comment jouer',

  click_to_start: 'Cliquer pour commencer',
  code: 'Code: {code}',

  // Errors
  error_location: 'Désolé. Nous ne pouvons pas établir votre emplacement.',
  error_newAttemptIn: 'Nouvelle tentative dans {secondes} sec.',
  error_orientation: 'Veuillez pivoter votre écran.',
  error_server: 'Désolé. Impossible d\'établir une connexion avec le serveur.',

  // Explanation
  explanation_message: 'Secouer votre appareil mobile au bon moment en suivant le rythme de la musique.',
  explanation_title: 'Comment jouer',

  // Exploration
  exploration_start: 'Jouer',

  // Inputs
  inputType_shake: 'Secouer',
  inputType_shake_unavailable: 'Secouer n\'est pas disponible sur votre appareil. Veuillez consulter les paramètres de votre navigateur pour activer l\'accéléromètre.',
  inputType_title: 'Choisis le type d\'interaction',
  inputType_touch: 'Taper',

  // Introduction
  introduction_carousel_title_lightShow: 'Préparez-vous!',
  introduction_carousel_title_poll: 'Donnez votre avis!',
  introduction_carousel_title_rockband: 'TENEZ-VOUS PRÊTS!',
  introduction_carousel_title_trivia: 'Le saviez-vous?',
  introduction_carousel_title_waiting: 'TENEZ-VOUS PRÊTS!',
  introduction_text_1: "Vous serez bientôt dans le feu de l'action. D'ici là, réchauffez-vous bien.",
  introduction_text_2: "Vous devrez choisir entre devenir guitariste ou batteur. Votre téléphone intelligent vous servira d'instrument.",
  introduction_text_3: "Regardez l'écran géant et suivez le rythme du mieux que vous pouvez pour accumuler des points!",
  introduction_text_bingo: 'Dans un moment, vous devrez être attentif à votre émission de télévision. Votre téléphone intelligent vous servira de carte de bingo.',
  introduction_text_lightShow: "Dans un moment, vous devrez lever votre cellulaire et diriger l'écran vers l'avant!",
  introduction_text_poll: 'Dans les moments qui vont suivre, vous prendrez part à un sondage aux côtés de plusieurs autres utilisateurs.',

  // Languages
  language_en: 'English',
  language_fr: 'Français',
  language_selection: 'Sélectionnez votre langue',

  // Leaderbord
  leaderboard_all_players: 'Tous les joueurs',
  leaderboard_player: 'Joueur',
  leaderboard_rank: '#',
  leaderboard_score: '%',
  leaderboard_team: 'Mon équipe',
  leaderboard_title: 'Tableau des scores',

  // LightShow
  lightShow_geolocation: 'On essaie de vous trouver!',
  lightShow_geolocation_subtext: 'Merci de patienter',
  lightShow_intro_text: "L'animation lumineuse va bientôt commencer. Préparez-vous à lever votre téléphone!",

  // Location
  location_stand_message: 'Placez-vous dans la foule.',
  location_stay_message: 'Demeurez maintenant à votre place.',
  location_user_ready: 'Je suis prêt(e)',
  location_waiting: 'Veuillez patienter pendant qu\'on vous localise.',

  look_up: 'Regarder l\'écran géant',

  minute: 'minute',
  minutes: 'minutes',

  // MultiplayerWaiting
  multiplayerWaiting_subtitle: 'Le jeu va commencer',
  multiplayerWaiting_title: 'Préparez-vous!',

  // Music Waiting
  music_waiting_sub: 'Nous compilons les résultats.',
  music_waiting_text: 'Merci de votre participation!',
  music_warmup_title: 'Échauffement',

  // Navigation
  navigation_cancel: 'Annuler',
  navigation_choose: 'Choisir',
  navigation_close: 'Fermer',
  navigation_continue: 'Continuer',
  navigation_skip: 'Passer',

  // Notification
  notification_go_to_game: 'Aller au jeu',

  ok: 'OK',

  // Onboarding Seat
  onboarding_seat_invalid_error: 'Le numéro de siège est invalide. Veuillez revérifier.',
  onboarding_seat_pixel_error: 'Il y a un problème avec votre numéro de siège... Veuillez revérifier.',
  onboarding_seat_text: 'Entrez votre numéro de siège',

  // Orchestration
  orchestration_error: 'Oups! Une erreur est survenue.',
  orchestration_notification: 'Recevoir une notification',
  orchestration_time_title: 'Le jeu commence dans',

  // Page Not found
  page_not_found: 'Oups! Page introuvable.',
  page_not_found_go_back: 'Retour au site.',

  // Permissions
  permission_error_location_instruction: 'Puisque la géolocalisation est désactivée sur votre appareil, vous devez manuellement choisir un emplacement dans la liste ci-dessous',
  permission_error_location_placeholder: 'Choisir...',
  permission_error_location_required: "La localisation est requise pour cette partie de l'expérience.",
  permission_error_location_submit: 'Valider',
  permission_geolocation_text: 'La géolocalisation rendra l\'expérience plus précise.',
  permission_geolocation_title: 'Géolocalisation',
  permission_microphone_text: 'Le microphone peut être utilisé dans l\'expérience.',
  permissions_deny_geoloc: 'Refuser',
  permissions_enable_geoloc: 'Autoriser',
  permissions_enable_media: 'Autoriser',
  permissions_error_geoloc: 'Veuillez autoriser ou refuser la géolocalisation.',
  permissions_title: 'Nous avons besoin d\'accès à',
  permissions_warn_geoloc: "En refusant, certaines parties de l'expérience ne seront pas accessibles. Vérifier les paramètres de votre navigateur si vous êtes incapables d'accepter.",

  // Prize
  prize_better_luck: 'Meilleure chance la prochaine fois.',
  prize_id: 'code: {id}',
  prize_intro: '{itemCount, plural, one {La personne gagnante sera dévoilée sous peu.} other {Les personnes gagnantes seront dévoilées sous peu.}}',
  prize_no_prize: 'Vous n\'avez gagné aucun prix pour le moment.',
  prize_not_winner: 'Merci pour votre participation',
  prize_revealing: 'Gagnerez-vous?',
  prize_winner: ', vous avez gagné!',
  prize_winner_name: 'Bravo {name}',
  prizes_won: 'Prix gagnés',

  // Profile page
  profile_language: 'Langue',
  profile_team: 'Mon équipe',
  profile_termsAndConditions: 'Termes & conditions',
  profile_title: 'Profil du joueur',
  profile_username: 'Mon nom',

  pts: 'pts',

  result_good: 'Trop bien!',
  result_poor: 'Merci!',

  // Reward Page
  reward_congrats_collect: 'Prendre',
  reward_congrats_subtitle: 'Vous avez gagné',
  reward_congrats_title: 'Félicitations!',

  // Server errors
  server_error_unreachable: 'Erreur: Serveur inaccessible, veuillez ré-essayer plus tard',

  // Server offset
  server_offset_loading: 'Synchronisation en cours...',

  // SocialShare
  social_share_subtitle: 'Pour savoir quand vous pourrez récupérer la vidéo!',
  social_share_title: 'Suivez-nous sur les réseaux sociaux',

  // Story page
  story_desc_1: 'Vous pourrez participer à différents mini-jeux pendant la partie.',
  story_desc_2: "Prenez part à l'expérience et aidez votre équipe à obtenir la victoire.",
  story_subtitle_1: 'chance de gagner un prix',
  story_subtitle_2: '& suivez les instructions',
  story_title_1: 'Jouez et courez la',
  story_title_2: 'Ayez votre téléphone',

  // Team
  team_selection_confirm: 'Confirmer',
  team_selection_difficulty_easy: 'Facile',
  team_selection_difficulty_hard: 'Difficile',
  team_selection_difficulty_medium: 'Moyen',
  team_selection_found: "Vous avez rejoins l'équipe: {name}!",
  team_selection_team_bassists: 'Bassistes',
  team_selection_team_drummers: 'Batteurs',
  team_selection_team_guitarists: 'Guitaristes',
  team_selection_team_synthPlayers: 'Claviéristes',
  team_selection_team_trumpeters: 'Trompettistes',
  team_selection_team_vocalists: 'Vocalistes',
  team_selection_text: 'Le jeu va débuter sous peu.',
  team_selection_title: 'Choisis ton équipe!',
  team_selection_title_geoloc: 'Une équipe vous sera attribuée!',

  // Terms And Conditions
  terms_privacy_access_text: "Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant. L'exercice de ce droit se fera: Code postal: 155, rue Principale Ouest, Suite 100, Magog J1X2A7, Courriel: support@novominteractive.com, Téléphone: (819) 769-0741, Section du site web: www.skyshow.app/",
  terms_privacy_access_title: "Droit d'accès",
  terms_privacy_cookie_text: "Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers témoins (cookies). Il s'agit principalement des informations suivantes: Adresse IP, Système d'exploitation, Pages visitées et requêtes, Heure et jour de connexion. Le recours à de tels fichiers nous permet: Amélioration du service et accueil personnalisé, Statistique.",
  terms_privacy_cookie_title: 'Fichiers journaux et témoins',
  terms_privacy_form_text: "Vos renseignements personnels sont collectés par le biais de formulaire, à savoir: Jeu. Nous utilisons les renseignements ainsi collectés pour les finalités suivantes: Informations / Offres promotionnelles. Vos renseignements sont également collectés par le biais de l'interactivité pouvant s'établir entre vous et notre site Web et ce, de la façon suivante: Nous utilisons les renseignements ainsi collectés pour les finalités suivantes: Forum ou aire de discussion, Commentaires.",
  terms_privacy_form_title: 'Formulaires  et interactivité',
  terms_privacy_intro_text: "Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée. C'est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.",
  terms_privacy_intro_title: 'Introduction',
  terms_privacy_opposition_text: "Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels. Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte. Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion. Pour pouvoir exercer ces droits, vous pouvez: Code postal: 155, rue Principale Ouest, Suite 100, Magog J1X2A7, Courriel: support@novominteractive.com, Téléphone: (819) 769-0741, Section du site web: www.skyshow.app/",
  terms_privacy_opposition_title: "Droit d'opposition et de retrait",
  terms_privacy_privacy_text: "Nous collectons les renseignements suivants: Prénom, Préférences (musicales, littéraires, cinématographiques, etc.) Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l'interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations vous concernant.",
  terms_privacy_privacy_title: 'Collecte des renseignements personnels',
  terms_privacy_security_text: "Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations. Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes: Protocole SSL (Secure Sockets Layer), Gestion des accès - personne concernée, Sauvegarde informatique. Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours présente lorsque l'on utilise Internet pour transmettre des renseignements personnels.",
  terms_privacy_security_title: 'Sécurité',
  terms_privacy_title: 'POLITIQUE MODÈLE DE CONFIDENTIALITÉ',

  // Trivia
  trivia_correct_answer: 'Correct',
  trivia_expand: 'Vue détaillée',
  trivia_minimize: 'Vue simplifiée',
  trivia_out_of: 'sur',
  trivia_time: 'Vous avez répondu en {time} sec',
  trivia_waiting: 'Merci de patienter pour la prochaine question.',
  trivia_wrong_answer: 'Incorrect',

  // Tutorial
  tutorial_text_shake: "Quand les notes atteignent la zone de contact sur l'écran géant, SECOUER votre téléphone",
  tutorial_text_touch: "Quand les notes atteignent la zone de contact sur l'écran géant, APPUYER sur votre écran",
  tutorial_title: 'Tutoriel',
  tutorial_trivia: 'Répondez aux questions le plus rapidement possible.',

  user_feedback_send: 'Envoyer',
  userFeedback_leave_comment: "Faites nous parvenir vos commentaires sur l'expérience, merci!",
  userFeedback_thanks: 'Merci pour votre participation!\nBon spectacle.',

  // Username page
  username_alreadyTaken: 'Ce nom a déjà été pris',
  username_censored: 'Ton nom a été censuré, car il comportait un langage inapproprié.',
  username_input: "Entrez un nom d'utilisateur",
  username_question: 'Quel est \n     ton nom?',

  waiting_for_score: 'Votre score est entrain d\' être calculé...',
  your_result_is: 'Votre résultat est',
};
