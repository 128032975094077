import { useQuery } from '@apollo/client';
import { useSubscription } from '@novom/react-anyware-intermedia-client';
import { geoContains } from 'd3-geo';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Loading from '#components/Loading/Loading';
import { GET_LIGHT_SHOW } from '#lib/apollo/LightShow/queries';

import Transition from '../../components/Transition/Transition';
import { GET_PIXEL_BY_LOCATION, GET_PIXEL_BY_SEAT } from '../../lib/apollo/queries';
import Introduction from '../Introduction/IntroductionContainer';
import Permissions from '../Onboarding/Permissions/PermissionsContainer';
import EffectDisplay from './EffectDisplay/EffectDisplay';
import IOSEffectDisplay from './EffectDisplay/IOSEffectDisplay';
import PixelError from './PixelError';
import LightEffectsContainer from './styles';

const propTypes = {
  lightShowId: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  permissions: PropTypes.shape().isRequired,
  serverOffset: PropTypes.number,
  user: PropTypes.shape().isRequired,
};

const defaultProps = {
  serverOffset: null,
};

function LightShow({
  location,
  user,
  permissions,
  lightShowId,
  serverOffset,
}) {
  const {
    data: lightShowData,
    loading: lightShowLoading,
  } = useQuery(GET_LIGHT_SHOW, {
    fetchPolicy: 'network-only',
    skip: !lightShowId,
    variables: {
      id: lightShowId,
    },
  });

  const teamMode = lightShowData?.getLightShow?.teamMode;
  const matrixId = lightShowData?.getLightShow?.matrix?.id;

  const {
    data: seatPixel,
    loading: seatLoading,
  } = useQuery(GET_PIXEL_BY_SEAT, {
    fetchPolicy: 'network-only',
    skip: !matrixId || teamMode !== 'seat',
    variables: {
      matrixId,
      seat: location.seat,
    },
  });

  const fence = useMemo(() => {
    const geofences = lightShowData?.getLightShow?.geofences;
    if (geofences && location) {
      return geofences.find(({ collection }) => {
        const { features } = JSON.parse(collection);
        const { lat, lon } = location.position;
        return features.find((feature) => geoContains(feature, [lat, lon]));
      });
    }
    return false;
  }, [lightShowData, location]);

  const {
    data: locationPixel,
  } = useQuery(GET_PIXEL_BY_LOCATION, {
    fetchPolicy: 'network-only',
    skip: !matrixId || teamMode !== 'geolocation' || !fence?.id,
    variables: {
      fenceId: fence.id,
      matrixId,
    },
  });

  const { data, loading } = useSubscription('lightShow');

  if (loading || lightShowLoading) return <Loading />;
  if (teamMode === 'seat' && !seatLoading && !seatPixel?.getMatrixPixelBySeat) return (<PixelError teamMode={teamMode} matrixId={matrixId} />);
  if (teamMode === 'geolocation' && !permissions.geolocation) return <Permissions />;

  const pixel = seatPixel?.getMatrixPixelBySeat || locationPixel?.getMatrixPixelByGeofenceId;
  const currentStep = data?.step;
  const startTime = data?.startTime;
  const isIPhone = window.navigator.userAgent.includes('iPhone');
  return (
    <Transition currentStep={currentStep}>
      {() => (
        <>
          {currentStep === 'INTRODUCTION' && (
            <Introduction user={user} selectedGame="lightShow" />
          )}
          {currentStep === 'LIGHT_SHOW' && (isIPhone ? (
            <LightEffectsContainer usePercents>
              <IOSEffectDisplay
                pixel={pixel}
                serverOffset={serverOffset}
                startTime={startTime}
              />
            </LightEffectsContainer>
          ) : (
            <LightEffectsContainer usePercents>
              <EffectDisplay
                pixel={pixel}
                serverOffset={serverOffset}
                startTime={startTime}
              />
            </LightEffectsContainer>
          ))}
        </>
      )}
    </Transition>
  );
}

LightShow.propTypes = propTypes;
LightShow.defaultProps = defaultProps;

export default LightShow;
