import { useApolloClient } from '@apollo/client';
import { createElement, forwardRef } from 'react';

function withIntermediaClient(WrappedComponent) {
  return forwardRef((props, ref) => {
    const apolloClient = useApolloClient();
    return createElement(WrappedComponent, { ...props, apolloClient, ref });
  });
}

export default withIntermediaClient;
