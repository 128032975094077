import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

import { bounceIn, fadeIn } from '../../styles/animations';
import {
  UNI_GOLD,
  WHITE,
} from '../../styles/colors';
import { Text, Title } from '../../styles/styledComponents';

const leaderboardBkg1 = 'benchmarks/images/leaderboard/bkgPurpleWave';
const leaderboardBkg2 = 'benchmarks/images/leaderboard/bkgPurpleWave2';

const leaderboardBkg = {
  normal: leaderboardBkg1,
  rougeOr: leaderboardBkg2,
};

const ANIMATION_DURATION_MS = 1000;

export const LeaderboardContainer = styled.div`
  width: 100%;
  height: 100%;
  animation: ${fadeIn} ${ANIMATION_DURATION_MS}ms forwards;
`;

export const LeaderboardRows = styled.div`
  overflow-y: scroll;
  height: 85%;
`;

export const Header = styled(Title)`
  font-size: 8vmin;
  margin: 10% 0;
  animation: ${bounceIn} ${ANIMATION_DURATION_MS}ms forwards;
`;

export const Row = styled.div`
  height: 18%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color:
    ${(props) => (props.myScore && `${UNI_GOLD}`)
    || (props.index % 2 === 0 && `${WHITE}`)
    || '#d6d6d6'};
`;

export const HeaderRow = styled(Row)`
  margin: 0;
  height: 100%;
  background-color: ${(props) => props.theme.primaryColor};
`;

export const RankColumn = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HeaderRankColumn = styled(RankColumn)`
  background: none;
  position: relative;
`;

export const RankText = styled(Text)`
  color: white;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-weight: bold;
  font-size: 2vh;
  background-color: ${(props) => props.theme.primaryColorLight};
  border-radius: 999px;
  width: 5vh;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const UsernameColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2%;
`;

export const ScoreColumn = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const HeaderUsernameColumn = styled(UsernameColumn)`
  justify-content: center;
  position: relative;
  padding-right: none;
`;

export const TeamEmblem = styled.img`
  width: 5vmin;
  object-fit: contain;
  margin-right: 2vmin;
`;

export const LeaderboardText = styled(Text)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 3.5vmin;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.primaryColor};
`;

export const ScoreText = styled(LeaderboardText)`
  font-weight: bold;
`;

export const HeaderText = styled(LeaderboardText)`
  color: white;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 4vmin;
`;

export const HeaderContainer = styled.div`
  height: 15%;
`;

export const UserContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UserResults = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
`;

export const LeaderboardTabContainer = styled.div`
  width: 90vw;
  height: 60%;
`;

export const BackgroundImage = styled(CloudImage).attrs((props) => ({
  publicId: leaderboardBkg[props.theme.themeCode],
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Pts = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 2.5vh;
  margin-bottom: 1.5vh;
  margin-left: 1vw;
`;

export const Tabs = styled.div`
  width: 100%;
  height: 6vh;
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.p`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2vh;
  font-family: ${(props) => props.theme.fontFamily};
  color: white;
  background-color:
    ${(props) => (props.active
    ? props.theme.primaryColor
    : props.theme.primaryColorLight
  )};
  border-radius: 20px 20px 0 0;
`;

export const Username = styled.p`
  text-align: center;
  color: white;
  font-size: 2.5vh;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: bold;
`;

export const UserPoints = styled.p`
  color: ${(props) => props.theme.secondaryColor};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-weight: bold;
  font-size: 6vh;
`;

export const UserPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const UserRank = styled.p`
  color: ${(props) => props.theme.primaryColorLight};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-weight: bold;
  font-size: 4vh;
  background-color: white;
  border-radius: 999px;
  width: 10vh;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 6vw;
`;
