import styled, { keyframes } from 'styled-components';

export const ConfettisCanvas = styled.canvas`
  position: absolute;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  max-width: initial;
  height: 100%;
  margin: 0;
`;

export const IntroText = styled.div`
  text-align: center;
`;

export const PrizeContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 2em;
`;

const rainbow = keyframes`
  0% { background-color: rgb(19, 133, 221); }
  20% { background-color: rgb(0, 190, 120); }
  40% { background-color: rgb(255, 244, 88); }
  60% { background-color: rgb(230, 159, 0); }
  80% { background-color: rgb(204, 121, 167); }
  100% { background-color: rgb(19, 133, 221); }
`;

const CHColors = keyframes`
  0% { background-color: rgb(24, 32, 101); }
  20% { background-color: rgb(255, 255, 255); }
  40% { background-color: rgb(170, 28, 44); }
  60% { background-color: rgb(24, 32, 101); }
  80% { background-color: rgb(255, 255, 255); }
  100% { background-color: rgb(170, 28, 44); }
`;

export const Rainbow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rainbow} 1s infinite;
`;

export const CanadianFlash = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${CHColors} 1.5s infinite;
`;

export const FlashingColors = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation:
    ${({ colors }) => {
    const frames = colors.map((c, i) => `${parseInt(i * (100 / colors.length), 10)}% { background-color: ${c}; }`);
    return (
      keyframes`
        ${frames}
      `
    );
  }} 1.5s infinite;
`;

export const RevealContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const SmallText = styled.div`
  font-size: 0.5em;
`;

export const SmallerText = styled.div`
  font-size: 0.3em;
  position: absolute;
  bottom: 5%;
`;
