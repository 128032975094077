import { connect } from 'react-redux';

import Story from './Story';

const mapStateToProps = (state) => ({
  language: state.config.language,
});

const StoryContainer = connect(mapStateToProps)(Story);

export default StoryContainer;
