import { useIntermediaClient } from '@novom/react-anyware-intermedia-client';
import { createElement, forwardRef } from 'react';

function withIntermediaClient(WrappedComponent) {
  return forwardRef((props, ref) => {
    const intermediaClient = useIntermediaClient();
    return createElement(WrappedComponent, { ...props, intermediaClient, ref });
  });
}

export default withIntermediaClient;
