import { library } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Logger from '../../lib/Logger';
import {
  LoadingContainer,
  LoadingFrame,
  LoadingIcon,
  Message,
} from './styles';

library.add(faHourglassHalf);

const propTypes = {
  messageKey: PropTypes.string,
};

const defaultProps = {
  messageKey: null,
};

const logger = Logger.getLogger('Loading');

function Loading({ messageKey }) {
  logger.trace('render()');
  return (
    <LoadingContainer>
      <LoadingFrame>
        <LoadingIcon><FontAwesomeIcon icon="hourglass-half" /></LoadingIcon>
      </LoadingFrame>
      {messageKey && <Message><FormattedMessage id={messageKey} /></Message>}
    </LoadingContainer>
  );
}

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
