import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Logger from '../../lib/Logger';
import SecretButton from './styles';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  resetApp: PropTypes.func.isRequired,
};

class ResetButton extends PureComponent {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);
    this.logger.trace('constructor()');
    this.state = { numberOfClick: 0 };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { dispatch, resetApp } = this.props;
    const { numberOfClick } = this.state;

    const newNumberOfClick = numberOfClick + 1;
    this.logger.trace('onClick()', newNumberOfClick);

    if (newNumberOfClick >= 3) {
      this.logger.trace('reset app');
      this.setState({ numberOfClick: 0 });
      resetApp();
      dispatch(push('/'));
      window.location.reload();
    } else {
      this.setState({ numberOfClick: newNumberOfClick });
    }
  }

  render() {
    return (
      <SecretButton onClick={this.onClick} id="resetButton" />
    );
  }
}

ResetButton.propTypes = propTypes;

export default ResetButton;
