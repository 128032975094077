import { connect } from 'react-redux';

import WinnerOrchestration from './WinnerOrchestration';

const mapStateToProps = ({ user }) => ({
  team: user.team.trivia,
});

const WinnerOrchestrationContainer = connect(
  mapStateToProps,
)(WinnerOrchestration);

export default WinnerOrchestrationContainer;
