import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Loading from '../../../components/Loading/Loading';
import { AnswersContainer } from '../styles';
import Answer from './Answer';
import QuestionText from './QuestionText';

const propTypes = {
  currentQuestion: PropTypes.shape().isRequired,
  selectedAnswer: PropTypes.string,
  setSelectedAnswer: PropTypes.func.isRequired,
  showDetails: PropTypes.bool.isRequired,
  triviaInfo: PropTypes.shape().isRequired,
};

const defaultProps = {
  selectedAnswer: null,
};

function Question({
  currentQuestion,
  selectedAnswer,
  setSelectedAnswer,
  showDetails,
  triviaInfo,
}) {
  const [startTime, setStartTime] = useState();
  useEffect(() => {
    setStartTime(+moment());
  }, []);
  if (!currentQuestion) return <Loading />;
  const { answers, question } = currentQuestion;
  return (
    <>
      {showDetails && <QuestionText question={question} triviaInfo={triviaInfo} />}
      <AnswersContainer>
        {answers.map((answer, i) => (
          <Answer
            answer={answer}
            index={i}
            key={answer.id}
            isSelectedAnswer={selectedAnswer ? answer.id === selectedAnswer : null}
            onSelect={selectedAnswer || triviaInfo.status !== 'answering'
              ? () => { }
              : () => setSelectedAnswer(answer.id, +moment() - startTime)}
            showDetails={showDetails}
          />
        ))}
      </AnswersContainer>
    </>
  );
}

Question.propTypes = propTypes;
Question.defaultProps = defaultProps;

export default Question;
