import { useMutation } from '@apollo/client';
import { useAction } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';

import { JOIN_TEAM } from '../../../../lib/apollo/mutations';
import IntegratedTeamSelectionLogic from './IntegratedTeamSelectionLogic';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  setTeam: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

function IntegratedTeamSelectionHooks({
  activityId,
  setTeam,
  teams,
  theme,
}) {
  const userId = useSelector((state) => state.user.id);
  const { emit } = useAction('musicGame', 'ADD_TO_PLAYER_COUNT');
  const [joinTeam] = useMutation(JOIN_TEAM);

  return (
    <IntegratedTeamSelectionLogic
      activityId={activityId}
      emit={emit}
      joinTeam={joinTeam}
      setTeam={setTeam}
      teams={teams}
      theme={theme}
      userId={userId}
    />
  );
}

IntegratedTeamSelectionHooks.propTypes = propTypes;

export default withTheme(IntegratedTeamSelectionHooks);
