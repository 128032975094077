import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SmallerText, SmallText } from '../styles';
import Confettis from './Confettis';

const propTypes = {
  code: PropTypes.string.isRequired,
  isWinner: PropTypes.bool.isRequired,
};

function RevealResult(props) {
  const user = useSelector((state) => state.user);
  const { isWinner, code } = props;

  if (isWinner) {
    return (
      <>
        <Confettis />
        <FormattedMessage id="prize_winner_name" values={{ name: user.username }} />
        <FormattedMessage id="prize_winner" />
        <SmallText><FormattedMessage id="prize_id" values={{ id: code }} /></SmallText>
        <SmallerText>{`Id: ${user.id}`}</SmallerText>
      </>
    );
  }

  return (
    <>
      <FormattedMessage id="prize_not_winner" />
      <SmallText><FormattedMessage id="prize_better_luck" /></SmallText>
    </>
  );
}

RevealResult.propTypes = propTypes;

export default RevealResult;
