import styled from 'styled-components';

export const AbsoluteFillObject = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const BeginMessage = styled.h1`
  font-size: 28px;
  color: white;
  text-align: center;
  user-select: none;
`;
