import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_LOCATION,
  SET_POSITION,
  SET_SEAT,
} from './LocationActions';

const logger = Logger.getLogger('LocationReducer');

function LocationReducer(state = initialStore.location, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_LOCATION');
      return { ...initialStore.location };

    case RESET_LOCATION:
      logger.trace('RESET_LOCATION');
      return { ...initialStore.location };

    case SET_SEAT:
      logger.trace('SET_SEAT', action);
      return {
        ...state,
        seat: action.seat,
      };

    case SET_POSITION:
      logger.trace('SET_POSITION', action);
      return {
        ...state,
        position: action.position,
      };

    default:
      return state;
  }
}

export default LocationReducer;
