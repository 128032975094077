import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import withApolloClient from '#lib/apollo/withApolloClient';
import { withGoogleAnalytics } from '#lib/GoogleAnalytics';
import withIntermediaClient from '#lib/withIntermediaClient';
import { setId, setUsername } from '#reducers/UserReducer/UserActions';

import withEventId from '../../../providers/EventProvider/withEvent';
import Username from './Username';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setId: (id) => dispatch(setId(id)),
  setUsername: (username) => dispatch(setUsername(username)),
});

const UsernameContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withGoogleAnalytics,
  withTheme,
  withIntermediaClient,
  withApolloClient,
  withEventId,
)(Username);

export default UsernameContainer;
