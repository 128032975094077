export default function extractPartitions(
  midiData,
  note,
) {
  const partition = midiData.reduce((acc, event) => {
    const {
      name,
      noteName,
    } = event;
    if (name === 'Note on' && noteName === note) {
      return [...acc, event];
    }
    return acc;
  }, []);
  return partition;
}
