import styled from 'styled-components';

import { spin } from '../../styles/animations';
import { Text } from '../../styles/styledComponents';

export const LoadingContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15vmin;
  pointer-events: none;
`;

export const LoadingFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  background-color: ${(props) => props.theme.primaryColor};
  border: 0.07em solid ${(props) => props.theme.secondaryColor};
  border-radius: 50%;
`;

export const LoadingIcon = styled(Text)`
  font-size: 25vmin;
  text-align: center;
  animation: ${spin} 3s ease-in-out infinite;
`;

export const Message = styled(Text)`
  text-align: center;
  margin: 25px;
`;
