import { FormattedMessage } from 'react-intl';

import {
  PreAnswerContainer,
  PreAnswerText,
} from '../styles';

function PleaseWait() {
  return (
    <PreAnswerContainer>
      <PreAnswerText><FormattedMessage id="trivia_waiting" /></PreAnswerText>
    </PreAnswerContainer>
  );
}

export default PleaseWait;
