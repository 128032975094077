export const RESET_LOCATION = 'RESET_LOCATION';
export const SET_SEAT = 'SET_SEAT';
export const SET_POSITION = 'SET_POSITION';

export function resetLocation() {
  return { type: RESET_LOCATION };
}

export function setSeat(seat) {
  return { seat, type: SET_SEAT };
}

export function setPosition(position) {
  return { position, type: SET_POSITION };
}
