import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

import { slideInRight, slideOutRight } from '../../styles/animations';

const backgroundImage1 = 'benchmarks/images/profile/bkgLightWave';
const backgroundImage2 = 'benchmarks/images/profile/bkgLightWave2';
const backgroundImage = {
  normal: backgroundImage1,
  rougeOr: backgroundImage2,
};

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 3vmin;
  z-index: 4;
`;

export const NameText = styled.p`
  font-size: 5vmin;
  color: ${(props) => props.theme.primaryColor};
`;

export const BackgroundImage = styled(CloudImage).attrs((props) => ({
  publicId: backgroundImage[props.theme.themeCode],
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const ProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  visibility: ${(props) => (props.opening ? 'hidden' : 'visible')};
  animation:
    ${(props) => (props.opening ? slideInRight : slideOutRight)}
    ${(props) => props.animationDurationMs}ms
    forwards;
  animation-delay: ${(props) => props.animationDelayMs}ms;
`;

export const ProfileTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 7vmin;
  color: ${(props) => props.theme.primaryColor};
`;

export const ProfileBox = styled.div`
  width: 85%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 5vmin;
  padding: 7vmin;
  background-size: 100%, 100% 17vh, 100%;
  background-position: 0 0, 0 20vmin, 0 100%;
  background-repeat: no-repeat;
`;

export const ProfileSection = styled.div`
  width: 100%;
`;

export const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SectionTitle = styled.p`
  font-size: 6vmin;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  margin-bottom: 5vmin;
`;

export const TeamEmblem = styled(CloudImage)`
  max-height: 100%;
  width: auto;
`;

export const EmblemContainer = styled.div`
  display: flex;
  height: 30vmin;
  width: 30vmin;
  background-color: ${(props) => props.theme.primaryColorLight || props.theme.primaryColor};
  border-radius: 30vmin;
  margin-bottom: 4vmin;
  padding: 5vmin;
  justify-content: center;
  align-items: center;
`;

export const LanguageForm = styled.div`
  width: 75%;
  margin-top: 1vmin;
`;

export const TermsLink = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 4vmin;
  color: ${(props) => props.theme.primaryColorLight || props.theme.primaryColor};
  font-weight: bold;
  text-decoration: underline;
`;
