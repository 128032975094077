import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import TransitionEffect from './styles';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  currentGameType: PropTypes.string,
  currentStep: PropTypes.string,
  startShown: PropTypes.bool,
};

const defaultProps = {
  currentGameType: '',
  currentStep: '',
  startShown: false,
};

function Transition({
  children,
  currentGameType,
  currentStep,
  startShown,
}) {
  const [inTransition, setInTransition] = useState(!startShown);
  const [step, setStep] = useState('');
  useEffect(() => {
    let timeout;
    setInTransition(true);
    timeout = setTimeout(() => {
      timeout = setTimeout(() => {
        setInTransition(false);
        setStep(currentStep);
      }, 200);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentStep, currentGameType]);
  return (
    <TransitionEffect inTransition={inTransition}>
      {children(step)}
    </TransitionEffect>
  );
}

Transition.propTypes = propTypes;
Transition.defaultProps = defaultProps;

export default Transition;
