import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import BlockedOrientation from '../../../components/BlockedOrientation/BlockedOrientation';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Logger from '../../../lib/Logger';
import { Title } from '../../../styles/styledComponents';
import {
  CheckContainer,
  ImageCol,
  Loading,
  PermissionCheck,
  PermissionContainer,
  PermissionError,
  PermissionForm,
  PermissionMessages,
  PermissionsContainer,
  PermissionText,
  PermissionTextContainer,
  PermissionTitle,
  PermissionWarn,
  SVGImage,
} from './styles';

const LocationSVG = 'benchmarks/images/onboarding/permissions/permissionLocation';

const propTypes = {
  analytics: PropTypes.shape(),
  dispatch: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    geolocation: PropTypes.bool.isRequired,
    mediaCapture: PropTypes.bool.isRequired,
  }).isRequired,
  setPermissions: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  analytics: undefined,
  title: undefined,
};

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);

    this.state = {
      geolocAccepted: undefined,
    };
    this.close = this.close.bind(this);
    this.requestGeolocation = this.requestGeolocation.bind(this);
  }

  close() {
    this.logger.trace('close()');
    const {
      analytics,
      dispatch,
      onNext,
      setPermissions,
    } = this.props;
    const { geolocAccepted } = this.state;
    if (geolocAccepted !== undefined) {
      setPermissions({
        geolocation: geolocAccepted,
      });
      if (analytics) {
        analytics.logEvent('geoloc_permission', { enabled: geolocAccepted });
      }
      /**
       * Redirect to onboarding in case it's not completed
       * Onboarding will redirect to hub if completed
       */
      if (onNext) {
        onNext();
      } else {
        dispatch(push('/orchestration'));
      }
    } else {
      this.setState({ geolocError: true });
    }
  }

  async requestGeolocation() {
    this.setState({ loading: true });
    await navigator.geolocation.getCurrentPosition(
      () => {
        this.setState({
          geolocAccepted: true,
          geolocError: false,
          loading: false,
        });
      },
      () => {
        this.setState({
          geolocAccepted: false,
          geolocError: false,
          loading: false,
        });
      },
    );
  }

  render() {
    const {
      geolocAccepted,
      geolocError,
      loading,
    } = this.state;
    const { theme, title } = this.props;

    return (
      <>
        <PermissionsContainer>
          <Title><FormattedMessage id={title || 'permissions_title'} /></Title>
          <PermissionContainer>
            <ImageCol xs={6}>
              <SVGImage
                alt="location"
                publicId={`${LocationSVG}${theme.cloudExtension}`}
              />
            </ImageCol>
            <PermissionTextContainer xs={6}>
              <PermissionTitle><FormattedMessage id="permission_geolocation_title" /></PermissionTitle>
              <PermissionText><FormattedMessage id="permission_geolocation_text" /></PermissionText>
              <PermissionForm>
                <CheckContainer>
                  <PermissionCheck
                    checked={geolocAccepted === true}
                    onChange={this.requestGeolocation}
                    type="radio"
                  />
                  <PermissionText>
                    <FormattedMessage id="permissions_enable_geoloc" />
                    {loading && <Loading><Spinner animation="border" /></Loading>}
                  </PermissionText>
                </CheckContainer>
                <CheckContainer>
                  <PermissionCheck
                    checked={geolocAccepted === false}
                    onChange={() => {
                      this.setState({ geolocAccepted: false, geolocError: false, loading: false });
                    }}
                    type="radio"
                  />
                  <PermissionText><FormattedMessage id="permissions_deny_geoloc" /></PermissionText>
                </CheckContainer>
              </PermissionForm>
            </PermissionTextContainer>
          </PermissionContainer>
          <PermissionMessages>
            {geolocError && <PermissionError><FormattedMessage id="permissions_error_geoloc" /></PermissionError>}
            {geolocAccepted === false && <PermissionWarn><FormattedMessage id="permissions_warn_geoloc" /></PermissionWarn>}
          </PermissionMessages>
          <CustomButton themeCode={theme && theme.themeCode} onClick={this.close} id="nextButton">
            <FormattedMessage id="navigation_continue" />
          </CustomButton>
        </PermissionsContainer>
        <BlockedOrientation orientation="landscape" />
      </>
    );
  }
}

Permissions.propTypes = propTypes;
Permissions.defaultProps = defaultProps;

export default withTheme(Permissions);
