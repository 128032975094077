import PropTypes from 'prop-types';

import {
  LeaderboardText,
  RankColumn,
  RankText,
  Row,
  ScoreColumn,
  ScoreText,
  UsernameColumn,
} from './styles';

const propTypes = {
  index: PropTypes.number.isRequired,
  scoreEntry: PropTypes.shape().isRequired,
};

function Score(props) {
  const { index, scoreEntry } = props;

  return (
    <Row index={index}>
      <RankColumn>
        <RankText>{index + 1}</RankText>
      </RankColumn>
      <UsernameColumn>
        <LeaderboardText>
          {scoreEntry.user.username}
        </LeaderboardText>
      </UsernameColumn>
      <ScoreColumn>
        <ScoreText>
          {Math.round(scoreEntry.score) || 0}
        </ScoreText>
      </ScoreColumn>
    </Row>
  );
}

Score.propTypes = propTypes;

export default Score;
