import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import i18n from '#lib/i18n/i18n';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function AppIntlProvider({ children }) {
  const language = useSelector((state) => state.config.language);

  return (
    <IntlProvider locale={language} messages={i18n[language]}>
      {children}
    </IntlProvider>
  );
}

AppIntlProvider.propTypes = propTypes;

export default AppIntlProvider;
