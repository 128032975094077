import { connect } from 'react-redux';

import LightShow from './LightShow';

const mapStateToProps = (state) => ({
  location: state.location,
  permissions: state.config.permissions,
  user: state.user,
});

const LightShowContainer = connect(
  mapStateToProps,
)(LightShow);

export default LightShowContainer;
