import PropTypes from 'prop-types';
import { Component } from 'react';

import { Touchable } from './styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  inputType: PropTypes.string,
  onInput: PropTypes.func.isRequired,
};

const defaultProps = {
  inputType: 'touch',
};

class GameInput extends Component {
  constructor(props) {
    super(props);
    this.detectMotion = this.detectMotion.bind(this);

    this.buffer = [];
    this.cooldown = false;
  }

  componentDidMount() {
    const { inputType } = this.props;
    if (inputType === 'shake') {
      window.addEventListener('devicemotion', this.detectMotion, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('devicemotion', this.detectMotion, true);
    clearTimeout(this.cooldownTimeout);
  }

  detectMotion(evt) {
    if (!this.cooldown) {
      const { onInput } = this.props;
      const { acceleration: accel } = evt;
      const accelValue = Math.abs(accel.x) + Math.abs(accel.y) + Math.abs(accel.z);
      this.buffer.push(accelValue);
      if (this.buffer.length > 50) {
        this.buffer.shift();
      }
      const delta = this.buffer.reduce(
        (acc, value, index, array) => {
          if (index < array.length - 1) {
            return acc + ((array[index + 1] - value) / array.length);
          }
          return acc;
        },
        0,
      );
      if (delta > 0.8 && !this.cooldown) {
        onInput();
        this.cooldown = true;
        this.cooldownTimeout = setTimeout(() => { this.cooldown = false; }, 100);
      }
    }
  }

  render() {
    const { children, inputType, onInput } = this.props;

    return (
      <>
        {inputType === 'touch' && (
          <Touchable onClick={onInput}>
            {children}
          </Touchable>
        )}
        {inputType === 'shake' && children}
      </>
    );
  }
}

GameInput.propTypes = propTypes;
GameInput.defaultProps = defaultProps;

export default GameInput;
