import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  color: whitesmoke;
  text-align: center;
`;

export const IconContainer = styled.div`
  font-size: 25vmin;
  color: whitesmoke;
`;
