import { useMutation } from '@apollo/client';
import { useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { UPDATE_SCORE } from '#lib/apollo/mutations';

import CountdownHeader from '../../../components/CountdownHeader/CountdownHeaderContainer';
import Loading from '../../../components/Loading/Loading';
import PreAnswer from '../../../components/PreAnswer/PreAnswer';
import isLoadTest from '../../../lib/isLoadTest';
import { TriviaContainer } from '../styles';
import Question from './Question';
import Results from './Results';
import Waiting from './Waiting';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  alreadyJoined: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  setScore: PropTypes.func.isRequired,
  setTriviaJoined: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

function TriviaScreen({
  activityId,
  alreadyJoined,
  currentQuestion: storedCurrentQuestion,
  user,
  setCurrentQuestion,
  setScore,
  setTriviaJoined,
}) {
  const {
    id,
    score,
    username,
  } = user;
  const { trivia: triviaScore } = score;
  const { data, loading, error } = useSubscription('trivia');
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [answerTime, setAnswerTime] = useState(null);

  const [updateScore] = useMutation(
    UPDATE_SCORE, {
      variables: {
        activityId,
        score: 0,
        userId: id,
      },
    },
  );

  useEffect(() => {
    if (data) {
      const { triviaInfo } = data;
      const { questionsCompleted } = triviaInfo;
      if (currentQuestionIndex !== questionsCompleted) {
        setCurrentQuestionIndex(questionsCompleted);
        setSelectedAnswer(null);
      }
    }
  }, [currentQuestionIndex, data, id, setScore, updateScore]);

  useEffect(() => {
    if (data && currentQuestionIndex !== null && currentQuestionIndex >= 0) {
      const { triviaInfo } = data;
      const { questionsCompleted, status } = triviaInfo;
      if (!alreadyJoined && storedCurrentQuestion !== currentQuestionIndex && !['answering', 'results'].includes(status)) {
        setTriviaJoined(true);
        setCurrentQuestion(questionsCompleted);
      } else if (alreadyJoined && storedCurrentQuestion !== currentQuestionIndex) {
        setTriviaJoined(false);
      }
    }
  }, [
    alreadyJoined,
    currentQuestionIndex,
    setCurrentQuestion,
    storedCurrentQuestion,
    data,
    setTriviaJoined,
  ]);

  const answerQuestion = useCallback((answerId, timeTaken) => {
    if (data) {
      const { currentQuestion } = data;
      const { correctAnswer, duration } = currentQuestion;

      setSelectedAnswer(answerId);

      const isCorrectAnswer = answerId === correctAnswer;
      setAnswerTime(timeTaken);
      const timeLeft = duration - timeTaken;
      const currentQuestionScore = isCorrectAnswer && timeLeft > 0
        ? parseInt(timeLeft / 100, 10)
        : 0;
      const newScore = currentQuestionScore + triviaScore;

      const addScoreTimeout = setTimeout(() => {
        clearTimeout(addScoreTimeout);
        setScore(newScore);
        updateScore({
          variables: {
            score: newScore,
          },
        });
      }, timeLeft);
    }
  }, [data, setScore, triviaScore, updateScore]);

  useEffect(() => {
    let loadTestTimeout;
    if (data) {
      const { currentQuestion, triviaInfo } = data;

      if (isLoadTest() && triviaInfo.status === 'answering' && !selectedAnswer) {
        const { answers } = currentQuestion;
        const chosenAnswer = answers[Math.floor(Math.random() * 4)].id;
        const answerTimeFake = Math.floor(Math.random() * 15000);
        loadTestTimeout = setTimeout(() => {
          setSelectedAnswer(chosenAnswer);
          answerQuestion(chosenAnswer, answerTimeFake);
        }, answerTimeFake);
      }
    }
    return () => {
      clearTimeout(loadTestTimeout);
    };
  }, [currentQuestionIndex, answerQuestion, data, selectedAnswer]);

  if (loading || error) return <Loading />;
  const { currentQuestion, triviaInfo } = data;
  const { correctAnswer, duration } = currentQuestion;

  if (triviaInfo.status === 'preAnswer') {
    return (
      <PreAnswer
        currentItem={triviaInfo.questionsCompleted}
        numberOfItems={triviaInfo.numberOfQuestions}
      />
    );
  }
  if (['answering', 'results'].includes(triviaInfo.status) && !alreadyJoined) return <Waiting />;
  return (
    <TriviaContainer>
      <CountdownHeader
        currentQuestionIndex={currentQuestionIndex}
        duration={duration}
        score={triviaScore}
        username={username}
      />
      {
        triviaInfo.status !== 'answering'
        && (
          <Results
            correctAnswer={correctAnswer}
            selectedAnswer={selectedAnswer}
            answerTime={answerTime}
            setAnswerTime={setAnswerTime}
          />
        )
      }
      <Question
        currentQuestion={currentQuestion}
        setSelectedAnswer={answerQuestion}
        selectedAnswer={selectedAnswer}
        showDetails
        triviaInfo={triviaInfo}
      />
    </TriviaContainer>
  );
}

TriviaScreen.propTypes = propTypes;

export default TriviaScreen;
