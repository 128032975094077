import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBat,
  faCat,
  faCrow,
  faDeer,
  faDog,
  faDuck,
  faElephant,
  faFish,
  faFrog,
  faHippo,
  faHorse,
  faMonkey,
  faPig,
  faRabbit,
  faRam,
  faSnake,
  faSpider,
  faSquirrel,
  faTurtle,
  faWhale,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

library.add([
  faCat,
  faCrow,
  faRam,
  faElephant,
  faDog,
  faFish,
  faFrog,
  faHippo,
  faHorse,
  faTurtle,
  faWhale,
  faRabbit,
  faPig,
  faMonkey,
  faDuck,
  faDeer,
  faSnake,
  faSpider,
  faSquirrel,
  faBat,
]);

const teamIcons = [
  'cat',
  'crow',
  'ram',
  'elephant',
  'dog',
  'fish',
  'frog',
  'hippo',
  'horse',
  'turtle',
  'whale',
  'rabbit',
  'pig',
  'monkey',
  'duck',
  'deer',
  'snake',
  'spider',
  'squirrel',
  'bat',
];

export const Teams = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const Team = styled.div`
  width: 50%;
  display: flex;
  padding: 3%;
`;

export const TeamImage = styled(CloudImage)`
  filter: drop-shadow(0 8px 0 rgba(0, 0, 0, 0.3));
  max-width: 35vw;
  max-height: 35vw;
`;

export const TeamImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
`;

export const TeamName = styled.div`
  width: 40vw;
  font-size: 2vmax;
  font-weight: bold;
  text-align: center;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TeamIconContainerPadding = styled.div`
  padding: 5%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamIconContainer = styled.div`
  width: 35vw;
  height: 35vw;
  background: #dad9ef;
  border-radius: 50%;
  filter: drop-shadow(0 8px 0 rgba(0, 0, 0, 0.3));
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: teamIcons[props.iconindex],
}))`
  color: #6a697e;
  display: flex;
`;

export const TeamButton = styled.button`
  background-color: ${(props) => (`${props.color}77` || '#000')};
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  border: ${(props) => `3px solid ${props.color}`};
  outline: none;
  opacity: ${(props) => ((props.selectedTeam && !props.isSelected) ? 0.5 : 1)};
  transition: opacity 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :focus {
    box-shadow: none;
    outline: 0;
  }
`;
