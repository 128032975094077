import PropTypes from 'prop-types';

import { ButtonContainer, IconContainer } from './styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  hidden: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  fontSize: null,
  height: null,
  hidden: false,
  icon: null,
  id: null,
  width: null,
};

function DarkButton(props) {
  const {
    children,
    disabled,
    fontSize,
    height,
    hidden,
    icon,
    id,
    onClick,
    width,
  } = props;

  return (
    <ButtonContainer
      disabled={disabled}
      fontSize={fontSize}
      height={height}
      hidden={hidden}
      id={id}
      onClick={!disabled ? onClick : undefined}
      width={width}
    >
      {icon && <IconContainer icon={icon} />}
      <p>
        {children}
      </p>
    </ButtonContainer>
  );
}

DarkButton.propTypes = propTypes;
DarkButton.defaultProps = defaultProps;

export default DarkButton;
