import styled from 'styled-components';

export const Select = styled.select`
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.fontSize};
  color: black;
  background-color: transparent;
  border: none;
  appearance: none;
  text-align: center;
  text-align-last: center;
  padding-right: 4vw;

  :focus {
    outline: none;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height || '11vmin'};
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
`;

export const TriangleDown = styled.div`
  position: absolute;
  right: 4vw;
  border-left: 2.5vmin solid transparent;
  border-right: 2.5vmin solid transparent;
  border-top: 2.5vmin solid ${(props) => props.theme.secondaryColor};
  animation: ${(props) => (props.focus ? 'Rotate 0.5s ease forwards' : 'RotateInverse 0.5s ease forwards')};

  @keyframes Rotate {

    from { transform: rotate(0deg); }

    to { transform: rotate(90deg); }
  }

  @keyframes RotateInverse {

    from { transform: rotate(90deg); }

    to { transform: rotate(0deg); }
  }
`;
