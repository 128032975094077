import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SONG_STATUS from '#lib/constants/SongStatus';

import MidiVisualizer from '../MidiVisualiser';
import { AbsoluteFillObject, BeginMessage, CenterContainer } from './styles';

const defaultProps = {
  midiInfo: undefined,
};

const propTypes = {
  audioPlayerRef: PropTypes.shape().isRequired,
  direction: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  midiInfo: PropTypes.shape(),
  onAudioEnds: PropTypes.func.isRequired,
  onAudioStart: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  songStatus: PropTypes.string.isRequired,
  teamInfos: PropTypes.shape().isRequired,
};

function IntegratedGame(props) {
  const {
    audioPlayerRef,
    midiInfo,
    direction,
    playing,
    teamInfos,
    onAudioStart,
    onAudioEnds,
    file,
    songStatus,
  } = props;

  return (
    <>
      <audio
        preload="auto"
        ref={audioPlayerRef}
        onPlay={onAudioStart}
        onEnded={onAudioEnds}
        onPause={() => {}}
        autoPlay
        controls={false}
        muted={false}
        src={file}
      >
        <track kind="captions" />
      </audio>
      {songStatus === SONG_STATUS.NOT_STARTED && (
        <AbsoluteFillObject>
          <CenterContainer>
            <BeginMessage>
              <FormattedMessage id="click_to_start" />
            </BeginMessage>
          </CenterContainer>
        </AbsoluteFillObject>
      )}
      {songStatus === SONG_STATUS.FINISHED && (
        <AbsoluteFillObject>
          <CenterContainer>
            <BeginMessage>
              <FormattedMessage id="waiting_for_score" />
            </BeginMessage>
          </CenterContainer>
        </AbsoluteFillObject>
      )}
      {midiInfo && (
        <MidiVisualizer
          audioPlayer={audioPlayerRef}
          teamInfos={teamInfos}
          direction={direction}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...midiInfo}
          playing={playing}
        />
      )}
    </>
  );
}

IntegratedGame.propTypes = propTypes;
IntegratedGame.defaultProps = defaultProps;

export default IntegratedGame;
