import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_SCORE } from '../../../../lib/apollo/mutations';
import IntegratedGameLogic from './IntegratedGameLogic';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  song: PropTypes.shape().isRequired,
  team: PropTypes.string.isRequired,
  teamInfos: PropTypes.shape().isRequired,
};

function IntegratedGameHooks(props) {
  const {
    activityId,
    currentStep,
    song,
    team,
    inputType,
    teamInfos,
  } = props;

  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();

  const [setScore] = useMutation(
    UPDATE_SCORE, {
      variables: {
        activityId,
        score: 0,
        userId,
      },
    },
  );

  return (
    <IntegratedGameLogic
      currentStep={currentStep}
      dispatch={dispatch}
      inputType={inputType}
      setScore={setScore}
      song={song}
      team={team}
      teamInfos={teamInfos}
    />
  );
}

IntegratedGameHooks.propTypes = propTypes;

export default IntegratedGameHooks;
