import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { LILAC } from '../../styles/colors';

export const HeaderContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderCol = styled(Col).attrs({
  xs: 8,
})`
  display: flex;
  flex-flow: wrap row;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.5em;
`;

export const Username = styled.p`
  color: ${LILAC};
  font-weight: normal;
  font-size: 1em;
  text-transform: uppercase;
`;
