import { useEffect, useRef } from 'react';

import { ConfettisCanvas } from '../styles';

function renderConfettis(canvasRef) {
  const canvas = canvasRef.current;
  let context;
  const maxConfettis = 50;
  const particles = [];
  let H = window.innerHeight;
  let W = window.innerWidth;

  if (canvas) {
    context = canvas.getContext('2d');
  }

  const possibleColors = [
    'DodgerBlue',
    'OliveDrab',
    'Gold',
    'Pink',
    'SlateBlue',
    'LightBlue',
    'Gold',
    'Violet',
    'PaleGreen',
    'SteelBlue',
    'SandyBrown',
    'Chocolate',
    'Crimson',
  ];

  function randomFromTo(from, to) {
    return Math.floor((Math.random() * ((to - from) + 1)) + from);
  }

  function ConfettiParticle() {
    this.d = (Math.random() * maxConfettis) + 11;
    this.r = randomFromTo(11, 22); // radius
    this.x = Math.random() * W; // x
    this.y = (Math.random() * H) - H; // y

    this.color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
    this.tilt = Math.floor(Math.random() * 22) - 11;
    this.tiltAngle = 0;
    this.tiltAngleIncremental = (Math.random() * 0.07) + 0.05;

    this.draw = () => {
      context.beginPath();
      context.lineWidth = this.r / 2;
      context.strokeStyle = this.color;
      context.moveTo(this.x + this.tilt + (this.r / 3), this.y);
      context.lineTo(this.x + this.tilt, this.y + this.tilt + (this.r / 5));
      return context.stroke();
    };
  }

  function Draw() {
    const results = [];

    requestAnimationFrame(Draw);

    context.clearRect(0, 0, W, window.innerHeight);

    for (let i = 0; i < maxConfettis; i += 1) {
      results.push(particles[i].draw());
    }

    let particle = {};
    for (let i = 0; i < maxConfettis; i += 1) {
      particle = particles[i];

      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(particle.d) + 3 + (particle.r / 2)) / 2;
      particle.tilt = Math.sin(particle.tiltAngle - (i / 3)) * 15;

      // If a confetti has fluttered out of view,
      // bring it back to above the viewport and let if re-fall.
      if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
        particle.x = Math.random() * W;
        particle.y = -30;
        particle.tilt = Math.floor(Math.random() * 10) - 20;
      }
    }

    return results;
  }

  window.addEventListener(
    'resize',
    () => {
      canvas.height = window.innerHeight;
      canvas.width = window.innerWidth;
      W = window.innerWidth;
      H = window.innerHeight;
    },
    false,
  );

  // Push new confetti objects to `particles[]`
  for (let i = 0; i < maxConfettis; i += 1) {
    particles.push(new ConfettiParticle());
  }

  // Initialize
  if (canvas) {
    canvas.width = W;
    canvas.height = H;
    Draw();
  }
}

function Confettis() {
  const canvasRef = useRef(null);
  useEffect(() => { renderConfettis(canvasRef); }, [canvasRef]);

  return <ConfettisCanvas ref={canvasRef} />;
}

export default Confettis;
