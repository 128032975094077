import { useMutation } from '@apollo/client';
import { useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import {
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import { JOIN_TEAM } from '#lib/apollo/mutations';

import CustomButton from '../CustomButton/CustomButton';
import Loading from '../Loading/Loading';
import {
  MainContainer,
  NextButtonContainer,
  PageText,
  PageTitle,
  TextContainer,
} from './styles';

const propTypes = {
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setTeam: PropTypes.func.isRequired,
  team: PropTypes.string,
  teamMode: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    themeCode: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  team: undefined,
};

function TeamSelectionFrame({
  children,
  activityId,
  activityType,
  setTeam,
  team,
  theme,
  user,
  teamMode,
}) {
  const [joinTeam] = useMutation(JOIN_TEAM);
  const [selectedTeam, setSelectedTeam] = useState(team);
  const { data, loading, error } = useSubscription(activityType);
  const handleTeamSelection = useCallback((teamName) => setSelectedTeam(teamName), []);
  const handleTeamConfirm = useCallback(async () => {
    const { id } = user;
    await joinTeam({ variables: { activityId, team: selectedTeam, userId: id } });
    setTeam(selectedTeam);
  }, [setTeam, joinTeam, activityId, selectedTeam, user]);

  useEffect(() => {
    if (!team && data && teamMode === 'automatic') {
      const { teams } = data;
      const keys = Object.keys(teams);
      const randomTeam = keys[Math.floor(keys.length * Math.random())];
      handleTeamSelection(randomTeam);
      handleTeamConfirm();
    }
  }, [data, handleTeamConfirm, team, teamMode, handleTeamSelection]);

  if (loading || error || teamMode === 'automatic') return <Loading />;

  const { teams } = data;

  return (
    <MainContainer usePercents>
      <TextContainer>
        {team
          ? (
            <PageTitle>
              <FormattedMessage id="team_selection_found" values={{ name: team }} />
            </PageTitle>
          )
          : (
            <>
              <PageTitle><FormattedMessage id="team_selection_title" /></PageTitle>
              <PageText><FormattedMessage id="team_selection_text" /></PageText>
            </>
          )}
      </TextContainer>
      {typeof children === 'function' && children({
        onTeamSelection: handleTeamSelection,
        selectedTeam,
        teams,
      })}
      {typeof children === 'object' && cloneElement(children, {
        onTeamSelection: handleTeamSelection,
        selectedTeam,
        teams,
      })}
      <NextButtonContainer>
        <CustomButton
          disabled={selectedTeam === ''}
          onClick={handleTeamConfirm}
          id="confirmButton"
          themeCode={theme && theme.themeCode}
        >
          <FormattedMessage id="team_selection_confirm" />
        </CustomButton>
      </NextButtonContainer>
    </MainContainer>
  );
}

TeamSelectionFrame.propTypes = propTypes;
TeamSelectionFrame.defaultProps = defaultProps;

export default withTheme(TeamSelectionFrame);
