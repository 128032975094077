import images from './assets/images';

function startingPoint(width, height) {
  return { x: width / 2 - 94.5, y: height / 4.5 };
}

function drawBackground(ctx, width, height) {
  ctx.save();
  ctx.fillStyle = '#542EAB';
  ctx.fillRect(0, 0, width, height);
  ctx.restore();
}

function drawImage(image, ctx, { x, y }) {
  ctx.drawImage(image, x, y);
}

function getTranslation({ x: sx, y: sy }, { x, y }, elapsed = 1, time = 1) {
  if (elapsed >= time) {
    return { x, y };
  }
  const totalTranslationX = x - sx;
  const totalTranslationY = y - sy;
  return {
    x: sx + (elapsed * totalTranslationX) / time,
    y: sy + (elapsed * totalTranslationY) / time,
  };
}

function drawPutYourHandsUp(ctx, width, height, elapsed = 1) {
  const {
    put,
    your,
    phones,
    up,
  } = images;
  const { x, y } = startingPoint(width, height);
  ctx.save();
  drawImage(put, ctx, getTranslation({ x: -200, y }, { x, y }, elapsed, 200));
  drawImage(your, ctx, getTranslation(
    { x: width + 100, y: y + 50 },
    { x: x + 165, y: y + 50 },
    elapsed - 200,
    200,
  ));
  drawImage(phones, ctx, getTranslation(
    { x: -200, y: y + 100 },
    { x: x - 60, y: y + 100 },
    elapsed - 400,
    200,
  ));
  drawImage(up, ctx, getTranslation(
    { x: width + 100, y: y + 90 },
    { x: x + 190, y: y + 90 },
    elapsed - 600,
    200,
  ));
  ctx.restore();
}

function drawArms(ctx, width, height, elapsed = 1) {
  const {
    arm1,
    arm2,
    arm3,
    arm4,
  } = images;
  const { x, y } = startingPoint(width, height);
  ctx.save();
  drawImage(arm1, ctx, getTranslation(
    { x: x - 340, y: height + 100 },
    { x: x - 340, y: y + 70 },
    elapsed - 1000,
    300,
  ));
  drawImage(arm2, ctx, getTranslation(
    { x: x - 230, y: height + 100 },
    { x: x - 230, y: y + 50 },
    elapsed - 1200,
    250,
  ));
  drawImage(arm3, ctx, getTranslation(
    { x: x + 350, y: height + 100 },
    { x: x + 350, y: y + 40 },
    elapsed - 1050,
    270,
  ));
  drawImage(arm4, ctx, getTranslation(
    { x: x + 500, y: height + 100 },
    { x: x + 500, y: y + 20 },
    elapsed - 1250,
    320,
  ));
  ctx.restore();
}

export default function handsUp(canvas, elapsed = 1) {
  const { height, width } = canvas;
  const ctx = canvas.getContext('2d');
  ctx.save();
  drawBackground(ctx, width, height);
  drawPutYourHandsUp(ctx, width, height, elapsed);
  drawArms(ctx, width, height, elapsed);
  ctx.restore();
}
