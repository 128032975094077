export const CLEAR_TIMER = 'CLEAR_TIMER';
export const SET_TIMER_ID = 'SET_TIMER_ID';
export const START_TIMER = 'START_TIMER';
export const SET_TIME_ELAPSED = 'SET_TIME_ELAPSED';
export const RESTART_TIMER = 'RESTART_TIMER';

export function startTimer(timeStarted, duration) {
  return ({ duration, timeStarted, type: START_TIMER });
}

export function setTimeElapsed(timeElapsed) {
  return ({ timeElapsed, type: SET_TIME_ELAPSED });
}

export function restartTimer(now) {
  return ({ now, type: RESTART_TIMER });
}

export function clearTimer() {
  return ({ type: CLEAR_TIMER });
}

export function setTimerID(id) {
  return ({ id, type: SET_TIMER_ID });
}
