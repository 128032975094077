import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { scheduleToDivArray } from './scheduleConversion';
import { FramesContainer } from './styles';

const propTypes = {
  pixel: PropTypes.shape().isRequired,
  serverOffset: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
};

function IOSEffectDisplay({ pixel, serverOffset, startTime }) {
  const frameRefs = useRef({ refs: [] });
  const [divFrames, setDivFrames] = useState([]);
  const { schedule } = pixel;

  useEffect(() => {
    const timeLeft = startTime - (+moment.utc() + serverOffset);
    setDivFrames(scheduleToDivArray(
      schedule,
      (refs) => { frameRefs.current.refs = refs; },
      timeLeft,
    ));
  }, [schedule, serverOffset, startTime]);

  return (
    <FramesContainer>
      {divFrames}
    </FramesContainer>
  );
}

IOSEffectDisplay.propTypes = propTypes;

export default IOSEffectDisplay;
