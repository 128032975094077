export const AQUA = '#00f7e3';
export const AQUA_LIGHT = '#95fff6';
export const BEIGE = '#e6e5e0';
export const BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.5)';
export const BLUE_PURPLE = '#5148ce';
export const BLUE = '#1a1d61';
export const DEFAULT_BACKGROUND = '#0c0e23';
export const FUSHIA = '#bb1069';
export const GRASS = '#15b78f';
export const GREEN = '#20d8a6';
export const GREEN_TWO = '#389C27';
export const GREEN_PERSIAN = '#02b0a2';
export const GREEN_PURE = '#00ff00';
export const GREY = '#deddf0';
export const GREY_LIGHT = 'lightGray';
export const LILAC = '#bab8e6';
export const NAVY = '#18135e';
export const NAVY_TRANSPARENT = 'rgba(28, 25, 45, 0.8)';
export const PURPLE = '#5a4196';
export const PURPLE_DARK = '#211d37';
export const PURPLE_LIGHT = '#9c50ff';
export const RED = '#fb466f';
export const RED_PURE = '#ff0000';
export const STEEL = '#314054';
export const STEEL_DARK = '#2a3149';
export const STEEL_LIGHT = '#3a6c7f';
export const SKY_DARK = '#21313e';
export const TAUPE = '#93928c';
export const TEAL = '#3eb8ae';
export const TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const TURQUOISE = '#aaf5fa';
export const DARK_TURQUOISE = '#22938C';
export const WHITE = 'white';
export const WHITE_TRANSPARENT = 'rgba(255, 255, 255, 0.5)';
export const YELLOW = '#f6b103';
export const UNI_GOLD = '#ffc903';
export const UNI_RED = '#fa2f4c';
export const BASS = '';
export const DRUMS = '#fa2f4c';
export const GUITAR = '';
export const MICRO = '#ffc903';
export const SYNTH = '';
export const TRUMPET = '#9046ff';
