import { Transformation } from 'cloudinary-react';
import PropTypes from 'prop-types';

import TeamSelectionFrame from '../../../components/TeamSelectionFrame/TeamSelectionFrameContainer';
import Logger from '../../../lib/Logger';
import {
  Team,
  TeamButton,
  TeamIcon,
  TeamIconContainer,
  TeamImage,
  TeamImageWrapper,
  TeamName,
  Teams,
} from './styles';

const logger = Logger.getLogger('TeamSelection');

const propTypes = {
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  setTeam: PropTypes.func.isRequired,
  team: PropTypes.string,
  teamMode: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  team: undefined,
};

function TeamSelection({
  activityId,
  team,
  user,
  setTeam,
  activityType,
  teamMode,
}) {
  logger.trace('render');
  return (
    <TeamSelectionFrame
      activityId={activityId}
      activityType={activityType}
      setTeam={setTeam}
      team={team}
      user={user}
      teamMode={teamMode}
    >
      {({ teams, onTeamSelection, selectedTeam }) => (
        <Teams>
          {Object.keys(teams).map((teamName, index) => (
            <Team
              key={teamName}
            >
              <TeamButton
                color={teams[teamName].color}
                onClick={() => {
                  onTeamSelection(teamName);
                }}
                isSelected={selectedTeam === teamName}
                selectedTeam={selectedTeam}
              >
                <TeamImageWrapper>
                  {teams[teamName].image
                    ? (
                      <TeamImage
                        publicId={teams[teamName].image.match(/[^/]*$/)[0]}
                        altId="e17fd23071e0a72496e5637f7ea95832.jpg"
                        crop="thumb"
                        width={250}
                        height={250}
                      >
                        <Transformation radius="max" />
                      </TeamImage>
                    )
                    : (
                      <TeamIconContainer>
                        <TeamIcon iconindex={index} size="4x" />
                      </TeamIconContainer>
                    )}
                </TeamImageWrapper>
                <TeamName>
                  {teamName}
                </TeamName>
              </TeamButton>
            </Team>
          ))}
        </Teams>
      )}
    </TeamSelectionFrame>
  );
}

TeamSelection.propTypes = propTypes;
TeamSelection.defaultProps = defaultProps;

export default TeamSelection;
