import { createElement, useContext } from 'react';

import GoogleAnalyticsContext from './GoogleAnalyticsContext';

function withGoogleAnalytics(WrappedComponent) {
  return (props) => {
    const analytics = useContext(GoogleAnalyticsContext);
    return createElement(WrappedComponent, { ...props, analytics });
  };
}

export default withGoogleAnalytics;
