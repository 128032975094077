import PropTypes from 'prop-types';
import { createElement } from 'react';

import DarkButton from '../DarkButton/DarkButton';
import NormalButton from '../NormalButton/NormalButton';

const propTypes = {
  type: PropTypes.string,
};

function CustomButton(props) {
  const { type } = props;
  if (type === 'dark') {
    return createElement(DarkButton, props);
  }
  return createElement(NormalButton, props);
}

CustomButton.propTypes = propTypes;
CustomButton.defaultProps = {
  type: '',
};

export default CustomButton;
