import { goBack as back } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import BlockedOrientation from '../../components/BlockedOrientation/BlockedOrientation';
import CustomBack from '../../components/CustomBack/CustomBack';
import { FullScreenContainer } from '../../styles/styledComponents';
import data from './data.json';
import {
  Container,
  Header,
} from './styles';
import Term from './Term';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const ANIMATION_DURATION_MS = 500;
const BUTTON_DURATION_MS = 250;
const EXIT_DURATION_MS = ANIMATION_DURATION_MS + BUTTON_DURATION_MS;

function TermsAndConditions({ dispatch }) {
  const [opening, setOpening] = useState(true);

  let timeoutHandle;
  const goBack = () => {
    if (opening) {
      timeoutHandle = setTimeout(() => { // give time for the exit animation
        dispatch(back());
      }, EXIT_DURATION_MS);
      setOpening(false);
    }
  };
  useEffect(() => (() => { clearTimeout(timeoutHandle); }), [timeoutHandle]);

  return (
    <FullScreenContainer usePercents>
      <Container
        animationDelayMs={opening ? 250 : BUTTON_DURATION_MS}
        animationDurationMs={ANIMATION_DURATION_MS}
        opening={opening}
      >
        <Header>
          <CustomBack onClick={goBack} id="backButton" />
        </Header>
        {data.map((term) => (
          <Term key={term.title} data={term} />
        ))}
        <BlockedOrientation orientation="landscape" />
      </Container>
    </FullScreenContainer>
  );
}

TermsAndConditions.propTypes = propTypes;

export default TermsAndConditions;
