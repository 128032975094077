import PropTypes from 'prop-types';

import { ButtonContainer } from '../../styles/styledComponents';
import { IconContainer } from './styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  hidden: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  width: PropTypes.string,
};

const defaultProps = {
  className: '',
  disabled: false,
  fontSize: null,
  height: null,
  hidden: false,
  icon: null,
  id: null,
  style: {},
  width: null,
};

function NormalButton(props) {
  const {
    children,
    className,
    disabled,
    fontSize,
    height,
    hidden,
    icon,
    id,
    onClick,
    style,
    width,
  } = props;

  return (
    <ButtonContainer
      className={className}
      disabled={disabled}
      fontSize={fontSize}
      height={height}
      hidden={hidden}
      id={id}
      onClick={!disabled ? onClick : undefined}
      style={style}
      width={width}
    >
      {icon && <IconContainer icon={icon} />}
      <p>
        {children}
      </p>
    </ButtonContainer>
  );
}

NormalButton.propTypes = propTypes;
NormalButton.defaultProps = defaultProps;

export default NormalButton;
