import { useContext, useState } from 'react';
import CountUp from 'react-countup';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import BlockedOrientation from '../../../components/BlockedOrientation/BlockedOrientation';
import EventContext from '../../../providers/EventProvider/EventContext';
import {
  Crowd,
  CrowdShadow,
  ResultText,
  ResultTitle,
  Score,
} from './styles';

function Result() {
  const [countUpFinished, setCountUpFinished] = useState(false);
  const { isIntegrated } = useContext(EventContext);
  const {
    score: { music: musicScore },
  } = useSelector((state) => state.user);

  const isAGoodScore = musicScore >= 50;

  return (
    <>
      {countUpFinished && (
        <ResultTitle>
          <FormattedMessage id={isAGoodScore ? 'result_good' : 'result_poor'} />
        </ResultTitle>
      )}
      <ResultText><FormattedMessage id="your_result_is" /></ResultText>
      {typeof musicScore === 'number' && (
        <Score countUpFinished={countUpFinished} isAGoodScore={isAGoodScore}>
          <CountUp
            start={0}
            end={musicScore}
            duration={5}
            delay={0.8}
            onEnd={() => setCountUpFinished(true)}
          />
          %
        </Score>
      )}
      <CrowdShadow isIntegrated={isIntegrated} animationStarted={countUpFinished} />
      <Crowd isIntegrated={isIntegrated} animationStarted={countUpFinished} />
      {!isIntegrated && (
        <BlockedOrientation orientation="landscape" />
      )}
    </>
  );
}

export default Result;
