import { useQuery } from '@apollo/client';
import { useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';

import Loading from '#components/Loading/Loading';

import { GET_PRIZE } from '../../lib/apollo/Prize/queries';
import Intro from './Introduction/Introduction';
import Reveal from './Reveal/Reveal';
import { PrizeContainer, RevealContainer } from './styles';

const propTypes = {
  gameId: PropTypes.string.isRequired,
};

function Prize(props) {
  const { gameId } = props;
  const { data, loading } = useSubscription('prize');

  const {
    data: prizeData,
    loading: prizeLoading,
  } = useQuery(GET_PRIZE, { variables: { id: gameId } });

  if (loading || prizeLoading) return <Loading />;

  const {
    winners,
    step,
    quantity,
  } = data;

  return (
    <PrizeContainer>
      {step === 'INTRODUCTION' && (
        <Intro prizeCount={quantity} />
      )}
      {step === 'PRIZE_GIVING' && (
        <RevealContainer>
          <Reveal winners={winners} code={prizeData.getPrize.code} />
        </RevealContainer>
      )}
    </PrizeContainer>
  );
}

Prize.propTypes = propTypes;

export default Prize;
