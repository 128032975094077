export const RESET_ONBOARDING = 'RESET_ONBOARDING';
export const SET_NEXT_ONBOARDING_STEP = 'SET_NEXT_ONBOARDING_STEP';
export const SET_ONBOARDING_TO_COMPLETED = 'SET_ONBOARDING_TO_COMPLETED';

export function setNextOnboardingStep(nextIndex) {
  return { nextIndex, type: SET_NEXT_ONBOARDING_STEP };
}

export function resetOnboarding() {
  return { type: RESET_ONBOARDING };
}

export function setOnboardingToCompleted() {
  return { type: SET_ONBOARDING_TO_COMPLETED };
}
