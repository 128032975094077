import { connect } from 'react-redux';

import { setCurrentQuestion, setTriviaJoined } from '../../../reducers/GameReducer/GameActions';
import { setScore } from '../../../reducers/UserReducer/UserActions';
import TriviaScreen from './TriviaScreen';

const mapStateToProps = (state) => ({
  alreadyJoined: state.game.trivia.joined,
  currentQuestion: state.game.trivia.currentQuestion,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentQuestion: (id) => dispatch(setCurrentQuestion(id)),
  setScore: (score) => dispatch(setScore(score, 'trivia')),
  setTriviaJoined: (joined) => dispatch(setTriviaJoined(joined)),
});

const TriviaScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriviaScreen);

export default TriviaScreenContainer;
