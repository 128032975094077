import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCircle,
  faHexagon,
  faSquare,
  faThLarge,
  faThList,
  faTimes,
  faTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import {
  BLUE_PURPLE,
  DARK_TURQUOISE,
  GREEN,
  LILAC,
  RED,
  UNI_RED,
  YELLOW,
} from '../../styles/colors';
import { FullScreenContainer } from '../../styles/styledComponents';

library.add([
  faCircle,
  faThLarge,
  faThList,
  faHexagon,
  faSquare,
  faTriangle,
  faCheck,
  faTimes,
]);

const colors = [
  DARK_TURQUOISE,
  BLUE_PURPLE,
  YELLOW,
  RED,
];

const answerIcons = [
  'square',
  'triangle',
  'circle',
  'hexagon',
];

export const AnswerTimeText = styled.span`
  padding-left: 5vw;
  padding-right: 5vw;
  text-shadow: 0 0 3px black;
`;

export const TutorialContainer = styled(FullScreenContainer)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 4vmax;
`;

export const FullWidthImage = styled.img`
  width: 100%;
  height: auto;
`;

export const Text = styled.span`
  font-weight: normal;
  width: 100%;
  line-height: 1.1;
  margin-bottom: auto;
  word-break: break-word;
`;

export const TutorialText = styled(Text)`
  font-size: 0.8em;
  color: white;
  margin-top: auto;
  width: 100%;
  padding: 0 8%;
`;

export const TutorialTitle = styled(Text)`
  font-size: 1.2em;
  color: white;
  margin-top: auto;
  width: 100%;
  padding: 0 8%;
`;

export const TriviaContainer = styled(Container)`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 15px;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  font-size: 3.8vmax;
  font-weight: 600;
`;

export const FooterScore = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: ${UNI_RED};
  color: white;
  font-weight: normal;
  font-size: 1.2em;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 2px 0 0 20px;
`;

export const AnswersContainer = styled(Row)`
  margin: 0;
  width: 100%;
  flex: 0.8;
`;

export const AnswerButton = styled(Col).attrs({
  xs: 6,
})`
  height: 50%;
  padding: 5px;
`;

export const AnswerContent = styled.div`
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.showDetails ? 'space-between' : 'center')};
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${(props) => colors[props.colorIndex]};
  ${(props) => (props.isSelectedAnswer === false ? 'opacity: 0.5' : null)}
`;

export const AnswerIcon = styled(FontAwesomeIcon).attrs({
  icon: (props) => answerIcons[props.iconIndex],
})`
  color: white;
  font-size: 1.6em;
  flex-shrink: 0;
  filter: drop-shadow(4px 4px 1px rgba(0, 0, 0, 0.3));
`;

export const AnswerTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const AnswerText = styled.p`
  font-weight: normal;
  font-size: 0.8em;
  color: white;
  word-break: break-word;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.3));
`;

export const QuestionContainer = styled(Row)`
  margin: 0;
  width: 100%;
  flex: 0.275;
  text-align: left;
  justify-content: center;
  flex-flow: column;
  display: flex;
  align-items: center;
  font-size: 3.6vmax;
`;

export const QuestionNumber = styled.span`
  font-weight: normal;
  font-size: 0.6em;
  width: 100%;
  color: ${LILAC};
  word-break: break-word;
`;

export const CorrectIcon = styled(FontAwesomeIcon).attrs({
  icon: 'check',
})`
  flex: 1;
  color: white;
  font-size: 1.5em;
`;

export const IncorrectIcon = styled(FontAwesomeIcon).attrs({
  icon: 'times',
})`
  flex: 1;
  color: white;
  font-size: 1.5em;
`;

export const ResultsBackground = styled.div`
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ResultsContainer = styled(Row)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  flex-flow: wrap;
  margin: 0;
  width: 100%;
  height: 20%;
  flex: 1;
`;

export const PaddedText = styled.div`
  padding: 10px;
  border-radius: 200px;
  background-color: ${(props) => (props.correct ? GREEN : RED)};
  border: 5px solid rgba(0, 0, 0, 0.5);
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap row;
  align-items: center;
  overflow: hidden;
`;

export const ValidationText = styled.span`
  flex: 1;
  color: white;
  font-weight: normal;
  font-size: 1.2em;
`;

export const EmptySpace = styled.span`
  flex: 1;
`;

export const PreAnswerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PreAnswerText = styled.p`
  color: white;
  font-size: 2em;
`;
