import PropTypes from 'prop-types';

import Logger from '../../lib/Logger';
import LeaderboardHeader from './LeaderboardHeader';
import Score from './Score';
import { LeaderboardContainer, LeaderboardRows } from './styles';

const logger = Logger.getLogger('LeaderboardTab');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    scores: PropTypes.arrayOf(PropTypes.shape()),
  })).isRequired,
  unit: PropTypes.string,
};

const defaultProps = {
  unit: '%',
};

function LeaderboardTab({ data, unit }) {
  logger.trace('render()', data);

  return (
    <LeaderboardContainer>
      <LeaderboardHeader unit={unit} />
      <LeaderboardRows>
        {data && data.slice(0, 10).map((score, index) => (
          <Score
            index={index}
            scoreEntry={score}
          />
        ))}
      </LeaderboardRows>
    </LeaderboardContainer>
  );
}

LeaderboardTab.propTypes = propTypes;
LeaderboardTab.defaultProps = defaultProps;

export default LeaderboardTab;
