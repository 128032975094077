export const midiToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

export const tickToMs = (tick, bpm, division) => {
  const millisecondsPerTick = 60000 / (bpm * division);
  return millisecondsPerTick * tick;
};

export const msToTick = (milliseconds, bpm, division) => {
  const millisecondsPerTick = 60000 / (bpm * division);
  return milliseconds / millisecondsPerTick;
};
