import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

import { NonGradientTitle, Text } from '../../styles/styledComponents';

const rotate = 'benchmarks/images/rotateScreen/rotate';
const generalBackground1 = 'benchmarks/images/app/generalBackground';
const generalBackground2 = 'benchmarks/images/app/generalBackground2';
const generalBackground = {
  normal: generalBackground1,
  rougeOr: generalBackground2,
};

export const BackgroundImage = styled(CloudImage).attrs((props) => ({
  publicId: generalBackground[props.theme.themeCode],
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MessageScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RotateImage = styled(CloudImage).attrs({
  publicId: rotate,
})`
  position: relative;
  margin: 0 auto;
  min-width: 30%;
  max-width: 50%;
  min-height: 35%;
  max-height: 55%;
  z-index: 998;
`;

export const Message = styled(NonGradientTitle)`
  color: white;
  margin: 5vh auto 0 auto;
  z-index: 998;
`;

export const LinkText = styled(Text)`
  font-size: 5vmin;
`;
