export default [
  {
    description: 'story_desc_1',
    image: 'benchmarks/images/story/story1',
    subtitle: 'story_subtitle_1',
    title: 'story_title_1',
  },
  {
    description: 'story_desc_2',
    image: 'benchmarks/images/story/story2',
    subtitle: 'story_subtitle_2',
    title: 'story_title_2',
  },
];
