export const defaultStyle = {
  active: {
    color: '#2de8b0',
    gradientEndColor: '#2de8b033',
    gradientSize: 200,
    innerColor: '#2de8b0ff',
  },
  color: '#deddf0',
  innerColor: '#eeedf0',
};

export const drummers = defaultStyle;

export const guitarists = {
  active: {
    color: '#edd63f',
    gradientEndColor: '#edd63f33',
    gradientSize: 200,
    innerColor: '#d4c563',
  },
  color: '#deddf0',
  innerColor: '#eeedf0',
};

const noteStyles = {
  defaultStyle,
  drummers,
  guitarists,
};

export default noteStyles;
