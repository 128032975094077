import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { doubleBrightness } from '../../styles/animations';
import { WHITE } from '../../styles/colors';

export const RoundButton = styled.button`
  position: relative;
  width: 12vmin;
  height: 12vmin;
  margin-right: 2vmin;
  background-color: ${(props) => (props.theme.themeCode === 'normal' ? '#9047ff' : props.theme.primaryColor)};
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    animation: ${doubleBrightness} 500ms;
  }
`;

export const ResponsiveIcon = styled(FontAwesomeIcon)`
  font-size: 6vmin;
  color: ${WHITE};
`;
