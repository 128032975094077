export const SET_JOINED_TRIVIA = 'SET_JOINED_TRIVIA';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_POLL_QUESTION = 'SET_POLL_QUESTION';
export const SET_INPUT_TYPE = 'SET_INPUT_TYPE';
export const SET_LAST_EXTENSION = 'SET_LAST_EXTENSION';
export const SET_STAMPED_WORDS = 'SET_STAMPED_WORDS';

export function setTriviaJoined(joined) {
  return { joined, type: SET_JOINED_TRIVIA };
}

export function setInputType(inputType) {
  return { inputType, type: SET_INPUT_TYPE };
}

export function setCurrentQuestion(id) {
  return { id, type: SET_CURRENT_QUESTION };
}

export function setPollQuestion(id) {
  return { id, type: SET_POLL_QUESTION };
}

export function setLastExtension(id, extensionType) {
  return { extensionType, id, type: SET_LAST_EXTENSION };
}

export function setStampedWords(stampedWords) {
  return { stampedWords, type: SET_STAMPED_WORDS };
}
