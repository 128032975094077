import { useIntermediaClient, useSubscription } from '@novom/react-anyware-intermedia-client';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loading from '#components/Loading/Loading';
import ServerError from '#components/ServerError/ServerError';
import { setOrchestration } from '#reducers/OrchestrationReducer/OrchestrationActions';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function OrchestrationSubscriber({ children }) {
  const dispatch = useDispatch();
  const { data, loading, error } = useSubscription('orchestration');
  const intermediaClient = useIntermediaClient();

  useEffect(() => {
    if (data?.gameType) {
      const subscribe = async () => intermediaClient.subscribe(
        data.gameType,
        (rtData) => {
          dispatch(setOrchestration({ gameType: data.gameType, ...rtData }));
        },
      );

      const promise = subscribe();
      return () => {
        promise.then((subscription) => subscription.close());
      };
    }
    return undefined;
  }, [dispatch, intermediaClient, data]);

  if (error) return <ServerError error={error.message} />;
  if (loading) return <Loading />;
  return children;
}

OrchestrationSubscriber.propTypes = propTypes;

export default OrchestrationSubscriber;
