import { censor, isBadWord } from '@novom/censorship';
import faker from 'faker';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';

import CustomButton from '../../../components/CustomButton/CustomButton';
import { CREATE_USER } from '../../../lib/apollo/mutations';
import isLoadTest from '../../../lib/isLoadTest';
import Logger from '../../../lib/Logger';
import {
  ErrorPopover,
  TextAndInput,
  UsernameAnswer,
  UsernameContainer,
  UsernameForm,
  UsernameQuestion,
} from './styles';

const propTypes = {
  analytics: PropTypes.shape(),
  apolloClient: PropTypes.shape().isRequired,
  event: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
    isIntegrated: PropTypes.bool.isRequired,
  }).isRequired,
  onNext: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

const defaultProps = {
  analytics: undefined,
};

class Username extends Component {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);

    this.state = {
      alreadyTaken: false,
      censored: false,
      networkError: false,
      networkRemainingSec: 0,
      username: isLoadTest() ? faker.internet.userName() : '',
      verifyingUsername: false,
    };

    this.usernameRef = createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (isLoadTest()) {
      this.handleSubmit();
    }
  }

  componentWillUnmount() {
    clearInterval(this.networkAttemptInterval);
    clearTimeout(this.usernameQueryTimeout);
  }

  handleInputChange(event) {
    const username = event.target.value;

    if (username.length < 40) {
      this.setState({
        alreadyTaken: false,
        censored: false,
        username: event.target.value,
      });
    }
  }

  handleCensoredError() {
    this.logger.trace('username censored');
    const { username } = this.state;

    this.setState({
      censored: true,
      username: censor(username),
    });
  }

  handleAlreadyTakenError() {
    this.logger.trace('username already taken');

    this.setState({
      alreadyTaken: true,
      censored: false,
    });
  }

  async handleSubmit() {
    const {
      apolloClient,
      onNext,
      setId,
      setUsername,
      event: { eventId },
    } = this.props;
    const { username } = this.state;
    this.logger.trace('handleSubmit()');

    if (isBadWord(username)) {
      this.handleCensoredError();
    } else {
      try {
        this.setState({ verifyingUsername: true });
        const { data: { createUser: { id } } } = await apolloClient.mutate({
          mutation: CREATE_USER,
          variables: { eventId, username },
        });
        this.setState({ verifyingUsername: false });
        setUsername(username);
        setId(id);
        const { analytics } = this.props;
        if (analytics) {
          analytics.logEvent('username_entered');
        }
        onNext();
      } catch (err) {
        this.setState({ verifyingUsername: false });
        this.handleAlreadyTakenError();
      }
    }
  }

  render() {
    const {
      alreadyTaken,
      censored,
      networkError,
      networkRemainingSec,
      username,
      verifyingUsername,
    } = this.state;
    const { theme } = this.props;
    const submitIsDisabled = username === '' || alreadyTaken || networkError || verifyingUsername;

    return (
      <UsernameContainer>
        <UsernameForm>
          <TextAndInput>
            <UsernameQuestion>
              <FormattedMessage id="username_input" />
            </UsernameQuestion>
            <UsernameAnswer
              ref={this.usernameRef}
              type="text"
              value={username}
              onChange={this.handleInputChange}
              maxLength="14"
              height="2em"
              disabled={networkError}
              isInvalid={alreadyTaken || censored}
              id="usernameInput"
              style={{ minHeight: '1.2em' }}
            />
            <Overlay show={censored} target={this.usernameRef.current} placement="bottom">
              <ErrorPopover><FormattedMessage id="username_censored" /></ErrorPopover>
            </Overlay>
            <Overlay show={alreadyTaken} target={this.usernameRef.current} placement="bottom">
              <ErrorPopover><FormattedMessage id="username_alreadyTaken" /></ErrorPopover>
            </Overlay>
            <Overlay show={networkError} target={this.usernameRef.current} placement="bottom">
              <ErrorPopover><FormattedMessage id="error_server" /></ErrorPopover>
              <ErrorPopover><FormattedMessage id="error_newAttemptIn" values={{ secondes: networkRemainingSec }} /></ErrorPopover>
            </Overlay>
          </TextAndInput>
        </UsernameForm>
        <CustomButton
          disabled={submitIsDisabled}
          onClick={!submitIsDisabled ? this.handleSubmit : () => { }}
          id="nextButton"
          themeCode={theme && theme.themeCode}
        >
          {verifyingUsername ? <Spinner animation="border" /> : <FormattedMessage id="navigation_continue" />}
        </CustomButton>
      </UsernameContainer>
    );
  }
}

Username.propTypes = propTypes;
Username.defaultProps = defaultProps;

export default Username;
