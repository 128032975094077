import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AnswerTimeText,
  CorrectIcon,
  EmptySpace,
  IncorrectIcon,
  PaddedText,
  ResultsBackground,
  ResultsContainer,
  ValidationText,
} from '../styles';

const propTypes = {
  answerTime: PropTypes.number,
  correctAnswer: PropTypes.string.isRequired,
  selectedAnswer: PropTypes.string,
  setAnswerTime: PropTypes.func.isRequired,
};

const defaultProps = {
  answerTime: null,
  selectedAnswer: null,
};

function Results({
  answerTime,
  correctAnswer,
  selectedAnswer,
  setAnswerTime,
}) {
  useEffect(() => (() => { setAnswerTime(null); }), [setAnswerTime]);
  const correct = correctAnswer === selectedAnswer;
  return (
    <ResultsBackground>
      <ResultsContainer>
        <PaddedText correct={correct}>
          {correct
            ? <CorrectIcon />
            : <IncorrectIcon />}
          <ValidationText>
            {correct
              ? <FormattedMessage id="trivia_correct_answer" />
              : <FormattedMessage id="trivia_wrong_answer" />}
          </ValidationText>
          <EmptySpace />
        </PaddedText>
        {answerTime && (
          <FormattedMessage id="trivia_time" values={{ time: answerTime / 1000 }}>
            {(text) => <AnswerTimeText>{text}</AnswerTimeText>}
          </FormattedMessage>
        )}
      </ResultsContainer>
    </ResultsBackground>
  );
}

Results.propTypes = propTypes;
Results.defaultProps = defaultProps;

export default Results;
