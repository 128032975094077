import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  PreAnswerContainer,
  PreAnswerNumber,
  PreAnswerNumberContainer,
  PreAnswerText,
} from './styles';

const propTypes = {
  currentItem: PropTypes.number.isRequired,
  numberOfItems: PropTypes.number.isRequired,
};

function PreAnswer({ currentItem, numberOfItems }) {
  const [number, setNumber] = useState(currentItem);
  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      setNumber((num) => num + 1);
    }, 750);
    return () => { clearTimeout(timeoutHandle); };
  }, []);

  return (
    <PreAnswerContainer>
      <PreAnswerText>Question</PreAnswerText>
      <PreAnswerNumberContainer>
        <PreAnswerNumber>{number}</PreAnswerNumber>
        <PreAnswerText>{`/ ${numberOfItems}`}</PreAnswerText>
      </PreAnswerNumberContainer>
    </PreAnswerContainer>
  );
}

PreAnswer.propTypes = propTypes;

export default PreAnswer;
