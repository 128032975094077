/* eslint-disable sort-keys */

const MUSIC_GAME_STEPS = {
  INTRODUCTION: 'INTRODUCTION',
  TEAM_SELECTION: 'TEAM_SELECTION',
  WAITING: 'WAITING',
  WARMUP: 'WARMUP',
  GAME: 'GAME',
  RESULT: 'RESULT',
  LEADERBOARD: 'LEADERBOARD',
};

export default MUSIC_GAME_STEPS;
