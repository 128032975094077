import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';

import packageJson from '../../package.json';
import appReducer from '../reducers/AppReducer/AppReducer';
import initialStore from './initialStore';
import isLoadTest from './isLoadTest';

export const history = createBrowserHistory({
  basename: '/',
});

export default function configureStore() {
  let savedStore = {};
  const errors = {};
  if ('localStorage' in window && window.localStorage !== null) {
    savedStore = JSON.parse(window.localStorage.getItem('store')) || {};
    if ((savedStore.config && savedStore.config.version !== packageJson.version) || isLoadTest()) {
      savedStore = {};
    }
  } else {
    errors.localStorage = true;
  }

  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    appReducer(history),
    {
      ...initialStore,
      ...savedStore,
      appConfig: initialStore.appConfig,
      errors,
    },
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
      ),
    ),
  );

  store.subscribe(() => {
    const { orchestration, visibility, ...storeState } = store.getState();
    if ('localStorage' in window && window.localStorage !== null && !isLoadTest()) {
      localStorage.setItem('store', JSON.stringify(storeState));
    }
  });

  return store;
}
