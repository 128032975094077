import { CloudImage } from '@novom/cloudinary-image';
import styled from 'styled-components';

import { PURPLE_LIGHT, UNI_GOLD } from '../../../styles/colors';

export const Username = styled.p`
  flex: 1;
  position: relative;
  margin-right: 2rem;
  font-size: 3vh;
  color: white;
  overflow-x: hidden;
  text-align: right;
`;

export const DefaultTeamBox = styled.div`
  position: relative;
  height: calc(10vh - 2rem);
  width: calc(10vh - 2rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.theme.themeCode === 'normal' ? PURPLE_LIGHT : UNI_GOLD)};
  border-radius: 14.3vmin;
`;

export const DefaultEmblem = styled(CloudImage)`
  max-height: 75%;
  max-width: 75%;
`;
