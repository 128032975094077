import { CloudImage } from '@novom/cloudinary-image';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';

import { GREY, NAVY } from '../../../styles/colors';

export const StoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${GREY};
  height: 100%;
  width: 100%;
  padding-bottom: 4vh;
`;

export const SkipButton = styled.div`
  color: ${NAVY};
  font-weight: bold;
  display: block;
  margin-top: 1vh;
  font-size: 4vmin;
`;

export const CarouselImage = styled(CloudImage)`
  background: ${GREY};
  background: linear-gradient(0deg, ${GREY} 0%, #f7f7fc 10%, #fff 50%, #fff 100%);
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CustomCarousel = styled(Carousel)`
  width: 100%;
  max-height: 50%;
  position: relative;

  li {
    opacity: 1;
    background-color: #fff;
    border: 1px solid ${NAVY};
  }

  ol {
    display: block;
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel-item {
    height: 100%;
  }

  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    float: left;
    bottom: 0;
    position: relative;
  }

  .carousel-indicators .active {
    background-color: ${NAVY};
  }
`;

export const Title = styled.div`
  text-align: left;
  font-size: 3em;
  font-weight: 600;
  line-height: 1;
  width: 100%;
`;

export const SubTitle = styled.div`
  font-size: 2em;
  font-weight: 400;
  line-height: 0.8;
  width: 100%;
`;

export const StoryText = styled.div`
  text-align: left;
  color: ${(props) => (props.theme.themeCode === 'normal' ? NAVY : 'black')};
  height: 45%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Description = styled.div`
  margin-top: 4em;
  width: 100%;
  font-size: 1.5em;
  overflow: auto;
`;
