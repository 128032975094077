import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  CLEAR_TIMER,
  RESTART_TIMER,
  SET_TIME_ELAPSED,
  SET_TIMER_ID,
  START_TIMER,
} from './TimerActions';

const logger = Logger.getLogger('TimerReducer');

function timerReducer(state = initialStore.timer, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_APP');
      return {
        ...initialStore.timer,
      };

    case CLEAR_TIMER:
      logger.trace('CLEAR_TIMER');
      return {
        ...state,
        duration: initialStore.timer.duration,
        id: initialStore.timer.id,
        timeStarted: initialStore.timer.timeStarted,
        timeStopped: initialStore.timer.timeStopped,
      };

    case SET_TIMER_ID:
      logger.trace('SET_TIMER_ID');
      return {
        ...state,
        id: action.id,
      };

    case START_TIMER:
      logger.trace('START_TIMER');
      return {
        ...state,
        duration: action.duration,
        timeElapsed: 0,
        timeStarted: action.timeStarted,
      };

    case SET_TIME_ELAPSED:
      logger.trace('SET_TIME_ELAPSED');
      return {
        ...state,
        timeElapsed: action.timeElapsed,
      };

    case RESTART_TIMER:
      logger.trace('RESTART_TIMER');
      return {
        ...state,
        timeElapsed: (action.now - state.timeStarted) > state.duration
          ? 0
          : state.timeElapsed + (action.now - (state.timeStarted + state.timeElapsed)),
      };

    default:
      return state;
  }
}

export default timerReducer;
