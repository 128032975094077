import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_CONFIG,
  SET_GEOLOCATION_PERMISSION,
  SET_IS_INTEGRATED,
  SET_LANGUAGE,
  SET_PERMISSIONS,
  SET_VERSION,
} from './ConfigActions';

const logger = Logger.getLogger('ConfigReducer');

function ConfigReducer(state = initialStore.config, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_CONFIG');
      return {
        ...initialStore.config,
      };

    case RESET_CONFIG:
      logger.trace('RESET_CONFIG');
      return {
        ...initialStore.config,
      };

    case SET_LANGUAGE:
      logger.trace('SET_LANGUAGE', action.language);
      return {
        ...state,
        language: action.language,
      };

    case SET_PERMISSIONS:
      logger.trace('SET_PERMISSIONS', action.permissions);
      return {
        ...state,
        permissions: action.permissions,
      };

    case SET_GEOLOCATION_PERMISSION:
      logger.trace('SET_GEOLOCATION_PERMISSION', action.permission);
      return {
        ...state,
        permissions: {
          ...state.permissions,
          geolocation: action.permission,
        },
      };

    case SET_VERSION:
      logger.trace('SET_VERSION', action.version);
      return {
        ...state,
        version: action.version,
      };

    case SET_IS_INTEGRATED:
      logger.trace('SET_IS_INTEGRATED', action.isIntegrated);
      return {
        ...state,
        isIntegrated: action.isIntegrated,
      };

    default:
      return state;
  }
}

export default ConfigReducer;
