import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HeaderContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
`;
