import PageNotFound from '../../components/PageNotFound/PageNotFound';
import Language from './Language/LanguageContainer';
import Permissions from './Permissions/PermissionsContainer';
import Seat from './Seat/SeatContainer';
import Story from './Story/StoryContainer';
import Username from './Username/UsernameContainer';

export default function getStepComponent(stepName) {
  switch (stepName) {
    case 'language':
      return Language;
    case 'permissions':
      return Permissions;
    case 'story':
      return Story;
    case 'username':
      return Username;
    case 'seat':
      return Seat;
    default:
      return PageNotFound;
  }
}
