import { gql } from '@apollo/client';

export const GET_MUSIC_GAME = gql`
  query($id: String!) {
    getMusicGame(id: $id) {
      id
      name
      teams {
        name
        image
        color
      }
      song {
        id
        name
        file
        midi
      }
    }
  }
`;

export const GET_ALL_SONG = gql`
  query($id: String!) {
    getAllSong(id: $id) {
      id
      name
      file
      midi
      teams {
        color
        image
        name
      }
    }
  }
`;

export const GET_MUSIC_GAME_SONG = gql`
  query($musicGameId: String!) {
    getMusicGameSong(musicGameId: $musicGameId) {
      id
      name
      file
      midi
      teams {
        color
        image
        name
      }
    }
  }
`;
