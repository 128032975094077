import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import BlockedOrientation from '../../../components/BlockedOrientation/BlockedOrientation';
import NormalSelect from '../../../components/NormalSelect/NormalSelect';
import languagesForSelect from '../../../lib/languagesForSelect';
import {
  ChooseButton,
  LanguageContainer,
  LanguageTitle,
  NextButtonContainer,
} from './styles';

const propTypes = {
  config: PropTypes.shape().isRequired,
  onNext: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

function Language(props) {
  const {
    config,
    onNext,
    setLanguage,
    theme,
  } = props;

  return (
    <>
      <LanguageContainer>
        <LanguageTitle><FormattedMessage id="language_selection" /></LanguageTitle>
        <NormalSelect
          id="languageSelect"
          themeCode={theme && theme.themeCode}
          list={languagesForSelect}
          onChange={(event) => setLanguage(event.target.value)}
          value={config.language}
          style={{ width: '80vw' }}
        />
        <NextButtonContainer>
          <ChooseButton onClick={onNext} themeCode={theme && theme.themeCode} id="nextButton">
            <FormattedMessage id="navigation_choose" />
          </ChooseButton>
        </NextButtonContainer>
      </LanguageContainer>
      <BlockedOrientation orientation="landscape" />
    </>
  );
}

Language.propTypes = propTypes;

export default withTheme(Language);
