import { useQuery } from '@apollo/client';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CustomBack from '../../components/CustomBack/CustomBack';
import { GET_PRIZES_WON } from '../../lib/apollo/Win/queries';
import { FullScreenContainer } from '../../styles/styledComponents';
import {
  BackgroundImage,
  Code,
  Container,
  Header,
  Section,
  Title,
} from './styles';

const ANIMATION_DURATION_MS = 500;
const BUTTON_DURATION_MS = 250;
export const EXIT_DURATION_MS = ANIMATION_DURATION_MS + BUTTON_DURATION_MS;

function PrizeWon() {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.id);

  const onClose = useCallback(() => {
    dispatch(push('/'));
    setOpen(false);
  }, [dispatch]);

  const { data } = useQuery(GET_PRIZES_WON, {
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      userId,
    },
  });

  return (
    <FullScreenContainer usePercents>
      <Container
        animationDelayMs={open ? 250 : BUTTON_DURATION_MS}
        animationDurationMs={ANIMATION_DURATION_MS}
        opening={open}
      >
        <BackgroundImage />
        <Header>
          <CustomBack onClick={() => onClose()} id="backButton" />
        </Header>
        <Title><FormattedMessage id="prizes_won" /></Title>
        <Section>
          {(!data || data?.getAllWin.length === 0) && <FormattedMessage id="prize_no_prize" />}
          {data?.getAllWin?.map(({ code }) => (
            <Code>
              <FormattedMessage id="code" values={{ code }} />
            </Code>
          ))}
        </Section>
      </Container>
    </FullScreenContainer>
  );
}

export default PrizeWon;
