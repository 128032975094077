import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading/Loading';
import { GET_VALID_SEATS } from '../../lib/apollo/queries';
import Seat from '../Onboarding/Seat/SeatContainer';
import GeopositionWait from './GeopositionWait/GeopositionWait';

const propTypes = {
  matrixId: PropTypes.string.isRequired,
  teamMode: PropTypes.string.isRequired,
};

function PixelError({ matrixId, teamMode }) {
  const { data, loading, error } = useQuery(GET_VALID_SEATS, { variables: { matrixId } });

  if (loading || (error && error.stack)) return <Loading />;
  const { getMatrixValidSeats } = data;

  return (
    teamMode === 'geolocation'
      ? <GeopositionWait />
      : (
        <Seat
          seatError
          range={getMatrixValidSeats}
          onNext={() => { }}
        />
      )
  );
}

PixelError.propTypes = propTypes;

export default PixelError;
