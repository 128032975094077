import PropTypes from 'prop-types';
import { range } from 'range';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import DIRECTIONS from '#lib/constants/Directions';
import SONG_STATUS from '#lib/constants/SongStatus';
import extractPartitions from '#lib/extractPartitions';
import isLoadTest from '#lib/isLoadTest';
import midiPlayer from '#lib/midiPlayer';
import GameInput from '#modules/RockBand/GameScreen/GameInput';
import { msToTick } from '#modules/RockBand/GameScreen/gameUtils';

import { setScore } from '../../../../reducers/UserReducer/UserActions';
import midiSettings from '../../midiSettings';
import getMidiInfo from '../MidiVisualiser/getMidiInfo';
import IntegratedGame from './IntegratedGame';

const propTypes = {
  currentStep: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  setScore: PropTypes.func.isRequired,
  song: PropTypes.shape().isRequired,
  team: PropTypes.string.isRequired,
  teamInfos: PropTypes.shape().isRequired,
};

const fetchMidiFile = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

const DEFAULT_TEAM_INFOS = {
  drummers: {
    color: '#ff0021',
    image: 'benchmarks/images/teams/teamDrummer',
  },
};

const DIFFICULTY_LEVEL = 100;

function IntegratedGameLogic(props) {
  const {
    currentStep,
    dispatch,
    inputType,
    setScore: postScore,
    song,
    team,
    teamInfos,
  } = props;

  const [midiInfo, setMidiInfo] = useState();
  const [songStatus, setSongStatus] = useState(SONG_STATUS.NOT_STARTED);
  const [teamInfo, setTeamInfo] = useState(DEFAULT_TEAM_INFOS);
  const [totalNotes, setTotalNotes] = useState(0);
  const [notesPlayed, setNotesPlayed] = useState([]);
  const [notesTicks, setNotesTicks] = useState([]);
  const audioPlayerRef = useRef();

  const getScoreFromRecordedNotes = (delayTicks = 0) => {
    const removeDelay = (note) => note - delayTicks;
    const isHitted = (note, remainingNotes) => {
      const pastNoteTicks = range(note - (DIFFICULTY_LEVEL + 30), note);
      const futureNoteTicks = range(note, note + DIFFICULTY_LEVEL);

      // Played note matchs a note in the song's unplayed notes
      const match = remainingNotes.find((tick) => pastNoteTicks.includes(tick)
        || futureNoteTicks.includes(tick));

      // Played note matchs a note in the song
      const matched = notesTicks.some((tick) => pastNoteTicks.includes(tick)
        || futureNoteTicks.includes(tick));

      return { match, matched };
    };
    const delayedNotes = notesPlayed.map(removeDelay);
    const notesResults = delayedNotes.reduce((acc, note) => {
      const hitResult = isHitted(note, acc.remainingNotes);
      if (hitResult.match) {
        return {
          ...acc,
          matchedInputs: [...acc.matchedInputs, hitResult.match],
          remainingNotes: acc.remainingNotes.filter((t) => t !== hitResult.match),
        };
      }

      if (!hitResult.matched) {
        return {
          ...acc,
          unmatchedInputs: [...acc.unmatchedInputs, note],
        };
      }

      return acc;
    }, {
      matchedInputs: [], // Notes played correctly
      remainingNotes: notesTicks, // Notes in the song that are not yet matched
      unmatchedInputs: [], // Notes played incorrectly
    });
    return (notesResults.matchedInputs.length * 100) / totalNotes;
  };

  const onAudioStart = useCallback(() => {
    setSongStatus(SONG_STATUS.PLAYING);
    midiPlayer.skipToTick(0);
    midiPlayer.play();
  }, []);

  const onAudioEnds = async () => {
    setTimeout(() => {
      setSongStatus(SONG_STATUS.FINISHED);
    }, 1000);
    const div = midiPlayer.getDivision().division;
    const bpm = midiPlayer.getDivision().tempo;
    const minDelay = -Math.round(msToTick(5000, bpm, div));
    const maxDelay = Math.round(msToTick(25000, bpm, div));
    const delayStep = Math.round(msToTick(100, bpm, div));
    const delays = range(minDelay, maxDelay, delayStep);

    const bestScore = Math.max(...delays.map(getScoreFromRecordedNotes));
    let score = 0;
    if (isLoadTest()) {
      score = Math.floor(Math.random() * 100);
    }
    if (totalNotes === 0) {
      score = 0;
    }

    score = Math.floor(bestScore);
    dispatch(setScore(score, 'music'));
    await postScore({ variables: { score } });
  };

  const handleOnClick = useCallback(() => {
    if (songStatus === SONG_STATUS.NOT_STARTED && !midiPlayer.isPlaying()) {
      setTimeout(() => {
        midiPlayer.skipToTick(0);
        midiPlayer.play();
        audioPlayerRef.current.play();
      }, 3000);
    }
    const lastInputTick = midiPlayer.getCurrentTick();
    setNotesPlayed((previousValue) => [...previousValue, lastInputTick]);
  }, [songStatus]);

  useEffect(() => {
    const { midi } = song;
    const reader = new FileReader();
    const audioPlayer = audioPlayerRef.current;
    const { noteName: teamNote } = midiSettings.instruments[team.toLowerCase()];
    const onMidiEvent = (event) => {
      if (event.name === 'Note on' && event.velocity > 0 && event.noteName === teamNote) {
        setTotalNotes((previousValue) => previousValue + 1);
      }
    };
    const prepareMidi = async () => {
      const blob = await fetchMidiFile(midi);
      reader.readAsArrayBuffer(blob);
      reader.onload = () => {
        midiPlayer.resetTracks();
        midiPlayer.loadArrayBuffer(reader.result);
        midiPlayer.getDivision().eventListeners = {};
        midiPlayer.on('midiEvent', onMidiEvent);
        const instrument = {
          ...midiSettings.instruments[team.toLowerCase()],
          name: team,
        };
        const allTeamEvents = extractPartitions([].concat(...midiPlayer.events), teamNote);
        const newNotesTicks = allTeamEvents.map(({ tick }) => (parseInt(tick, 10)));
        setNotesTicks(newNotesTicks);
        setMidiInfo(getMidiInfo(midiPlayer, [instrument]));
      };
    };
    if (!midiInfo && currentStep === 'GAME') {
      prepareMidi();
    }
    if (teamInfos) {
      const infos = teamInfos.find((songTeam) => songTeam.name === team);
      if (infos) setTeamInfo({ [team]: infos });
    }
    dispatch(setScore(0, 'music'));

    return () => {
      if (audioPlayer) {
        audioPlayer.currentTime = 0;
      }
      midiPlayer.stop();
    };
  }, [currentStep, dispatch, midiInfo, song, team, teamInfos]);

  return (
    <GameInput
      inputType={inputType}
      onInput={(e) => {
        if (e) e.stopPropagation();
        handleOnClick();
      }}
    >
      <IntegratedGame
        audioPlayerRef={audioPlayerRef}
        direction={DIRECTIONS.VERTICAL}
        midiInfo={midiInfo && {
          ...midiInfo,
          instrumentPartitions: midiInfo.instrumentPartitions[0],
        }}
        teamInfos={teamInfo}
        playing={songStatus === SONG_STATUS.PLAYING}
        onAudioStart={onAudioStart}
        onAudioEnds={onAudioEnds}
        songStatus={songStatus}
        file={song.file}
      />
    </GameInput>
  );
}

IntegratedGameLogic.propTypes = propTypes;

export default IntegratedGameLogic;
