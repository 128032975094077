import styled from 'styled-components';

const HandleOrientation = styled.div`
  display: none;

  @media (orientation: ${(props) => props.orientation}) {
    display: flex;
    position: absolute;
    z-index: 9999;
  }
`;

export default HandleOrientation;
