import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { resetApp } from '../../reducers/AppReducer/AppActions';
import { setOrchestration } from '../../reducers/OrchestrationReducer/OrchestrationActions';
import UserExistenceChecker from './UserExistenceChecker';

const mapStateToProps = (state) => ({
  orchestration: state.orchestration,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  goBackToOnboarding: () => (dispatch(push('/onboarding'))),
  resetApp: () => dispatch(resetApp()),
  setOrchestration: (orchestration) => dispatch(setOrchestration(orchestration)),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserExistenceChecker);

export default container;
