import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { withGoogleAnalytics } from '../../lib/GoogleAnalytics';
import { setLanguage } from '../../reducers/ConfigReducer/ConfigActions';
import Profile from './Profile';

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  config: state.config,
  username: state.user.username,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setLanguage: (language) => { dispatch(setLanguage(language)); },
});

const ProfileContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withGoogleAnalytics,
  withTheme,
)(Profile);

export default ProfileContainer;
