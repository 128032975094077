import styled from 'styled-components';

import CustomButton from '../../../components/CustomButton/CustomButton';
import { PURPLE_DARK } from '../../../styles/colors';
import { Text } from '../../../styles/styledComponents';

export const LanguageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LanguageTitle = styled(Text)`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  margin-bottom: 5vh;
  margin-top: -15vh;
`;

export const NextButtonContainer = styled.div`
  position: absolute;
  bottom: 15vh;
`;

export const ChooseButton = styled(CustomButton)`
  font-weight: bold;
  font-size: 5vmin;
  color: ${PURPLE_DARK};
`;
