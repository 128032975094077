import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import { RESET_APP } from '../AppReducer/AppActions';
import { SET_ORCHESTRATION_STATE } from './OrchestrationActions';

const logger = Logger.getLogger('OrchestrationReducer');

function OrchestrationReducer(state = initialStore.orchestration, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_HUB');
      return {
        ...initialStore.orchestration,
      };

    case SET_ORCHESTRATION_STATE:
      logger.trace('SET_ORCHESTRATION_STATE', action);
      return {
        ...state,
        ...action.orchestration,
      };

    default:
      return state;
  }
}

export default OrchestrationReducer;
