import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import NavMenu from './NavMenu';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  navigate: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
