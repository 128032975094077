import initialStore from '../../lib/initialStore';
import Logger from '../../lib/Logger';
import {
  ADD_ERROR,
  REMOVE_ERROR,
} from './ErrorsActions';

const logger = Logger.getLogger('ErrorsReducer');

function ErrorsReducer(state = initialStore.errors, action) {
  const newState = { ...state };

  switch (action.type) {
    case ADD_ERROR:
      logger.trace('ADD_ERROR', action.errorName);
      return {
        ...state,
        [action.errorName]: true,
      };

    case REMOVE_ERROR:
      logger.trace('REMOVE_ERROR', action.errorName);
      delete newState[action.errorName];
      return newState;

    default:
      return state;
  }
}

export default ErrorsReducer;
