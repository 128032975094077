import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Logger from '../../lib/Logger';
import { bounceInUp } from '../../styles/animations';
import { FullScreenContainer } from '../../styles/styledComponents';
import getStepComponent from './getStepComponent';
import StepContainer from './styles';

const ENTER_ANIMATION = bounceInUp;
const ENTER_DURATION_MS = 1500;
export const EXIT_DURATION_MS = 1750;

function checkCompleted(
  steps,
  index,
  onNotCompleted,
  setOnboardingToCompleted,
) {
  if (index < steps.length - 1 && index !== -1) {
    onNotCompleted();
  } else {
    setOnboardingToCompleted();
  }
}

const propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setNextOnboardingStep: PropTypes.func.isRequired,
  setOnboardingToCompleted: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
class Onboarding extends PureComponent {
  constructor(props) {
    super(props);
    this.logger = Logger.getLogger(this.constructor.name);
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  handleNextStep() {
    this.logger.trace('handleNextStep()');
    const {
      currentIndex,
      setNextOnboardingStep,
      setOnboardingToCompleted,
      steps,
    } = this.props;

    checkCompleted(
      steps,
      currentIndex,
      () => setNextOnboardingStep(currentIndex + 1),
      setOnboardingToCompleted,
    );
  }

  render() {
    this.logger.trace('render()');
    const { currentIndex, steps } = this.props;

    const StepComponent = getStepComponent(steps[currentIndex].name);

    return (
      <FullScreenContainer usePercents>
        <StepContainer
          animation={ENTER_ANIMATION}
          animationDurationMs={ENTER_DURATION_MS}
          key={steps[currentIndex].name}
        >
          <StepComponent onNext={this.handleNextStep} />
        </StepContainer>
      </FullScreenContainer>
    );
  }
}

Onboarding.propTypes = propTypes;

export default Onboarding;
