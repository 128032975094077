import { connect } from 'react-redux';

import TermsAndConditions from './TermsAndConditions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const TermsAndConditionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditions);

export default TermsAndConditionsContainer;
