// original source code: https://github.com/gregsramblings/getAccurateCurrentPosition
// source code modified to match project needs

const getAccurateCurrentPosition = (
  geolocationSuccess,
  geolocationError,
  geoprogress,
  opts = {},
) => {
  let bestPosition;
  let lastCheckedPosition;
  let locationEventCount = 0;
  let watchID;
  let timerID;
  const options = opts;

  const foundPosition = (position) => {
    geolocationSuccess(position);
  };

  const checkLocation = (position) => {
    lastCheckedPosition = position;
    locationEventCount += 1;
    // We ignore the first event unless it's the only one
    // received because some devices seem to send a cached
    // location even when maxaimumAge is set to zero
    if ((position.coords.accuracy <= options.desiredAccuracy) && (locationEventCount > 1)) {
      clearTimeout(timerID);
      navigator.geolocation.clearWatch(watchID);
      foundPosition(position);
    } else if (locationEventCount > 1
      && (!bestPosition || position.coords.accuracy <= bestPosition.coords.accuracy)) {
      bestPosition = position;
      geoprogress(position);
    } else {
      geoprogress(position);
    }
  };

  const stopTrying = () => {
    navigator.geolocation.clearWatch(watchID);
    foundPosition(bestPosition || lastCheckedPosition);
  };

  const onError = (error) => {
    clearTimeout(timerID);
    navigator.geolocation.clearWatch(watchID);
    geolocationError(error);
  };

  if (!options.maxWait) options.maxWait = 10000; // Default 10 seconds
  if (!options.desiredAccuracy) options.desiredAccuracy = 20; // Default 20 meters
  if (!options.timeout) options.timeout = options.maxWait; // Default to maxWait

  options.maximumAge = 0; // Force current locations only
  // Force high accuracy (otherwise, why are you using this function?)
  options.enableHighAccuracy = true;

  watchID = navigator.geolocation.watchPosition(checkLocation, onError, options);
  // Set a timeout that will abandon the location loop
  timerID = setTimeout(stopTrying, options.maxWait);
};

export default getAccurateCurrentPosition;
