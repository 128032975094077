import arm1Source from './arm1.png';
import arm2Source from './arm2.png';
import arm3Source from './arm3.png';
import arm4Source from './arm4.png';
import putSource from './putYourPhonesUp1.png';
import yourSource from './putYourPhonesUp2.png';
import phonesSource from './putYourPhonesUp3.png';
import upSource from './putYourPhonesUp4.png';

const arm1 = new Image();
arm1.src = arm1Source;

const arm2 = new Image();
arm2.src = arm2Source;

const arm3 = new Image();
arm3.src = arm3Source;

const arm4 = new Image();
arm4.src = arm4Source;

const put = new Image();
put.src = putSource;

const your = new Image();
your.src = yourSource;

const phones = new Image();
phones.src = phonesSource;

const up = new Image();
up.src = upSource;

const images = {
  arm1,
  arm2,
  arm3,
  arm4,
  phones,
  put,
  up,
  your,
};

export default images;
