import styled from 'styled-components';

import { flash } from '../../styles/animations';

export const CircularCountdownContainer = styled.div`
  flex: 0.4;
  animation: ${(props) => (props.alert ? flash : '')} 1000ms infinite;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleBackground = styled.circle`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const CircleProgress = styled.circle`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: fillingCircularCountdown ${(props) => props.milliseconds}ms linear forwards;

  @keyframes fillingCircularCountdown {

    from {
      stroke-dashoffset: 0;
    }

    to {
      stroke-dashoffset: ${(props) => props.strokeDasharray};
    }
  }
`;
