export const language = { name: 'language' };

export const story = { name: 'story' };

export const username = { name: 'username' };

export const seat = { name: 'seat' };

export const permissions = { name: 'permissions' };

export default [username];
