import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, ErrorMessage, IconContainer } from './styles';

const propTypes = {
  error: PropTypes.string,
};

const defaultProps = {
  error: undefined,
};

function ServerError({ error }) {
  return (
    <Container>
      <IconContainer>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </IconContainer>
      <ErrorMessage>
        <FormattedMessage id={error} />
      </ErrorMessage>
    </Container>
  );
}

ServerError.propTypes = propTypes;
ServerError.defaultProps = defaultProps;

export default ServerError;
